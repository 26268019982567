import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import useCreateProject from '../../../../_services/GeotrakService/hooks/useCreateProject';
import { Project } from '../../../../_services/GeotrakService/Models';
import '../projects.css';

const createButton = ({ project = new Project(), isValid = () => null }) => {
    const {
        createProject, error, isLoading, isSuccessful,
    } = useCreateProject();

    if (project.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/projects" />;
    }

    return (
        <div className="project-toolbar project-button">
            {error && <div className="text-danger project-error" id="create-project-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="create-project-button"
                text="Create"
                loadingText="Creating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => createProject(project)}
            />
        </div>
    );
};

export default createButton;

createButton.propTypes = {
    project: PropTypes.instanceOf(Project),
    isValid: PropTypes.func,
};
