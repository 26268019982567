import React from 'react';
import PropTypes from 'prop-types';
import { BsX } from 'react-icons/bs';
import { ButtonCharcoal, ButtonContainer, ButtonPrimary } from '../../utilComponents/styledComponents';
import './Modal.css';

const modal = ({
    showModal = false,
    header = '',
    onCancel = () => null,
    component = null,
    footer = null,
    displayFullScreen = true,
}) => {
    if (showModal) {
        return (
            <div role="dialog" id="modal-dialog">
                <div className="fade modal-backdrop show"></div>
                <div className="fade show modal standard-modal">
                    <div className={`modal-dialog display-full-screen-${displayFullScreen} standard-modal`}>
                        <div className="modal-content standard-modal" role="document">
                            <div className="text-start modal-header standard-modal">
                                {header}
                                <ButtonPrimary className="pull-right" onClick={onCancel}>
                                    <BsX size="1.5em" />
                                </ButtonPrimary>
                            </div>
                            <div className="modal-body">
                                <div className="modal-component">
                                    {component}
                                </div>
                            </div>
                            <div>
                                <ButtonContainer className="modal-action-buttons standard-modal flex-wrap">
                                    {footer}
                                    <ButtonCharcoal onClick={onCancel} id="cancel-button">Cancel</ButtonCharcoal>
                                </ButtonContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

export default modal;

modal.propTypes = {
    showModal: PropTypes.bool,
    header: PropTypes.string,
    onCancel: PropTypes.func,
    component: PropTypes.instanceOf(Object),
};
