import PropTypes from 'prop-types';
import { User } from '../models';

class IUpdateUserRequest {
    constructor() {
        this.id = null;
        this.data = {};
    }

    static load(user) {
        if (!user) {
            throw Error('user is required');
        }
        if (!(user instanceof User)) {
            throw Error('Unexpected type for user');
        }

        const request = new IUpdateUserRequest();
        request.id = user.id;
        request.data = user;
        request.validate();
        return request;
    }

    validate() {
        if (!this.id) {
            throw Error('id is required');
        }
    }
}

export default IUpdateUserRequest;

IUpdateUserRequest.propTypes = {
    id: PropTypes.string,
    data: PropTypes.instanceOf(Object),
};
