/* eslint react/prop-types: "off",
          react/no-did-update-set-state: "off",
          no-return-assign: "off",
          react/no-unused-prop-types: "off",
          no-param-reassign: "off",
          no-unneeded-ternary: "off",
*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDataGrid from 'react-data-grid';
import { Menu, Toolbar } from 'react-data-grid-addons';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { editData } from '../helpers/tools';
import {
    debounce
} from '../helpers/utilities';
import ContextMenu from '../utilComponents/ContextMenu/ContextMenu';

import LoadingFullScreen from '../utilComponents/LoadingFullScreen';
import { addAlertThunk } from '../actions/errorActions';
import { setTableDataColumnsFilter, resetTableDataColumnsFilter } from '../actions/dataColumnsFilterAction';
import { resetFeature, setFeature, fetchFeatureThunk } from '../actions/featureActions';
import { clearIdCollection, setIdCollection } from '../actions/idActions';
import Log from '../utilComponents/Log';
import { getItem, setItem } from '../helpers/localStorage';
import RowRenderer from './RowRenderer';
import './stylesheets/ResultsTable.css';
import FilterGridExpression from './ResultsTable/Models/FilterGridExpression';
import { IProjectRecordRequest } from '../_services/GeotrakService';
import { projectRecordService } from '../_services/GeotrakService/ProjectRecordService';
import IFilterGrid from './ResultsTable/Interfaces/IFilterGrid';
import { DataSource } from '../_services/GeotrakService/Models';
import ErrorMessage from '../utilComponents/ErrorMessage';
import { UserService } from '../_services';

const getFrozenColumns = (dataSource, view) => {
    const frozenColumns = getItem('frozenColumns') || [];
    const columns = frozenColumns.filter((column) => column.dataSource === dataSource && column.view === view);
    return columns.map((selectedColumn) => selectedColumn.column);
};

const addFrozenColumn = (dataSource, view, column) => {
    const frozenColumns = getItem('frozenColumns') || [];
    const newColumns = frozenColumns.concat({ dataSource, view, column });
    setItem('frozenColumns', newColumns);
};

const removeFrozenColumn = (dataSource, view, column) => {
    const frozenColumns = getItem('frozenColumns') || [];
    const newColumns = frozenColumns.filter(
        (item) => item.dataSource !== dataSource || item.view !== view || item.column !== column
    );
    setItem('frozenColumns', newColumns);
};

const { ContextMenuTrigger } = Menu;

const getFinalColumns = (activeSchema, dataSourceObj, view) => {
    if ((!activeSchema, !dataSourceObj, !view)) {
        return [];
    }
    const dataSource = dataSourceObj.name;
    const listOfFrozenColumns = getFrozenColumns(dataSource, view);
    const columns = activeSchema;
    const idColumn = columns.find((column) => column.key === dataSourceObj.uniqueIdField);
    if (idColumn) {
        idColumn.grayOutColumn();
    }
    const frozenColumns = columns.filter((col) => listOfFrozenColumns.includes(col.name));
    frozenColumns.map((column) => column.freezeColumn());
    const unfrozenColumns = columns.filter((col) => !listOfFrozenColumns.includes(col.name));
    unfrozenColumns.map((column) => column.unfreezeColumn());
    return [...frozenColumns, ...unfrozenColumns].map((col) => ({ ...col }));
};

const DEFAULT_SEARCH_PAGE = 1;
const DEBOUNCE_LIMIT = 2000;
class ResultsTable extends Component {
  debouncedFilterUpdate = debounce((filters) => {
      const { setColumnsFilter } = this.props;
      setColumnsFilter(filters);
      this.setState({
          filters,
          defaultFilterSearchPage: filters.length ? DEFAULT_SEARCH_PAGE : null,
      });
  }, DEBOUNCE_LIMIT);

  constructor(props) {
      super(props);
      this.state = {
          rows: [],
          columns: [],
          filters: [],
          selectedRows: new Set(),
          sort: {
              column: null,
              direction: null,
          },
          defaultFilterSearchPage: null,
          errorMessage: null,
          isLoading: false,
      };
      this.gridRef = React.createRef();
      this.userService = new UserService();
  }

  componentWillUnmount() {
      const { resetColumnsFilter } = this.props;
      resetColumnsFilter();
  }

  componentDidMount = async () => {
      const {
          activeSchema,
          dataSource,
          activeView,
      } = this.props;

      if (this.activeSchemaLoadedEvent()) {
          this.setState({
              columns: getFinalColumns(activeSchema, dataSource, activeView),
          });
      }
      await this.loadData();
  };

  componentDidUpdate = async (prevProps, prevState) => {
      const {
          activeSchema,
          dataSource,
          activeView,
          refreshTableData,
      } = this.props;

      const {
          sort,
      } = this.state;

      // REFRESH_TABLE_DATA: binds to dataRefreshAction.refreshTableData
      const receivedTableRefreshEvent = refreshTableData !== prevProps.refreshTableData;
      const activeSchemaChanged = this.activeSchemaChangedEvent(prevProps);
      const dataSourceChanged = this.dataSourceChangedEvent(prevProps);
      const activeQueryChanged = this.activeQueryChangedEvent(prevProps);
      const sortFilterPageChanged = this.sortFilterPageChangedEvent(prevProps, prevState);

      if (receivedTableRefreshEvent) {
          Log.debug('results table received refresh event');
          await this.loadData();
          return; // stop execution
      }

      if (activeSchemaChanged) {
          this.handleClearFilters();
          this.toggleDataGridFilters();
          this.resetGridView();
          this.setState({
              columns: getFinalColumns(activeSchema, dataSource, activeView),
          });
      }

      if (dataSourceChanged && sort.column) {
          this.gridRef.setState({ sortColumn: null, sortDirection: null });
          await this.sortRows(null, null);
      }

      if (sortFilterPageChanged || activeQueryChanged || dataSourceChanged) {
          this.setState({ selectedRows: new Set() });
          await this.loadData();
      }

      if (activeQueryChanged || dataSourceChanged) {
          this.handleClearFilters();
          this.toggleDataGridFilters();
      }
  };

  applyGridFilter = () => {
      const { activeQuery, dataSource } = this.props;
      const { filters } = this.state;

      return new IFilterGrid(activeQuery || dataSource.query, filters).buildQuery();
  };

  getSelectedIds = () => {
      const { selectedRows } = this.state;
      const { dataSource } = this.props;
      return Array.from(selectedRows).map((currentRow) => currentRow[dataSource.uniqueIdField]);
  };

  getPageIndex = () => {
      const { page } = this.props;
      const { defaultFilterSearchPage } = this.state;
      return defaultFilterSearchPage || page;
  };

  loadData = async () => {
      try {
          const { sort } = this.state;
          const {
              activeView,
              clearIdCollectionAction,
              resetFeature: resetFeatureAction,
              dataSource,
              onRecordCountReceived,
          } = this.props;

          this.setState({
              isLoading: true,
              selectedRows: new Set(),
              errorMessage: null,
          });
          clearIdCollectionAction();
          resetFeatureAction();

          const pageIndex = this.getPageIndex();
          const query = this.applyGridFilter();
          const request = IProjectRecordRequest.load(dataSource.id, query, activeView, sort);

          const records = await projectRecordService.getProjectRecords(pageIndex, request);
          onRecordCountReceived(records.recordCount);
          this.setState({ rows: records.data.map(this.prepareRows), isLoading: false });
          resetFeatureAction();
      } catch (err) {
          this.setState({ errorMessage: 'Unable to load data.' });
          Log.error('load data error');
          Log.error(err);
          Log.dump();
      }
  };

  activeSchemaLoadedEvent = () => {
      const { activeSchema } = this.props;
      return activeSchema && activeSchema.length;
  };

  activeSchemaChangedEvent = (prevProps) => {
      const { activeSchema } = this.props;
      return !(activeSchema.length === prevProps.activeSchema.length
      && activeSchema.every((value, index) => value === prevProps.activeSchema[index]));
  };

    dataSourceChangedEvent = (prevProps) => {
        const { dataSource } = this.props;
        return dataSource.id !== prevProps.dataSource.id;
    };

  activeQueryChangedEvent = (prevProps) => {
      const { activeQuery } = this.props;
      return activeQuery !== prevProps.activeQuery;
  };

  sortFilterPageChangedEvent = (prevProps, prevState) => {
      const { page } = this.props;
      const { sort, filters } = this.state;

      const pageChanged = page !== prevProps.page;
      const filtersChanged = !(
          filters.length === prevState.filters.length
          && filters.every((value, index) => value === prevState.filters[index])
      );
      const sortChanged = JSON.stringify(sort) !== JSON.stringify(prevState.sort);

      return [
          pageChanged,
          filtersChanged,
          sortChanged,
      ].includes(true);
  };

  resetGridView = () => {
      const el = this.gridRef.getDataGridDOMNode().querySelector('.react-grid-Canvas');
      if (el) {
          el.scrollTop = 0;
          el.scrollLeft = 0;
      }
  };

  prepareRows = (row) => Object.entries(row).reduce(
      (a, [key, value]) => (value == null ? a : (a[key] = value, a)), {}
  );

  onRowClick = async (rowIdx, row) => {
      if (rowIdx === -1) {
          return;
      }

      const newRow = row;
      const {
          dataSource,
          setIdCollection: setIds,
          resetFeature: resetFeatureAction,
          fetchFeatureThunk: fetchFeatureAction,
      } = this.props;
      const { selectedRows } = this.state;
      const uniqueId = dataSource.uniqueIdField;

      if (newRow.isSelected || this.getSelectedIds().includes(newRow[uniqueId])) {
          newRow.isSelected = false;
          selectedRows.forEach((selectedRow) => (selectedRow[uniqueId] === newRow[uniqueId]
              ? selectedRows.delete(selectedRow) : selectedRow));
      } else {
          newRow.isSelected = true;
          if (!this.getSelectedIds().includes(newRow[uniqueId])) {
              selectedRows.add(newRow);
              fetchFeatureAction(dataSource.id, row[uniqueId]);
          }
      }

      const selectedIds = Array.from(selectedRows).map((currentRow) => currentRow[uniqueId]);

      if (selectedIds.length) {
          setIds(selectedIds);
      } else {
          setIds([]);
          resetFeatureAction();
      }

      this.setState({
          selectedRows,
      });
  };

  onGridRowsUpdated = async ({ fromRow, toRow, updated }) => {
      const {
          dataSource, activeSchema, addAlertThunk: showAlert, fetchFeatureThunk: fetchFeatureAction,
      } = this.props;
      const { rows } = this.state;
      const gridRows = rows.slice();
      const database = dataSource.databaseReference;
      const table = dataSource.tableReference;
      const field = Object.keys(updated)[0];
      const newValue = updated[field];
      const fields = [
          {
              name: field,
              displayName: null,
              value: newValue,
              isReadOnly: null,
              dataType: activeSchema.find((column) => column.key === field),
              lookupValues: null,
          },
      ];
      const results = [];
      for (let i = fromRow; i <= toRow; i += 1) {
          const currentIndex = i;
          const id = gridRows[currentIndex][dataSource.uniqueIdField];
          const curVal = gridRows[currentIndex][field];

          if (newValue !== curVal && this.validateDataGridInput(newValue, field)) {
              const rowToUpdate = gridRows[currentIndex];
              gridRows[currentIndex] = update(rowToUpdate, { $merge: updated });
              this.setState({ rows: gridRows });

              results.push(editData(
                  database,
                  table,
                  dataSource.uniqueIdField,
                  id,
                  fields
              ).then((result) => {
                  if (result.data && result.data.length > 0 && Object.prototype.hasOwnProperty.call(result.data[0], 'msg')) {
                      showAlert(
                          'danger',
                          'Unable to Update',
                          'There was an error trying to update your request.'
                      );
                      Log.debug(result.data[0].msg);
                      return;
                  }
                  fetchFeatureAction(dataSource.id, rowToUpdate[dataSource.uniqueIdField]);
              }).catch((err) => {
                  showAlert('danger', 'Edit Not Saved', err);
              }));
          }
      }
      await Promise.all(results);
  };

    onFilterChange = (filterInput) => {
        const { filters } = this.state;
        let updatedFilters = filters.filter((expression) => expression.column.key !== filterInput.column.key);
        if (filterInput.filterTerm) {
            const newFilter = new FilterGridExpression(filterInput.column, filterInput.filterTerm);
            updatedFilters = [...updatedFilters, newFilter];
        }
        this.debouncedFilterUpdate(updatedFilters);
    };

  sortRows = (sortColumn, sortDirection) => new Promise((resolve) => {
      switch (sortDirection) {
      case 'ASC':
      case 'DESC':
          return this.setState({ sort: { column: sortColumn, direction: sortDirection } });
      case 'NONE':
      default:
          return this.setState({ sort: { column: null, direction: null } }, resolve);
      }
  });

  onFreezeColumn = (_evt, column, element) => {
      const { columns } = this.state;
      const { dataSource, activeView, activeSchema } = this.props;
      const childElements = Array.from(element.children[0].children);
      const columnHeader = childElements.find((child) => child.id === 'column-lock-label');
      let selectedColumn;
      if (columnHeader) {
          selectedColumn = columns.find((columnListItem) => columnListItem.name === columnHeader.innerHTML);
      } else {
          selectedColumn = column;
      }
      if (!selectedColumn.frozen) {
          addFrozenColumn(dataSource.name, activeView, selectedColumn.name);
      } else {
          removeFrozenColumn(dataSource.name, activeView, selectedColumn.name);
      }

      const newColumns = getFinalColumns(activeSchema, dataSource, activeView);

      this.setState({ columns: newColumns });
  };

   toggleDataGridFilters = () => {
       if (this.gridRef.state.canFilter) {
           this.gridRef.onToggleFilter();
       }
   };

  handleClearFilters = () => {
      const { resetColumnsFilter } = this.props;
      this.setState({ filters: [], defaultFilterSearchPage: null });
      resetColumnsFilter();
  };

  validateDataGridInput(newValue, field) {
      const { activeSchema, addAlertThunk: showAlert } = this.props;
      const activeColumn = activeSchema.find((column) => column.key === field);
      try {
          activeColumn.validateInput(newValue);
          return true;
      } catch (err) {
          showAlert('danger', 'Edit Not Saved', err || err.message);
          return false;
      }
  }

  renderOverlay() {
      const { errorMessage, isLoading } = this.state;

      if (errorMessage) {
          return (
              <ErrorMessage
                  message={errorMessage}
                  onDismiss={() => this.setState({ errorMessage: null })}
              />
          );
      }

      if (isLoading) {
          return <LoadingFullScreen />;
      }

      return null;
  }

  render() {
      const { width, match } = this.props;
      const { columns, rows } = this.state;
      const MIN_HEIGHT = 625;
      return (
          <div className="results-table-component">
              {this.renderOverlay()}
              <ReactDataGrid
                  contextMenu={(
                      <ContextMenu
                          onFreezeColumn={this.onFreezeColumn}
                          columns={columns}
                          match={match}
                          rows={rows}
                      />
                  )}
                  RowsContainer={ContextMenuTrigger}
                  enableCellSelect
                  columns={columns}
                  rowGetter={(i) => rows[i]}
                  rowsCount={rows.length}
                  onRowClick={this.onRowClick}
                  onGridRowsUpdated={this.onGridRowsUpdated}
                  rowRenderer={RowRenderer}
                  minHeight={MIN_HEIGHT}
                  minWidth={width}
                  toolbar={<Toolbar enableFilter />}
                  onAddFilter={this.onFilterChange}
                  onGridSort={this.sortRows}
                  onClearFilters={() => this.handleClearFilters()}
                  ref={(g) => {
                      this.gridRef = g;
                  }}
              />
          </div>
      );
  }
}

const mapStateToProps = (state) => ({
    activeView: state.activeConfiguration.view,
    activeQuery: state.activeConfiguration.query,
    activeSchema: state.activeConfiguration.schema,
    refreshTableData: state.refreshTableData,
});

const mapDispatchToProps = {
    setFeature,
    addAlertThunk,
    fetchFeatureThunk,
    setIdCollection,
    resetFeature,
    setColumnsFilter: setTableDataColumnsFilter,
    resetColumnsFilter: resetTableDataColumnsFilter,
    clearIdCollectionAction: clearIdCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsTable);

ResultsTable.propTypes = {
    addAlertThunk: PropTypes.func,
    dataSource: PropTypes.instanceOf(DataSource),
    activeView: PropTypes.string,
    activeQuery: PropTypes.string,
    activeSchema: PropTypes.instanceOf(Array),
    page: PropTypes.number,
    setFeature: PropTypes.func,
    setIdCollection: PropTypes.func,
    fetchFeatureThunk: PropTypes.func,
    resetFeature: PropTypes.func,
    clearIdCollection: PropTypes.func,
    setColumnsFilter: PropTypes.func,
    resetColumnsFilter: PropTypes.func,
    refreshTableData: PropTypes.bool,
    width: PropTypes.number,
    match: PropTypes.shape({
        path: PropTypes.string,
        url: PropTypes.string,
        params: PropTypes.instanceOf(Object),
    }).isRequired,
};

ResultsTable.defaultProps = {
    addAlertThunk: () => null,
    dataSource: new DataSource(),
    activeView: '',
    activeQuery: '',
    activeSchema: [],
    page: 1,
    setFeature: () => null,
    fetchFeatureThunk: () => null,
    clearIdCollection: () => null,
    resetFeature: () => null,
    setIdCollection: () => null,
    setColumnsFilter: () => null,
    resetColumnsFilter: () => null,
    refreshTableData: false,
    width: 0,
};
