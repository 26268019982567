import { RESET_ACTIVE_MODULE } from '../CONSTANTS/types';
import { DataSourceService } from '../_services/GeotrakService';
import { addAlertThunk } from './errorActions';
import { resetActiveViewThunk } from './viewActions';

export const resetActiveModule = {
    type: RESET_ACTIVE_MODULE,
};

export const activateModuleThunk = (loadedDataSourceId, history) => async (dispatch) => {
    try {
        const defaultDataSource = await new DataSourceService().getDataSource(loadedDataSourceId);
        dispatch(resetActiveViewThunk(defaultDataSource));
        history.push(`${history.location.pathname}?data-source=${defaultDataSource.id}`);
    } catch (err) {
        dispatch(addAlertThunk('danger', 'Error Loading Module', err));
    }
};
