import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LoadingFullScreen from '../../utilComponents/LoadingFullScreen';
import { MapService, ViewService } from '../../_services/GeotrakService';
import { MapConfig } from '../../_services/GeotrakService/Models';
import Toolbar from '../../utilComponents/Toolbar';
import LeafletMap from './LeafletMap';
import '../stylesheets/MapView.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import useGetDataSource from '../../_services/GeotrakService/hooks/useGetDataSource';
import IGetViewRequest from '../../_services/GeotrakService/interfaces/IGetViewRequest';
import { setQuery } from '../../actions/queryActions';

const mapView = ({ match = {} }) => {
    const history = useHistory();
    const searchParameters = new URLSearchParams(history.location.search);
    const { dataSource } = useGetDataSource(searchParameters.get('data-source'));
    const mapService = new MapService();
    const viewService = new ViewService();
    const activeView = useSelector((state) => state.activeConfiguration.view);
    const [isLoading, setIsLoading] = useState(true);
    const [mapConfig, setMapConfig] = useState(new MapConfig());
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    const setupMap = async () => {
        try {
            const mapServiceConfig = await mapService.getModuleMap(dataSource.moduleRoleId);
            setIsLoading(false);
            setMapConfig(mapServiceConfig);
        } catch (err) {
            setError(`Error Getting Map Configuration: ${err.message}`);
        }
    };

    const loadMap = async () => {
        try {
            const request = IGetViewRequest.load(dataSource.id, activeView);
            const viewResult = await viewService.getView(request);
            dispatch(setQuery(dataSource, viewResult));
            setupMap();
        } catch (err) {
            setError(`Error Setting Up Map: ${err.message}`);
        }
    };

    useEffect(() => {
        if (dataSource.id) {
            loadMap();
        }
    }, [dataSource]);

    if (isLoading) {
        return <LoadingFullScreen />;
    }

    return (
        <div id="map-view">
            {error && <div id="map-view-error" className="text-danger">{error}</div>}
            {mapConfig.id && (
                <LeafletMap mapConfig={mapConfig} dataSource={dataSource} />
            )}
            <Toolbar match={match} />
        </div>
    );
};

export default mapView;

mapView.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string,
        url: PropTypes.string,
    }),
};
