/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const devAdminPrivateRoute = ({ component: Component, user = {}, ...rest }) => {
    if (!user || !user.Id) {
        return null;
    }
    const permission = user.Admin;
    return (
        <Route
            {...rest}
            render={(props) => (permission === 'DEV' ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/unauthorized',
                        state: { from: props.location },
                    }}
                />
            ))}
        />
    );
};

export default devAdminPrivateRoute;

devAdminPrivateRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(Object)]),
    user: PropTypes.instanceOf(Object),
    location: PropTypes.instanceOf(Object),
};
