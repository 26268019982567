import React, { useState, useEffect } from 'react';
import { CheckRequestConfigService, CheckRequestService } from '../../_services';
import ICheckRequestsRequest from '../../_services/CheckRequestService/interfaces/ICheckRequestsRequest';
import CheckRequestTable from './CheckRequestTable';
import withCurrentUser from '../../utilComponents/hocs/withCurrentUser';
import { UserType } from '../../types';
import ErrorMessage from '../../utilComponents/ErrorMessage';

const checkRequestService = new CheckRequestService();
const checkRequestConfigService = new CheckRequestConfigService();

const needsReview = ({ user = {}, tableWidth }) => {
    const [activeQueue, setActiveQueue] = useState(null);
    const [queues, setQueues] = useState([]);
    const [error, setError] = useState(null);
    const getQueueQuantites = (recordQueues = queues) => {
        const countPromises = recordQueues.map((queue) => {
            const page = 1;
            const requestBody = ICheckRequestsRequest.load(user, [], page, queue.Label, queue.Code, null);
            return checkRequestService.getCheckRequests(requestBody);
        });
        Promise.all(countPromises).then((results) => {
            const queueList = recordQueues.map((queue, index) => {
                const updatedQueue = queue;
                updatedQueue.quantity = results[index].pager.totalItems;
                return updatedQueue;
            });
            const currentlyActiveQueue = queueList.find((queue) => queue.Label === activeQueue);
            if (!currentlyActiveQueue || currentlyActiveQueue.quantity < 1) {
                const currentQueue = queueList.find((queue) => queue.quantity > 0);
                setActiveQueue(currentQueue ? currentQueue.Label : null);
            }
            setQueues(queueList);
        }).catch((err) => {
            setError(`Error Getting Requests, ${err.message || err}`);
        });
    };

    const clearErrorMessage = () => {
        setError(null);
    };

    const getQueues = () => {
        checkRequestConfigService.getQueues().then((results) => {
            const queueList = results.map((queue) => {
                const updatedQueue = queue;
                updatedQueue.quantity = 0;
                return updatedQueue;
            });
            setQueues(queueList);
            getQueueQuantites(queueList);
        }).catch((err) => {
            setError(`Error Getting Queues, ${err.message || err}`);
        });
    };

    const renderTabContent = () => (
        error ? <ErrorMessage message={error.message || error} onDismiss={clearErrorMessage} />
            : queues.map((queue) => {
                if (queue.quantity > 0) {
                    return (
                        <div
                            role="tabpanel"
                            key={`${queue.Label}-content`}
                            className={`tab-pane ${activeQueue === queue.Label ? 'active' : ''}`}
                            id={queue.Label}
                        >
                            <CheckRequestTable
                                subset={queue.Label}
                                role={queue.Code}
                                refreshQueues={getQueues}
                                tableWidth={tableWidth}
                            />
                        </div>
                    );
                }
                return null;
            }));

    const renderTabs = () => queues.map((queue) => {
        if (queue.quantity > 0) {
            return (
                <li role="presentation" key={`${queue.Label}-tab`} className="nav-item">
                    <button
                        className={`nav-link ${(queue.Label === activeQueue ? 'active' : '')}`}
                        type="button"
                        role="tab"
                        aria-controls={queue.Label}
                        data-toggle="tab"
                        tabIndex={0}
                        data-target={queue.Label}
                        aria-selected={queue.Label === activeQueue}
                        onClick={() => {
                            setActiveQueue(queue.Label);
                            window.dispatchEvent(new Event('resize'));
                        }}
                        onKeyDown={() => setActiveQueue(queue.Label)}
                    >
                        {`${queue.Label} (${queue.quantity})`}
                    </button>
                </li>
            );
        }
        return null;
    });

    useEffect(() => {
        getQueues();
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <ul role="tablist" className="nav nav-tabs">
                        {renderTabs()}
                    </ul>
                    <div className="tab-content">
                        {renderTabContent()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withCurrentUser(needsReview);

needsReview.propTypes = {
    user: UserType,
};
