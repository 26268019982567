import { SET_LAYER_VISIBILITY } from '../CONSTANTS/types';

export const setLayerVisibilityAction = (visibleLayers) => ({
    type: SET_LAYER_VISIBILITY,
    payload: visibleLayers,
});

export const setLayerVisibility = (layerId, isVisible) => (dispatch) => (
    dispatch(setLayerVisibilityAction({ [layerId]: isVisible }))
);
