import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage';
import { DatabaseService } from '../_services/GeotrakService';
import IGetSchemasRequest from '../_services/GeotrakService/interfaces/IGetSchemasRequest';

const selectSchema = ({
    selectedDatabase = null,
    onSchemaSelected = () => null,
    selectedSchema = null,
}) => {
    const [schemas, setSchemas] = useState([]);
    const [database, setDatabase] = useState(selectedDatabase || '');
    const [value, setValue] = useState(selectedSchema || '');
    const [error, setError] = useState(null);

    const getSchemas = async () => {
        try {
            const request = IGetSchemasRequest.load(selectedDatabase);
            const results = await new DatabaseService().getSchemas(request);
            setSchemas(results);
        } catch (err) {
            setError(err);
        }
    };

    const onSchemaChanged = (e) => {
        setValue(e);
        onSchemaSelected(e);
    };

    useEffect(() => {
        if (selectedDatabase) {
            setDatabase(selectedDatabase);
            getSchemas();
            if (database !== selectedDatabase) {
                setValue('');
            } else {
                setValue(selectedSchema);
            }
        }
        if (!database) {
            setValue(selectedSchema || '');
        }
    }, [selectedDatabase, selectedSchema]);

    if (error) {
        return (
            <ErrorMessage message={error.message || error} />
        );
    }

    return (
        <div className="mb-3">
            <label htmlFor="select-schema" className="form-label">
                Schema
                {' '}
                <span className="text-danger">*</span>
            </label>
            <select
                id="select-schema"
                value={value}
                onChange={(e) => onSchemaChanged(e.target.value)}
            >
                <option disabled value="">--Select a schema--</option>
                {schemas.map((schema) => (
                    <option value={schema} id="select-schema-option" key={schema}>{schema}</option>
                ))}
            </select>
        </div>
    );
};

export default selectSchema;

selectSchema.propTypes = {
    selectedDatabase: PropTypes.string,
    onSchemaSelected: PropTypes.func,
    selectedSchema: PropTypes.string,
};
