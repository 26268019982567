import { useEffect, useReducer } from 'react';
import { useApiReducer, ACTIONS } from '../../../helpers/hooks/useApiReducer';
import UserService from '../UserService';

const useGetUser = (id) => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const { data, isLoading, error } = state;

    const getUser = async () => {
        try {
            const result = await new UserService().getUserById(id);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.CALL });
        getUser();
    }, []);

    return { user: data, error, isLoading };
};

export default useGetUser;
