import React from 'react';
import { ButtonContainer } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useNotificationUsersForm from './useNotificationUsersForm';
import { CreateUser, DeleteUser } from './Actions';
import { NotificationUser } from '../../../_services/NotificationService/Models';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminNotificationsUsersForm = ({ match }) => {
    const { params: { id, userId } } = match;
    const {
        users, values, onFormUpdate, isLoading, isValid,
    } = useNotificationUsersForm(userId);

    if (isLoading) {
        return <LoadingFullScreen id="notification-user-form-loader" />;
    }

    const notificationUser = new NotificationUser();
    notificationUser.username = values.username;
    notificationUser.notificationId = id;
    notificationUser.id = userId;

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Notification Users">
                <form>
                    <div className="mb-3">
                        <label htmlFor="select-user" className="form-label">
                            User
                            {' '}
                            <span className="text-danger">*</span>
                        </label>
                        <select
                            id="select-user"
                            disabled={!!userId}
                            value={values.username}
                            onChange={(e) => onFormUpdate('username', e.target.value)}
                        >
                            <option disabled value="">--Select a user--</option>
                            {users.map((user) => (
                                <option id="select-user-option" key={user.username} value={user.username}>{user.name}</option>
                            ))}
                        </select>
                    </div>
                    <ButtonContainer>
                        <CreateUser notificationUser={notificationUser} isValid={isValid} />
                        <DeleteUser notificationUser={notificationUser} />
                    </ButtonContainer>
                </form>
            </AdminContainer>
        </>
    );
};

export default adminNotificationsUsersForm;
