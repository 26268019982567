import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonPrimary } from '../../../utilComponents/styledComponents';
import Modal from '../../../components/Modals/Modal';
import DataSourcePicker from './DataSourcePicker';
import AppPicker from './AppPicker';
import ViewPicker from './ViewPicker';
import { ViewService } from '../../../_services/GeotrakService';
import withWizard from '../../../utilComponents/hocs/withWizard';
import IGetViewRequest from '../../../_services/GeotrakService/interfaces/IGetViewRequest';
import ErrorMessage from '../../../utilComponents/ErrorMessage';

const viewImport = ({ onFieldImport = () => null }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(false);
    const viewService = new ViewService();
    const steps = {
        SET_APP: AppPicker,
        SET_DATA_SOURCE: DataSourcePicker,
        SET_VIEW: ViewPicker,
    };

    const onImport = (dataSourceId, view) => {
        const request = IGetViewRequest.load(dataSourceId, view);
        viewService.getSystemView(request).then((viewRecord) => {
            onFieldImport(viewRecord.fields);
            setIsOpen(false);
        }).catch((err) => {
            onFieldImport([]);
            setError(`Error Getting View. ${err.message || err}`);
            setIsOpen(false);
        });
    };

    const ViewImportWizard = withWizard(steps, null, { isImport: true, onImport });
    return (
        <>
            <ButtonPrimary onClick={() => setIsOpen(true)}>Import from Another View</ButtonPrimary>
            {error && (
                <ErrorMessage
                    message={error}
                    onDismiss={() => setError(null)}
                />
            )}
            <Modal
                showModal={isOpen}
                header="View Field Import"
                onCancel={() => setIsOpen(false)}
                component={<ViewImportWizard />}
            />
        </>
    );
};

export default viewImport;

viewImport.propTypes = {
    onFieldImport: PropTypes.func,
};
