import React from 'react';
import Pagination from 'react-js-pagination';
import PropTypes from 'prop-types';
import './stylesheets/Pager.css';

const PAGE_RANGE = 10;
const PAGE_SIZE = 50;

const pager = ({ page, onPageChange, recordCount }) => (
    <div className="mt-2">
        <Pagination
            activePage={page}
            itemsCountPerPage={PAGE_SIZE}
            totalItemsCount={recordCount}
            pageRangeDisplayed={PAGE_RANGE}
            onChange={(e) => onPageChange(e)}
            itemClass="page-item"
            linkClass="page-link"
            className="bg-primary"
        />
    </div>
);

export default pager;

pager.propTypes = {
    onPageChange: PropTypes.func,
    page: PropTypes.number,
    recordCount: PropTypes.number,
};

pager.defaultProps = {
    onPageChange: () => null,
    page: 1,
    recordCount: 0,
};
