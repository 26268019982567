import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setActiveViewThunk } from '../../actions/viewActions';
import { ViewService } from '../../_services/GeotrakService';
import useGetDataSource from '../../_services/GeotrakService/hooks/useGetDataSource';
import IGetFavoriteViewsRequest from '../../_services/GeotrakService/interfaces/IGetFavoriteViewsRequest';
import IGetViewsRequest from '../../_services/GeotrakService/interfaces/IGetViewsRequest';
import { Favorite, Unfavorite } from './Actions';
import ErrorMessage from '../../utilComponents/ErrorMessage';
import './viewsList.css';

const viewsList = ({ onPageChange = () => null }) => {
    const [views, setViews] = useState([]);
    const [favoriteViews, setFavoriteViews] = useState([]);
    const [error, setError] = useState(null);
    const urlParam = 'data-source';
    const activeView = useSelector((state) => state.activeConfiguration.view);
    const history = useHistory();
    const dispatch = useDispatch();
    const searchParameters = new URLSearchParams(history.location.search);
    const { dataSource } = useGetDataSource(searchParameters.get(urlParam));
    const viewService = new ViewService();

    const getFavoriteViews = async () => {
        try {
            const request = IGetFavoriteViewsRequest.load(searchParameters.get(urlParam));
            const result = await viewService.getFavoriteViews(request);
            setFavoriteViews(result);
        } catch (err) {
            setError({ message: 'Error getting favorite views' });
        }
    };

    const getViews = async () => {
        try {
            const request = IGetViewsRequest.load(searchParameters.get(urlParam));
            const result = await viewService.getViews(request);

            setViews(result);
            getFavoriteViews();
        } catch (err) {
            setError({ message: 'Error loading views' });
        }
    };

    const changeView = (view) => {
        dispatch(setActiveViewThunk(dataSource, view));
        onPageChange(1);
    };

    const organizeViewList = (viewA, viewB) => {
        const isAFavorite = favoriteViews.some((favoriteView) => favoriteView.viewName === viewA);
        const isBFavorite = favoriteViews.some((favoriteView) => favoriteView.viewName === viewB);
        if (isAFavorite && isBFavorite) {
            return viewA > viewB ? 1 : -1;
        }
        if (isAFavorite && !isBFavorite) {
            return -1;
        }
        if (!isAFavorite && isBFavorite) {
            return 1;
        }
        return viewA > viewB ? 1 : -1;
    };

    const isFavoriteView = (view) => favoriteViews.some((favoriteView) => favoriteView.viewName === view.name);

    useEffect(() => {
        if (dataSource.id) {
            getViews();
        }
    }, [dataSource, activeView]);

    const viewNames = views.map((view) => view.name);
    const sortedViews = viewNames.sort(organizeViewList);
    return (
        <div>
            {error && <ErrorMessage message={error.message} onDismiss={() => setError(null)} />}
            {sortedViews.map((viewName) => {
                const view = views.find((viewItem) => viewItem.name === viewName);
                return (
                    <div
                        key={view.name}
                        className="views-list-view-name-container"
                        onClick={() => changeView(view)}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => changeView(view)}
                    >
                        <div
                            className={`views-list-view-name ${(view.name === activeView) && 'views-list-selected'} ${view.owner && 'views-list-custom'}`}
                        >
                            <span>
                                {isFavoriteView(view)
                                    ? (
                                        <Unfavorite
                                            view={view}
                                            refreshFavoriteList={getFavoriteViews}
                                            favoriteViews={favoriteViews}
                                            onError={setError}
                                        />
                                    )
                                    : (
                                        <Favorite
                                            view={view}
                                            isSelected={view.name === activeView}
                                            refreshFavoriteList={getFavoriteViews}
                                            onError={setError}
                                        />
                                    )}

                            </span>
                            <span>{view.name}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default viewsList;
