import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import '../dataSources.css';
import { DataSource } from '../../../../_services/GeotrakService/Models';
import useUpdateDataSource from '../../../../_services/GeotrakService/hooks/useUpdateDataSource';

const updateButton = ({ dataSource = new DataSource(), moduleId = null, isValid = () => null }) => {
    const {
        updateDataSource, error, isLoading, isSuccessful,
    } = useUpdateDataSource();

    if (!dataSource.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/modules/${moduleId}/roles/${dataSource.moduleRoleId}/data-sources`} />;
    }

    return (
        <div className="data-source-toolbar data-source-button">
            {error && <div className="text-danger data-source-error" id="update-data-source-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="update-data-source-button"
                text="Update"
                loadingText="Updating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => updateDataSource(dataSource)}
            />
        </div>
    );
};

export default updateButton;

updateButton.propTypes = {
    dataSource: PropTypes.instanceOf(DataSource),
    isValid: PropTypes.func,
    moduleId: PropTypes.string,
};
