import PropTypes from 'prop-types';
import { Role } from '../../GeotrakService/Models';

export default class User {
    constructor() {
        this.id = null;
        this.username = null;
        this.name = null;
        this.emailAddress = null;
        this.phoneNumber = null;
        this.adminPermission = null;
        this.createdOn = null;
        this.createdBy = null;
        this.updatedOn = null;
        this.updatedBy = null;
        this.isDeleted = false;
        this.deletedOn = null;
        this.deletedBy = null;
        this.roles = [];
    }

    static load(response) {
        const user = new User();

        if (response) {
            user.id = response.Id;
            user.username = response.userid;
            user.name = response.FullName;
            user.emailAddress = response.emailAddress;
            user.phoneNumber = response.phoneNumber;
            user.adminPermission = response.Admin;
            user.createdOn = response.createdOn;
            user.createdBy = response.createdBy;
            user.updatedOn = response.updatedOn;
            user.updatedBy = response.updatedBy;
            user.isDeleted = response.IsDeleted;
            user.deletedOn = response.deletedOn;
            user.deletedBy = response.deletedBy;
            user.roles = response.roles ? response.roles.map((role) => Role.load(role)) : [];
        }

        return user;
    }

    loadSqlEntity() {
        const sqlEntity = {
            userid: this.username,
            Admin: this.adminPermission,
            FullName: this.name,
            emailAddress: this.emailAddress,
            phoneNumber: this.phoneNumber,
            IsDeleted: this.isDeleted,
            roles: this.roles,
        };

        if (this.id) {
            sqlEntity.Id = this.id;
        }

        return sqlEntity;
    }

    toggleActivation() {
        this.isDeleted = !this.isDeleted;
    }
}

User.propTypes = {
    id: PropTypes.string,
    username: PropTypes.string,
    name: PropTypes.string,
    emailAddress: PropTypes.string,
    phoneNumber: PropTypes.string,
    adminPermission: PropTypes.string,
    createdOn: PropTypes.string,
    createdBy: PropTypes.string,
    updatedOn: PropTypes.string,
    updatedBy: PropTypes.string,
    isDeleted: PropTypes.bool,
    deletedOn: PropTypes.string,
    deletedBy: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.instanceOf(Role)),
};
