import React, { Component } from 'react';
import { MdArrowBack, MdArrowForward, MdCancel } from 'react-icons/md';
import {
    FormService, Role, GeotrakKey
} from '../../_services';
import { WidthControl, SpinnerControl } from './Controls';
import { ButtonPrimary, ButtonDanger, ButtonContainer } from '../../utilComponents/styledComponents';
import {
    RoleService, ModuleService, DataSourceService, FieldService
} from '../../_services/GeotrakService';
import Modal from '../Modals/Modal';
import './Fix.css';
import './FormBuilder.css';

const FULL_WIDTH = 12;
const FULL_ROLE_LENGTH = 3;
const DECORATION_INDEX_LENGTH = 3;
const ROLE_SUFFIX_LENGTH = 2;
const FIELD_SUFFIX_LENGTH = 4;

class FormBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            availableFields: [],
            availableForms: [],
            selectedModule: '',
            modules: [],
            selectedRoles: [],
            selectedLayer: null,
            selectedProject: null,
            selectedField: null,
            selectedDecoration: null,
            selectedDecorationValue: '',
            roles: [],
            roleFields: [],
            roleFilter: null,
            projects: [],
            layers: [],
            form: {
                name: null,
                isActive: false,
                formFields: [],
                geoTrakKeys: [],
            },
            error: null,
            isCreateMode: false,
            isLoadingAvailableForms: false,
            showConfirmation: false,
        };
        this.doNothingHref = '#';
        this.doCascade = true;
        this.modules = ['', 'Landbase', 'Manager', 'Structural'];
        this.formRefs = {
            name: React.createRef(),
            isActive: React.createRef(),
        };
        this.FormService = new FormService();
        this.RoleService = new RoleService();
        this.DataSourceService = new DataSourceService();
        this.ModuleService = new ModuleService();
        this.FieldService = new FieldService();
    }

    resetState = () => {
        this.doCascade = true;
        this.setState({
            availableFields: [],
            availableForms: [],
            availableRoles: [],
            selectedModule: '',
            selectedRoles: [],
            selectedLayer: null,
            selectedProject: null,
            selectedField: null,
            selectedDecoration: null,
            roles: [],
            roleFields: [],
            projects: [],
            layers: [],
            form: {
                name: null,
                isActive: false,
                formFields: [],
                geoTrakKeys: [],
            },
            isCreateMode: false,
        });
    };

    returnPreparedForm = () => {
        const { form } = this.state;
        const currentForm = JSON.parse(JSON.stringify(form));
        currentForm.name = this.formRefs.name.current.value;
        currentForm.isActive = this.formRefs.isActive.current.checked;
        currentForm.roleId = currentForm.geoTrakKeys[0].roleId;
        currentForm.formFields = currentForm.formFields.map((formField, idx) => {
            const updatedFormField = { ...formField };
            updatedFormField.orderIndex = idx;
            return updatedFormField;
        });
        currentForm.geoTrakKeys.forEach((geoTrakKey, index) => {
            if (geoTrakKey.roleId.includes('*')) {
                const updatedRoleId = currentForm.geoTrakKeys[index].roleId.slice(0, -ROLE_SUFFIX_LENGTH);
                currentForm.geoTrakKeys[index].roleId = updatedRoleId;
            }
        });

        return currentForm;
    };

    createForm = () => {
        const { form } = this.state;
        if (form.formFields.length === 0) {
            return;
        }
        this.setState({ isLoadingAvailableForms: true });
        this.FormService.insert(this.returnPreparedForm())
            .then(() => {
                this.setState({ isLoadingAvailableForms: false });
            })
            .catch((error) => {
                this.setState({ error: error.message || error, isLoadingAvailableForms: false });
            });
    };

    updateForm = async () => {
        await this.FormService.updateForm(this.returnPreparedForm());
    };

    toGeotrakKeys = () => {
        const { selectedRoles } = this.state;
        return selectedRoles.map((role) => this.convertRoleToGeoTrakKey(role));
    };

    convertRoleToGeoTrakKey = (role) => {
        const { selectedModule, selectedProject, selectedLayer } = this.state;
        return new GeotrakKey(
            null,
            selectedModule,
            selectedProject,
            role.name,
            role.module.name,
            selectedLayer.name,
            selectedLayer.databaseReference,
            selectedLayer.tableReference
        );
    };

    handleSelectForm = async (evt) => {
        const { availableForms, modules, layers } = this.state;
        evt.preventDefault();
        this.doCascade = false;
        const selectedFormId = evt.currentTarget.dataset.id;
        const formGeoTrakKeys = [];
        availableForms.forEach((form) => {
            if (form.formId === selectedFormId) {
                formGeoTrakKeys.push(form.geoTrakKey);
            }
        });

        try {
            const selectedForm = await this.FormService.getById(selectedFormId).then((form) => form);
            selectedForm.geoTrakKeys = formGeoTrakKeys;
            const selectedRoles = selectedForm.geoTrakKeys.map((gk) => {
                const module = modules.find((item) => item.name === gk.roleAppReference);
                return Role.loadFromGeoTrakKey(gk, module);
            });
            this.setState({ selectedRoles });
            const selectedLayerName = selectedForm.geoTrakKeys[0].layerName;
            const availableRoles = await this.getAvailableRoles().then((roles) => roles);
            const selectedDataSource = layers.find((layer) => layer.name === selectedLayerName);
            const roleFields = await this.FieldService.getFields(selectedDataSource.id);
            const selectedFormsFields = selectedForm.formFields.map((formField) => formField.fieldName);
            const availableFields = roleFields.filter((formObj) => !selectedFormsFields.includes(formObj.name));
            this.setState({
                availableFields,
                availableRoles,
                selectedLayer: selectedLayerName,
                roleFields,
                form: selectedForm,
                layers: [selectedLayerName],
                roles: availableRoles,
                isCreateMode: true,
            });
        } catch (err) {
            this.setState({ error: err.message || err });
        }
    };

    getAvailableRoles = () => {
        const { selectedModule, selectedProject, selectedRoles } = this.state;
        const roleNodes = selectedRoles[0].name.split('-');
        return this.RoleService
            .getRolesByModuleProjectEndsWith(
                selectedModule,
                selectedProject,
                roleNodes.length === FULL_ROLE_LENGTH ? roleNodes[ROLE_SUFFIX_LENGTH] : '*'
            )
            .then((roles) => roles.map((role) => ({
                role,
                selected: selectedRoles.filter((sr) => role.name.includes(sr.name)).length > 0,
            })));
    };

  handleDeleteForm = () => {
      const { availableForms, form } = this.state;
      return this.FormService.deleteForm(form.id)
          .then(() => {
              const forms = availableForms.filter((f) => f.id !== form.id);
              this.setState({
                  availableForms: forms,
                  showConfirmation: false,
              });
              this.resetState();
          })
          .catch(() => null);
  };

  handleClickCreateForm = () => {
      const newForm = {
          name: null,
          isActive: false,
          geoTrakKeys: this.toGeotrakKeys(),
          formFields: [],
      };

      return this.getAvailableRoles().then((availableRoles) => {
          this.setState({
              availableRoles,
              isCreateMode: true,
              form: newForm,
          });
      });
  };

  handleClickCancel = () => {
      this.doCascade = true;
      this.resetState();
  };

  handleClickCreate = () => {
      this.createForm();
      this.resetState();
  };

  handleClickUpdate = () => {
      this.updateForm();
  };

handleClickFinish = () => {
    this.updateForm();
    this.resetState();
};

  handleMoveRight = () => {
      const { form } = this.state;
      const { formFields } = form;
      const fieldIndex = this.getSelectedFieldIndex();
      if (fieldIndex < 0 || fieldIndex + 1 >= formFields.length) {
          return;
      }

      const selectedField = formFields[fieldIndex];
      const poppedField = formFields[fieldIndex + 1];
      formFields[fieldIndex] = poppedField;
      formFields[fieldIndex + 1] = selectedField;

      form.formFields = formFields;

      this.setState({
          form,
      });
  };

  handleMoveLeft = () => {
      const { form } = this.state;
      const fieldIndex = this.getSelectedFieldIndex();
      if (fieldIndex < 1) {
          return;
      }

      const { formFields } = form;
      const selectedField = formFields[fieldIndex];
      const poppedField = formFields[fieldIndex - 1];
      formFields[fieldIndex] = poppedField;
      formFields[fieldIndex - 1] = selectedField;

      form.formFields = formFields;

      this.setState({
          form,
      });
  };

  getSelectedFieldIndex = () => {
      const { form } = this.state;
      return form.formFields.findIndex((f) => f.selected);
  };

  handleClickField = (evt) => {
      const { form } = this.state;
      const { formFields } = form;

      // remove old selected
      let fieldIndex = this.getSelectedFieldIndex();
      if (fieldIndex > -1) {
          formFields[fieldIndex].selected = null;
      }

      // add new selected
      fieldIndex = form.formFields.findIndex(
          (f) => f.fieldName === evt.target.dataset.fieldname
      );
      formFields[fieldIndex].selected = true;
      form.formFields = formFields;

      this.setState({
          form,
      });
  };

  handleClickWidth = (_evt, data) => {
      const { form } = this.state;
      const fieldIndex = form.formFields.findIndex((f) => f.fieldName === data.fieldName);

      const formFields = form.formFields.slice();
      formFields[fieldIndex].width = data.width;

      form.formFields = formFields;

      this.setState({
          form,
      });
  };

  handleSelectModule = (evt) => {
      if (!this.doCascade) {
          return;
      }

      const moduleName = evt.target.value;

      const { form } = this.state;
      form.formFields = [];

      this.setState({
          form,
          selectedModule: moduleName,
          selectedLayer: null,
          projects: [],
          roles: [],
          layers: [],
          availableFields: [],
          availableForms: [],
          isCreateMode: false,
      });

      this.ModuleService.getModules(moduleName).then((modules) => {
          const selectedModules = modules.filter((module) => module.unit.name === moduleName);
          this.setState({
              projects: [...new Set(selectedModules.map((module) => module.project.name))],
              modules,
          });
      });
  };

  handleSelectLayer = (evt) => {
      const { form, layers } = this.state;
      const layerId = evt.target.value;
      const selectedLayer = layers.find((f) => f.name === layerId);

      form.formFields = [];

      this.setState({
          selectedLayer,
          form,
          availableFields: [{ name: 'loading', displayName: 'Loading....' }],
      });

      this.loadAvailableFields(selectedLayer);
  };

  loadAvailableFields = (dataSource) => this.FieldService.getFields(dataSource.id).then((fields) => {
      this.setState({
          availableFields: fields,
      });
      return fields;
  });

  handleSelectProject = (evt) => {
      const selectedProject = evt.target.value;
      this.setState({ selectedProject, selectedRoles: [] });

      const { form, selectedModule } = this.state;
      form.formFields = [];

      this.setState({
          form,
          roles: [],
          layers: [],
          availableFields: [],
          selectedLayer: null,
          isCreateMode: false,
      });

      this.RoleService.getRoles().then((roles) => {
          const roleList = roles.filter(
              (role) => role.module.project.name === selectedProject && role.module.unit.name === selectedModule
          );
          this.setState({
              roles: roleList,
          });
      });
  };

  handleSelectRole = (evt) => {
      const {
          form, roles, selectedRoles,
      } = this.state;

      let selectedRolesList = [...selectedRoles];

      if (!this.doCascade) {
          return;
      }

      const roleName = evt.target.value;
      const last2Char = evt.target.value.substring(evt.target.value.length - ROLE_SUFFIX_LENGTH);
      this.setState({
          roleFilter: last2Char.startsWith('-') ? last2Char : 'none',
      });

      let selectedRole = roles.filter((f) => f.name === roleName)[0];

      if (evt.target.checked) {
          selectedRolesList.push(selectedRole);
      } else {
          selectedRolesList = selectedRoles.filter((f) => f.name !== roleName);
          if (selectedRoles.length > 0) {
              selectedRole = selectedRolesList[selectedRolesList.length - 1];
          }
      }

      form.formFields = [];

      this.setState({
          selectedRoles: selectedRolesList,
          layers: [],
          availableFields: [],
          isLoadingAvailableForms: true,
          form,
      });

      if (selectedRole) {
          this.DataSourceService.getDataSources(selectedRole.id).then((layers) => {
              this.setState({
                  layers,
              });
          });

          this.FormService.getFormsByModuleProjectRole(selectedRolesList)
              .then((forms) => {
                  this.setState({
                      availableForms: forms,
                      isLoadingAvailableForms: false,
                  });
              });
      } else {
          this.setState({ isLoadingAvailableForms: false });
      }
  };

  getNodeFromRole = (role, index) => {
      const roleName = Object.hasOwnProperty.call(role, 'name') ? role.name : role;
      const slots = roleName.split('-');
      return slots.length >= index + 1 ? slots[index] : '*';
  };

  handleClickAddField = () => {
      const { selectedField } = this.state;
      if (selectedField) {
          this.addFieldToCanvas(selectedField);
      }
  };

  handleClickRemoveFromCanvas = (evt) => {
      const fieldName = evt.currentTarget.dataset.id;
      this.removeFieldFromCanvas(fieldName);
  };

  addFieldToCanvas = (fieldName) => {
      const {
          selectedDecoration, selectedDecorationValue, availableFields, form,
      } = this.state;
      let field;
      if (selectedDecoration) {
          field = {};
          field.fieldBody = selectedDecorationValue;
      } else {
          field = availableFields.find((f) => f.name === fieldName);
          field.id = null;
          if (!field) {
              return;
          }
      }

      field.width = field.width ? field.width : FULL_WIDTH;
      field.fieldName = fieldName;

      // add to formFields & remove from available fields
      form.formFields = [...form.formFields, field];

      this.setState({
          form,
          availableFields: availableFields.filter((f) => f.name !== fieldName),
      });
  };

  removeFieldFromCanvas = (fieldName) => {
      const { form, availableFields, roleFields } = this.state;
      const formFieldObj = form.formFields.find((f) => f.fieldName === fieldName);
      if (!formFieldObj) {
          return;
      }

      // add to availble fields & remove from form fields
      form.formFields = form.formFields.filter((f) => f.fieldName !== fieldName);
      const fieldToPutBack = roleFields.find((roleFieldObj) => roleFieldObj.name === formFieldObj.fieldName);
      const updatedFields = fieldToPutBack ? [...availableFields, fieldToPutBack]
          .sort((a, b) => a.name.localeCompare(b.name)) : availableFields;
      this.setState({
          form,
          availableFields: updatedFields,
      });
  };

  handleSelectField = (evt) => {
      const selectedField = evt.target.value;
      this.setState({
          selectedField,
      });
  };

  handleSelectDecoration = (evt) => {
      const index = this.getNextDecorationIndex(evt.target.value);
      const selectedDecoration = `${evt.target.value}[${index}]`;
      this.setState({
          selectedDecoration,
          selectedField: selectedDecoration,
      });
  };

  getNextDecorationIndex = (decoration) => {
      const { form } = this.state;
      // get the last relevant index (following convention [Decoration][000]) and add one to it
      const indexes = [];
      form.formFields.filter((field) => field.fieldName.includes(decoration)).forEach((field) => {
          indexes.push(
              parseInt(field.fieldName.substr(
                  (field.fieldName.length - FIELD_SUFFIX_LENGTH),
                  DECORATION_INDEX_LENGTH
              ), 10)
          );
      });
      const nextIndex = indexes.length ? Math.max(...indexes) + 1 : 0;
      // format to match convention
      return nextIndex.toString().padStart(DECORATION_INDEX_LENGTH, '0');
  };

  renderPointer = (message) => (
      <div className="text-danger">
          <strong>{message}</strong>
      </div>
  );

  // /
  // / renderers
  // /

  renderCreateButton = () => {
      const { selectedLayer } = this.state;
      if (!selectedLayer) {
          return null;
      }

      return (
          <button
              id="formbuilder-create-new-form"
              type="button"
              key="button-create"
              className="btn btn-info"
              onClick={this.handleClickCreateForm}
          >
              Create New Form
          </button>
      );
  };

  renderAvailableForms = () => {
      const {
          isCreateMode,
          selectedModule,
          selectedProject,
          selectedRoles,
          isLoadingAvailableForms,
          selectedLayer,
          availableForms,
      } = this.state;

      let availableFormsList = [...availableForms];

      if (isCreateMode) {
          return null;
      }

      if (!selectedModule) {
          return this.renderPointer('Select a Module');
      }

      if (!selectedProject) {
          return this.renderPointer('Select a Project');
      }

      if (selectedRoles.length === 0) {
          return this.renderPointer('Select a Role');
      }

      if (isLoadingAvailableForms) {
          return <SpinnerControl message="Loading Forms" />;
      }

      if (availableFormsList.length === 0 && selectedRoles.length > 0) {
          return (
              <div>
                  No Forms Found For
                  {' '}
                  {this.getNodeFromRole(selectedRoles[0].name, ROLE_SUFFIX_LENGTH)}
                  :
                  {selectedRoles.map((r) => this.getNodeFromRole(r.name, 1)).join()}
              </div>
          );
      }

      if (selectedLayer) {
          availableFormsList = availableFormsList.filter(
              (form) => selectedLayer.name === form.geoTrakKey.layerName
          );
      }

      let forms = (
          <div className="form-group available-fields" key="available-fields">
              <h4>Available Forms</h4>
              <ul className="list-group">
                  {availableFormsList.map((currentForm) => {
                      const { form, geoTrakKey } = currentForm;
                      return (
                          <div
                              className="formbuilder-available-forms-list"
                              key={form.id}
                          >
                              <a
                                  href={this.doNothingHref}
                                  className="list-group-item list-group-item-action"
                                  data-id={form.id}
                                  onClick={this.handleSelectForm.bind(this)}
                              >
                                  <h4 className="list-group-item-heading">{form.name}</h4>
                                  <p className="list-group-item-text">
                                      <strong>Layer</strong>
                                      :
                                      {geoTrakKey.layerName}
                                      <br />
                                      <strong>Markets</strong>
                                      :
                                      {' '}
                                      {geoTrakKey.roleId}

                                      <br />
                                      <strong>Is Active</strong>
                                      :
                                      {' '}
                                      {String(form.isActive)}
                                  </p>
                              </a>
                          </div>
                      );
                  })}
              </ul>
          </div>
      );

      if (availableFormsList.length === 0) {
          forms = null;
      }

      return forms;
  };

  renderAvailableFields = () => {
      const { availableFields } = this.state;
      return (
          <div className="form-builder-fields">
              <span>Select Field to Add to Canvas</span>
              <div className="input-group">
                  <select
                      className="form-control form-select"
                      id="formbuilder-fields"
                      onChange={this.handleSelectField}
                  >
                      <option key="default" value="">--- choose a field ---</option>
                      {availableFields.map((field) => (
                          <option key={field.name} value={field.name}>{field.name}</option>
                      ))}
                  </select>
                  <button className="btn btn-default" type="button" onClick={this.handleClickAddField}>
                      Add
                  </button>
              </div>
          </div>
      );
  };

  renderAvailableDecorations = () => {
      const { selectedDecoration, selectedDecorationValue } = this.state;
      return (
          <div className="form-builder-fields">
              <span>Select Controls to Add to Canvas</span>
              <div className="input-group mb-3">
                  <select
                      className="form-control form-select"
                      onChange={this.handleSelectDecoration}
                  >
                      <option key="" />
                      <option key="sectionHeader" value="[SectionHeader]">Section Header</option>
                  </select>
              </div>
              {selectedDecoration && (
                  <div className="form-builder-fields">
                      <span>Enter a header</span>
                      <div className="input-group mb-3">
                          <input
                              type="text"
                              className="form-control"
                              value={selectedDecorationValue}
                              onChange={(value) => this.setState({ selectedDecorationValue: value.target.value })}
                          />
                          <button className="btn btn-default" type="button" onClick={this.handleClickAddField}>
                              Add
                          </button>
                      </div>
                  </div>
              )}
          </div>
      );
  };

  renderSelectionControls = () => {
      const {
          selectedModule, projects, selectedLayer, layers, selectedRole, roles,
      } = this.state;
      return (
          <div className="filter-container mb-3">
              <div className="fw-bold form-group mb-3">
                  <label htmlFor="app" className="form-label">
                      Select Module
                  </label>
                  <select
                      className="form-control form-select"
                      onChange={this.handleSelectModule}
                      value={selectedModule}
                  >
                      {this.modules.map((mod) => (
                          <option key={mod}>{mod}</option>
                      ))}
                  </select>
              </div>
              <div className="fw-bold form-group mb-3">
                  <label htmlFor="app" className="form-label">
                      Select Project
                  </label>
                  <select className="form-control form-select" onChange={this.handleSelectProject}>
                      <option />
                      {projects.map((project) => (
                          <option key={project}>{project}</option>
                      ))}
                  </select>
              </div>
              <div className="fw-bold form-group mb-3">
                  <label htmlFor="app" className="form-label">
                      <div>Select Layer</div>
                      {!selectedRole ? (
                          <strong className="text-danger">Select a role to populate layers.</strong>
                      ) : (
                          ''
                      )}
                  </label>
                  <select
                      className="form-control form-select"
                      value={selectedLayer ? selectedLayer.name : ''}
                      onChange={this.handleSelectLayer}
                  >
                      <option />
                      {layers.map((layer) => (
                          <option key={layer.name}>{layer.name}</option>
                      ))}
                  </select>
              </div>
              <div className="formbuilder-select-roles overflow-y-auto form-group">
                  <span className="fw-bold">Select Role(s)</span>
                  {roles.map((role) => (
                      <div className={this.isRoleVisible(role.name)} key={role.name}>
                          <label className="form-check-label mb-0" htmlFor={role.name}>
                              <input
                                  id={role.name}
                                  type="checkbox"
                                  className="form-check-input"
                                  value={role.name}
                                  checked={this.isRoleChecked(role.name)}
                                  onChange={this.handleSelectRole}
                              />
                              {role.name}
                          </label>
                      </div>
                  ))}
              </div>
          </div>
      );
  };

  isRoleChecked = (roleId) => {
      const { selectedRoles } = this.state;
      const role = selectedRoles.filter((k) => k === roleId || k.name === roleId);
      return role.length > 0;
  };

  isRoleVisible = (roleId) => {
      const { selectedRoles, roleFilter } = this.state;
      const formClass = 'form-check';
      if (selectedRoles.length === 0) {
          return formClass;
      }

      const thisRole = roleId.substring(roleId.length - ROLE_SUFFIX_LENGTH);

      if (roleFilter === thisRole) {
          return formClass;
      }

      if (roleFilter === 'none' && !thisRole.startsWith('-')) {
          return formClass;
      }

      return 'd-none';
  };

  toggleSelectedRole = (evt) => {
      evt.preventDefault();
      const { availableRoles, form } = this.state;
      const formCopy = JSON.parse(JSON.stringify(form));
      const selectedRoleName = evt.target.dataset.rolename.includes('-*') ? evt.target.dataset.rolename.slice(0, -ROLE_SUFFIX_LENGTH) : evt.target.dataset.rolename;
      const updatedAvailableRoles = availableRoles.map((ar) => {
          const availableRole = JSON.parse(JSON.stringify(ar));
          const currentAvailableRoleName = availableRole.role.name.includes('-*') ? availableRole.role.name.slice(0, -ROLE_SUFFIX_LENGTH) : availableRole.role.name;
          if (currentAvailableRoleName === selectedRoleName) {
              availableRole.selected = !availableRole.selected; // reset toggle select property
          }
          return availableRole;
      });

      this.addRoleToForm(formCopy, updatedAvailableRoles, selectedRoleName);
      this.removeRoleFromForm(formCopy, updatedAvailableRoles, selectedRoleName);
      this.setState({
          form: formCopy,
          availableRoles: updatedAvailableRoles,
      });
  };

  addRoleToForm = (form, roles, selectedRoleName) => {
      roles.forEach((updatedRole) => {
          if (updatedRole.selected && updatedRole.role.name.includes(selectedRoleName)) {
              form.geoTrakKeys.push(this.convertRoleToGeoTrakKey(updatedRole.role));
          }
      });
  };

  removeRoleFromForm = (form, roles, selectedRoleName) => {
      roles.forEach((updatedRole) => {
          if (!updatedRole.selected && updatedRole.role.name.includes(selectedRoleName)) {
              form.geoTrakKeys.forEach((gtKey, index) => {
                  if (gtKey.roleId.includes(selectedRoleName)) {
                      form.geoTrakKeys.splice(index, 1);
                  }
              });
          }
      });
  };

  handleToggleIsActive = () => {
      const { form } = this.state;
      const formCopy = { ...form };
      formCopy.isActive = this.formRefs.isActive.current.checked;
      this.setState(
          { form: formCopy }
      );
  };

  renderCanvas() {
      const {
          form, selectedModule, selectedProject, selectedRoles, availableRoles,
          selectedLayer, error, isLoadingAvailableForms,
      } = this.state;

      return (
          <form>
              <div className="form-group form-builder-fields">
                  <label className="form-label" htmlFor="new-form-name">
                      <span>Enter Form Name</span>
                  </label>
                  <input
                      id="new-form-name"
                      name="newFormName"
                      type="text"
                      className="form-control"
                      ref={this.formRefs.name}
                      defaultValue={form.name}
                  />
              </div>
              <div className="form-check flush">
                  <label className="form-check-label" htmlFor="is-form-active">
                      <input
                          id="is-form-active"
                          name="activateForm"
                          type="checkbox"
                          className="form-check-input"
                          ref={this.formRefs.isActive}
                          onChange={this.handleToggleIsActive}
                          checked={form.isActive}
                      />
                      Is Active
                  </label>
              </div>
              <div className="row formbuilder-module-infos">
                  <div className="col-2">
                      <div className="form-group">
                          <label className="form-label" htmlFor="module">
                              Module
                              <input
                                  type="text"
                                  className="form-control"
                                  readOnly
                                  id="module"
                                  name="module"
                                  defaultValue={selectedModule}
                                  aria-describedby="helpblock-module"
                              />
                              <div id="helpblock-module" className="help-block" />
                          </label>
                      </div>
                  </div>
                  <div className="col-2">
                      <div className="form-group">
                          <label className="form-label" htmlFor="project">
                              Project
                              <input
                                  type="text"
                                  className="form-control"
                                  readOnly
                                  id="project"
                                  name="project"
                                  defaultValue={selectedProject}
                                  aria-describedby="helpblock-project"
                              />
                              <div id="helpblock-project" className="help-block" />
                          </label>
                      </div>
                  </div>
                  <div className="col-2">
                      <div className="form-group">
                          <label className="form-label" htmlFor="roleType">
                              Role Type
                              <input
                                  type="text"
                                  className="form-control"
                                  readOnly
                                  id="roleType"
                                  name="roleType"
                                  defaultValue={this.getNodeFromRole(selectedRoles[0], ROLE_SUFFIX_LENGTH)}
                                  aria-describedby="helpblock-roleType"
                              />
                              <div id="helpblock-roleType" className="help-block" />
                          </label>
                      </div>
                  </div>
                  <div className="col-5">
                      <div className="form-group selected-roles-container">
                          <label className="form-label" htmlFor="roleType">
                              Markets
                              <div>
                                  {availableRoles.map((availableRole) => (
                                      <button
                                          id="formbuilder-toggle-select-role"
                                          type="button"
                                          data-rolename={availableRole.role.name}
                                          onClick={this.toggleSelectedRole}
                                          className={`btn btn-default selected-${availableRole.selected}`}
                                          key={availableRole.role.name}
                                          disabled={availableRole.selected && form.geoTrakKeys.length === 1}
                                      >
                                          {this.getNodeFromRole(availableRole.role.name, 1)}
                                      </button>
                                  ))}
                              </div>
                              <div id="helpblock-roleType" className="help-block" />
                          </label>
                      </div>
                  </div>
              </div>

              <div className="form-group form form-builder-fields">
                  <label className="form-label" htmlFor="layerName">
                      <span>Field Layer</span>
                  </label>
                  <input
                      type="text"
                      className="form-control"
                      readOnly
                      id="layerName"
                      name="layerName"
                      defaultValue={selectedLayer.name}
                      aria-describedby="helpblock-layerName"
                  />
                  <div id="helpblock-layerName" className="help-block" />
              </div>
              {this.renderAvailableFields()}
              {this.renderAvailableDecorations()}
              <div className="move-field-container">
                  <MdArrowBack onClick={this.handleMoveLeft} />
                  <span className="move-label">Move Field</span>
                  <MdArrowForward onClick={this.handleMoveRight} />
              </div>
              <span id="formbuilder-form-canvas-label">Form Canvas</span>
              <div className="canvas">
                  <div className="row">
                      {form.formFields.map((field) => (
                          <div className={`col-${field.width} canvas-field-container`} key={field.fieldName}>
                              <div className={`container selected-${field.selected}`}>
                                  {!field.fieldBody
                  && (
                      <WidthControl
                          data-fieldname={field.fieldName}
                          data-field={field}
                          onClick={this.handleClickWidth}
                      />
                  )}
                                  <span
                                      className="float-end"
                                      aria-hidden="true"
                                      data-id={field.fieldName}
                                      onClick={this.handleClickRemoveFromCanvas}
                                  >
                                      <MdCancel />
                                  </span>
                                  <span onClick={this.handleClickField} role="button" tabIndex={0} onKeyDown={this.handleClickField} data-fieldname={field.fieldName}>
                                      {field.fieldBody ? `${field.fieldName} - ${field.fieldBody}` : `${field.fieldName}`}
                                  </span>
                              </div>
                          </div>
                      ))}
                  </div>
              </div>
              {isLoadingAvailableForms && <SpinnerControl message="Saving Form..." />}
              {error && <div className="text-danger">{error}</div>}
          </form>
      );
  }

  render() {
      const {
          isCreateMode, form, showConfirmation,
      } = this.state;
      if (isCreateMode) {
          return (
              <>
                  <Modal
                      showModal
                      header="Form Editor"
                      onCancel={this.handleClickCancel}
                      component={(
                          <div id="formbuilder-editor-content" className="container formbuilder-container">
                              <div className="row">
                                  <div className="col-xs-12">
                                      <Modal
                                          showModal={showConfirmation}
                                          header="Wait!"
                                          onCancel={() => { this.setState({ showConfirmation: !showConfirmation }); }}
                                          component={
                                              <h5 className="text-center">Are you sure you want to delete this form?</h5>
                                          }
                                          footer={
                                              <ButtonDanger onClick={this.handleDeleteForm}>Delete</ButtonDanger>
                                          }
                                          displayFullScreen={false}
                                      />
                                      {this.renderCanvas()}
                                  </div>
                              </div>
                          </div>
                      )}
                      footer={form.name ? (
                          <ButtonContainer>
                              <ButtonDanger onClick={() => { this.setState({ showConfirmation: !showConfirmation }); }}>
                                  Delete
                              </ButtonDanger>
                              <ButtonPrimary id="btn-formbuilder-finish" onClick={this.handleClickFinish} disabled={!form.formFields.length}>
                                  Save and Finish
                              </ButtonPrimary>
                              <ButtonPrimary id="btn-formbuilder-createForm" onClick={this.handleClickUpdate} disabled={!form.formFields.length}>
                                  Save
                              </ButtonPrimary>
                          </ButtonContainer>
                      ) : (
                          <ButtonContainer>
                              <ButtonPrimary id="btn-formbuilder-createForm" onClick={this.handleClickCreate} disabled={!form.formFields.length}>
                                  Create Form
                              </ButtonPrimary>
                          </ButtonContainer>
                      )}
                  />
              </>
          );
      }
      return (
          <div className="formbuilder-container row">
              <div className="row col-md-3">
                  <div className="container ">
                      <h4>Filter By</h4>
                      <hr />
                      {this.renderSelectionControls()}
                  </div>
              </div>

              <div className="col-md-8">
                  {this.renderCreateButton()}
                  <h4>Form Builder</h4>
                  <hr />
                  <div>
                      <div className="mb-3" id="formbuilder-editor-instructions">
                          <strong className="text-secondary">
                              To view existing forms, selected a module, project and at least one role from the
                              filters, from the selections on the left. To create a form you will need to also
                              select a layer.
                          </strong>
                      </div>
                      {this.renderAvailableForms()}
                  </div>
              </div>
          </div>
      );
  }
}

export default FormBuilder;
