import deliverToFields from '../../../CONSTANTS/deliverToFields';

const deliverToTypeExists = (requestForm) => Object.keys(requestForm).includes('DeliverToType');

const isCompanyField = (field) => field.Name === 'DeliverToCompany';

const isDeliveryField = (field) => deliverToFields.includes(field.Name);

const isNotAutopopulatedField = (isDeliveryAutopopulated, field) => (
    isDeliveryAutopopulated && !deliverToFields.includes(field.Name)
);

const isDeliveryManuallyPopulated = (requestForm) => (
    requestForm.DeliverToType && !requestForm.DeliverToAddress
);

export const isFieldRequired = (requestForm, field) => {
    if (isDeliveryField(field)) {
        if (isCompanyField(field) && !deliverToTypeExists(requestForm)) {
            return field.IsRequired;
        }
        return true;
    }
    return field.IsRequired;
};

export const isFieldVisible = (isDeliveryAutopopulated, requestForm, field) => {
    if (isNotAutopopulatedField(isDeliveryAutopopulated, field)) {
        return true;
    }
    if (!isDeliveryAutopopulated && deliverToTypeExists(requestForm) && isDeliveryField(field)) {
        return isDeliveryManuallyPopulated(requestForm);
    }
    if (!isDeliveryAutopopulated && (!isDeliveryField(field) || field.IsRequired)) {
        return true;
    }
    if (isCompanyField(field) && !deliverToTypeExists(requestForm)) {
        return true;
    }
    return false;
};
