import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import { Role } from '../../../../_services/GeotrakService/Models';
import useDeleteRole from '../../../../_services/GeotrakService/hooks/useDeleteRole';
import '../roles.css';
import { ButtonDanger } from '../../../../utilComponents/styledComponents';
import Modal from '../../../../components/Modals/Modal';

const deleteButton = ({ role = new Role(), moduleId = null }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const {
        deleteRole, error, isLoading, isSuccessful,
    } = useDeleteRole();

    if (!role.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/modules/${moduleId}/roles`} />;
    }

    if (showConfirmation) {
        return (
            <>
                <Modal
                    showModal
                    header="Wait!"
                    onCancel={() => setShowConfirmation(false)}
                    component={(
                        <h5 className="text-center">
                            Are you sure you want to delete this role?  This action is permanent and will remove
                            all associated data sources, views, fields, renderers, and more.
                        </h5>
                    )}
                    footer={(
                        <LoaderButton
                            danger
                            text="Delete"
                            id="confirm-delete-role-button"
                            loadingText="Deleting..."
                            isLoading={isLoading}
                            onClick={() => deleteRole(role)}
                        />
                    )}
                    displayFullScreen={false}
                />
                {error && <div className="text-danger role-error" id="delete-role-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            </>
        );
    }

    return (
        <div className="role-toolbar role-button">
            <ButtonDanger
                id="delete-role-button"
                onClick={() => setShowConfirmation(true)}
            >
                Delete
            </ButtonDanger>
        </div>
    );
};

export default deleteButton;

deleteButton.propTypes = {
    role: PropTypes.instanceOf(Role),
    moduleId: PropTypes.string,
};
