import React from 'react';
import propTypes from 'prop-types';
import '../stylesheets/ButtonDanger.css';

const buttonDanger = ({
    id = '',
    children = null,
    onClick = () => null,
    disabled = false,
}) => (
    <button id={id} className="btn btn-danger danger-button" type="button" onClick={onClick} disabled={disabled}>
        {children}
    </button>
);

export default buttonDanger;

buttonDanger.propTypes = {
    id: propTypes.string,
    children: propTypes.oneOfType([propTypes.instanceOf(Object), propTypes.string]),
    onClick: propTypes.func,
    disabled: propTypes.bool,
};
