import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import { NotificationField } from '../../../../_services/NotificationService/Models';
import useDeleteNotificationField from '../../../../_services/NotificationService/hooks/field/useDeleteNotificationField';
import '../notifications.css';

const deleteFieldButton = ({ notificationField = new NotificationField() }) => {
    const {
        deleteNotificationField, error, isLoading, isSuccessful,
    } = useDeleteNotificationField();

    if (!notificationField.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/notifications/${notificationField.notificationId}/fields`} />;
    }

    return (
        <div className="notification-toolbar notification-button">
            {error && <div className="text-danger notification-error" id="delete-notification-field-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                danger
                id="delete-notification-field-button"
                text="Delete"
                loadingText="Deleting..."
                isLoading={isLoading}
                onClick={() => deleteNotificationField(notificationField)}
            />
        </div>
    );
};

export default deleteFieldButton;

deleteFieldButton.propTypes = {
    notificationField: PropTypes.instanceOf(NotificationField),
};
