import React from 'react';
import PropTypes from 'prop-types';
import LabelControl from './LabelControl';

const selectControl = function statelessFunctionComponentClass(props) {
    const style = {
        display: 'inline-block',
        marginBottom: '0',
    };

    const columnWidth = props.formField.width ? `col-sm-${props.formField.width}` : 'col-sm-12';
    const helpBlock = props.feedback ? 'has-error' : '';

    return (
        <div className={`form-group ${helpBlock} ${columnWidth}`}>
            <LabelControl htmlFor={props.formField.fieldName} style={style}>
                {props.displayName}
            </LabelControl>

            <select
                id={props.formField.fieldName}
                name={props.formField.fieldName}
                disabled={props.metaData.isReadOnly}
                className="form-control"
                defaultValue={props.value}
                aria-describedby={`helpblock${props.formField.fieldName}`}
                required={props.formField.isRequired}
            >
                <option value="" />
                {props.metaData.lookupValues.map((luv) => (
                    <option key={luv} value={luv}>
                        {luv}
                    </option>
                ))}
            </select>
            <div id={`helpblock${props.formField.fieldName}`} className="help-block">
                {props.feedback}
            </div>
        </div>
    );
};

export default selectControl;

selectControl.propTypes = {
    formField: PropTypes.shape({
        fieldBody: PropTypes.string,
        fieldName: PropTypes.string.isRequired,
    }).isRequired,
    metaData: PropTypes.shape({
        isReadOnly: PropTypes.bool,
    }),
    displayName: PropTypes.string,
    value: PropTypes.string,
    feedback: PropTypes.string,
};
