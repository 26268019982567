import React from 'react';
import PropTypes from 'prop-types';

const adminContainer = ({ title = '', children = null }) => (
    <div id="admin-container" className="container">
        <h1 className="text-center mt-3 mb-3">{title}</h1>
        {children}
    </div>
);

export default adminContainer;

adminContainer.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
};
