import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import Widget from './Widget';
import Search from '../components/Search';
import RecordCreator from '../components/RecordCreator/RecordCreator';
import ResultsTable from '../components/ResultsTable';
import ViewsListPanel from '../components/ViewsList/ViewsListPanel';
import Pager from '../utilComponents/Pager';
import Toolbar from '../utilComponents/Toolbar';
import RecordCount from '../utilComponents/RecordCount';
import Loading from '../utilComponents/LoaderSpinner';
import { resetFeature } from '../actions/featureActions';
import { resetActiveViewThunk, setActiveView } from '../actions/viewActions';
import { closeWidgets } from '../actions/widgetActions';
import { resetQuery } from '../actions/queryActions';
import { HistoryType } from '../types';
import { DataSourceService } from '../_services/GeotrakService';
import IGetFavoriteDataSourceRequest from '../_services/GeotrakService/interfaces/IGetFavoriteDataSourceRequest';
import { addAlertThunk } from '../actions/errorActions';
import DataSourceBookmark from '../components/DataGrid/DataSourceBookmark';
import { FavoriteDataSource } from '../_services/GeotrakService/Models';
import './stylesheets/DataView.css';

const INNER_WIDTH_OFFSET = 0.95;
const INNER_WIDTH_SHIFT = 300;

class DataViewComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            dataSources: [],
            favoriteDataSource: new FavoriteDataSource(),
            resultsTableWidth: window.innerWidth * INNER_WIDTH_OFFSET - INNER_WIDTH_SHIFT,
            page: 1,
            recordCount: 0,
        };

        this.dataSourceService = new DataSourceService();
    }

  componentDidMount = async () => {
      const {
          closeWidgetsAction,
          resetFeatureAction,
          resetActiveViewAction,
      } = this.props;

      const dataSources = await this.getDataSources();
      const validDataSources = dataSources.filter((dataSource) => !dataSource.isHiddenOnData);
      const searchParameters = new URLSearchParams(window.location.search);
      closeWidgetsAction();
      resetFeatureAction();
      const dataSourceIndex = validDataSources.findIndex((dataSource) => dataSource.id === searchParameters.get('data-source'));
      resetActiveViewAction(dataSources[dataSourceIndex]);
      this.setState({
          defaultDataSourceIndex: dataSourceIndex,
          isLoading: false,
          dataSources: validDataSources,
      });

      this.getFavoriteDataSource();
  };

  componentWillUnmount = () => {
      const { closeWidgetsAction, setActiveViewAction } = this.props;
      closeWidgetsAction();
      setActiveViewAction('All');
  };

  getDataSources = () => {
      const { addAlertAction, match: { params: { moduleRoleId } } } = this.props;
      return this.dataSourceService.getDataSources(moduleRoleId).then((sources) => sources).catch((err) => {
          addAlertAction('danger', 'Error Getting Favorite Layer', err || err.message);
          return [];
      });
  }

  getFavoriteDataSource = () => {
      const {
          addAlertAction, match: { params: { moduleRoleId } },
      } = this.props;
      const favoriteRequest = IGetFavoriteDataSourceRequest.load(moduleRoleId);
      this.dataSourceService.getFavoriteDataSource(favoriteRequest).then((result) => {
          if (result) {
              this.setState({ favoriteDataSource: result });
          }
      }).catch((err) => {
          addAlertAction('danger', 'Error Getting Favorite Layer', err || err.message);
      });
  }

  onResize = (width) => {
      const resultsTableWidth = window.innerWidth * INNER_WIDTH_OFFSET - width;
      this.setState({ resultsTableWidth });
  };

  onDataSourceChange = (evt) => {
      evt.stopPropagation();
      const { tabIndex } = evt.target.dataset;
      const {
          resetActiveViewAction, resetFeatureAction,
          history, resetQueryAction,
      } = this.props;
      const { location } = history;
      const { dataSources } = this.state;

      this.setState({ defaultDataSourceIndex: tabIndex });
      const source = dataSources[tabIndex];
      this.setState({ page: 1 });
      history.push(`${location.pathname}?data-source=${source.id}`);
      resetActiveViewAction(source);
      resetFeatureAction();
      resetQueryAction();
  };

  onFavoriteSelected = (favoriteDataSource) => {
      this.setState({ favoriteDataSource });
  }

  setTabActiveView = (currentTabKey) => {
      const { defaultDataSourceIndex } = this.state;
      if (Number(currentTabKey) === Number(defaultDataSourceIndex)) {
          return 'active';
      }
      return '';
  };

  renderDataSourcesList = () => {
      const { match: { params: { moduleRoleId } } } = this.props;
      const { favoriteDataSource, dataSources } = this.state;
      return dataSources.reduce((accumulator, dataSource, index) => {
          accumulator.push(
              <li className="nav-item" id={`data-tab-${index}`} key={uuidv4()} role="presentation">
                  <button
                      id={`datasource-tab-${index}`}
                      role="tab"
                      type="button"
                      className={`text-center nav-link data-source-tab ${this.setTabActiveView(index)}`}
                      aria-controls={`datasource-tab-${index}`}
                      tabIndex={!this.setTabActiveView(index) ? -1 : 0}
                      data-tab-index={index}
                      aria-selected={!this.setTabActiveView(index) ? 'false' : 'true'}
                      onClick={this.onDataSourceChange}
                      onKeyDown={this.onDataSourceChange}
                  >
                      <DataSourceBookmark
                          favoriteDataSource={favoriteDataSource}
                          dataSource={dataSource}
                          moduleRoleId={moduleRoleId}
                          onFavoriteSelected={this.onFavoriteSelected}
                      />
                      {dataSource.alias}
                  </button>
              </li>
          );
          return accumulator;
      }, []);
  };

  getDefaultActiveKey = () => {
      const { dataSources } = this.state;
      const searchParameters = new URLSearchParams(window.location.search);
      return dataSources.findIndex((source) => source.id === searchParameters.get('data-source'));
  };

  onPageChange = (page) => {
      this.setState({ page });
  }

  render() {
      const {
          isLoading,
          dataSources,
          defaultDataSourceIndex,
          resultsTableWidth,
          page,
          recordCount,
      } = this.state;
      const {
          match,
          history,
      } = this.props;
      if (isLoading) {
          return <Loading />;
      }
      const defaultActiveKey = this.getDefaultActiveKey();

      return (
          <div className="data-view-buffer">
              <div className="data-view-pane">
                  <ViewsListPanel onResize={this.onResize} onPageChange={this.onPageChange} />
                  <div>
                      <ul
                          role="tablist"
                          activekey={defaultDataSourceIndex}
                          className="data-source-tab-container nav nav-tabs"
                      >
                          {this.renderDataSourcesList()}
                      </ul>
                      <RecordCreator
                          onPageChange={this.onPageChange}
                          recordCount={recordCount}
                      />
                      <Search match={match} dataSource={dataSources[defaultActiveKey]} />
                      <RecordCount totalRecords={recordCount} />
                      <ResultsTable
                          width={resultsTableWidth}
                          page={page}
                          match={match}
                          history={history}
                          dataSource={dataSources[defaultActiveKey]}
                          onRecordCountReceived={(e) => this.setState({ recordCount: e })}
                      />
                  </div>
              </div>
              <Pager
                  page={page}
                  onPageChange={this.onPageChange}
                  recordCount={recordCount}
              />
              <Toolbar match={match} />
              <Widget match={match} dataSource={dataSources[defaultActiveKey]} recordCount={recordCount} />
          </div>
      );
  }
}

const mapDispatchToProps = {
    resetActiveViewAction: resetActiveViewThunk,
    setActiveViewAction: setActiveView,
    resetFeatureAction: resetFeature,
    closeWidgetsAction: closeWidgets,
    resetQueryAction: resetQuery,
    addAlertAction: addAlertThunk,
};

export default connect(null, mapDispatchToProps)(DataViewComponent);

DataViewComponent.propTypes = {
    closeWidgetsAction: PropTypes.func.isRequired,
    resetActiveViewAction: PropTypes.func.isRequired,
    setActiveViewAction: PropTypes.func.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string,
        url: PropTypes.string,
        params: PropTypes.instanceOf(Object),
    }).isRequired,
    history: HistoryType.isRequired,
    resetFeatureAction: PropTypes.func.isRequired,
    resetQueryAction: PropTypes.func.isRequired,
    addAlertAction: PropTypes.func,
};

DataViewComponent.defaultProps = {
    addAlertAction: () => null,
};
