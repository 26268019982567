import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import '../notifications.css';
import { NotificationUser } from '../../../../_services/NotificationService/Models';
import useCreateNotificationUser from '../../../../_services/NotificationService/hooks/user/useCreateNotificationUser';

const createUserButton = ({ notificationUser = new NotificationUser(), isValid = () => null }) => {
    const {
        createNotificationUser, error, isLoading, isSuccessful,
    } = useCreateNotificationUser();

    if (notificationUser.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/notifications/${notificationUser.notificationId}/users`} />;
    }

    return (
        <div className="notification-toolbar notification-button">
            {error && <div className="text-danger notification-error" id="create-notification-user-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="create-notification-user-button"
                text="Create"
                loadingText="Creating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => createNotificationUser(notificationUser)}
            />
        </div>
    );
};

export default createUserButton;

createUserButton.propTypes = {
    isValid: PropTypes.func,
    notificationUser: PropTypes.instanceOf(NotificationUser),
};
