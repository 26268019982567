import React from 'react';
import PropTypes from 'prop-types';

const logoutLink = ({ onLogout = () => null }) => (
    <li className="dropdown-item d-grid">
        <button
            type="button"
            className="btn btn-primary"
            onClick={(evt) => {
                evt.stopPropagation();
                onLogout();
            }}
        >
            Logout
        </button>
    </li>
);

export default logoutLink;

logoutLink.propTypes = {
    onLogout: PropTypes.func,
};
