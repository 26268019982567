import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import IUpdateUserViewFieldsRequest from '../../../_services/GeotrakService/interfaces/IUpdateUserViewFieldsRequest';
import { ViewService } from '../../../_services/GeotrakService';
import { saveView } from '../../../actions/viewActions';
import LoaderButton from '../../../utilComponents/LoaderButton';
import { DataSource, View } from '../../../_services/GeotrakService/Models';
import { addAlertThunk } from '../../../actions/errorActions';

const updateButton = ({
    disabled = true,
    view = new View(),
    closeDrawer = () => null,
    dataSource = new DataSource(),
}) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const viewService = new ViewService();
    const dispatch = useDispatch();

    const updateView = () => {
        setIsUpdating(true);
        view.stripDataSourceQuery(dataSource);
        const request = IUpdateUserViewFieldsRequest.load(view, dataSource.id);
        viewService.updateUserViewFields(request).then((response) => {
            dispatch(saveView(response, dataSource));
            setIsUpdating(false);
            closeDrawer();
        }).catch(() => {
            setIsUpdating(false);
            dispatch(addAlertThunk('danger', 'Error Updating View', 'Make sure your view names and default statuses are unique'));
        });
    };

    return (
        <LoaderButton
            isLoading={isUpdating}
            onClick={updateView}
            disabled={disabled}
            text="Save Changes"
            loadingText="Saving..."
        />
    );
};

export default updateButton;

updateButton.propTypes = {
    disabled: PropTypes.bool,
    view: PropTypes.instanceOf(View),
    closeDrawer: PropTypes.func,
    dataSource: PropTypes.instanceOf(DataSource),
};
