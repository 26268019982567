import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoaderButton from '../../../utilComponents/LoaderButton';
import {
    ButtonContainer, ButtonPrimary
} from '../../../utilComponents/styledComponents';
import { IProjectRecordRequest, ProjectRecordService, ViewService } from '../../../_services/GeotrakService';
import IUpdateViewDetailsRequest from '../../../_services/GeotrakService/interfaces/IUpdateViewDetailsRequest';
import IDeleteSystemViewRequest from '../../../_services/GeotrakService/interfaces/IDeleteSystemViewRequest';
import ViewBuilderSummary from './ViewBuilderSummary';
import { View } from '../../../_services/GeotrakService/Models';

const viewDetails = ({
    nextStep = () => null,
    previousStep = () => null,
    jobProperties = {
        view: {},
        views: [],
        application: '',
        role: '',
        dataSource: '',
        isRootView: () => null,
    },
}) => {
    const {
        view, views, application, role, dataSource, isRootView, dataSources,
    } = jobProperties;
    const viewService = new ViewService();
    const projectRecordService = new ProjectRecordService();
    const isExistingView = jobProperties.view.name !== null;
    const [viewName, setViewName] = useState(jobProperties.view.name);
    const [isNameValid, setIsNameValid] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [viewQuery, setViewQuery] = useState(jobProperties.view.query);
    const [error, setError] = useState(false);

    const updateView = async () => {
        try {
            const selectedDataSource = dataSources.find((source) => source.name === dataSource);
            setIsSaving(true);
            const request = IUpdateViewDetailsRequest.load(view.name, viewName, viewQuery, selectedDataSource.id);
            await viewService.updateViewDetails(request);
            view.name = viewName;
            view.setName(viewName);
            view.setQuery(viewQuery);
            setIsSaving(false);
            nextStep({ view });
        } catch (err) {
            setIsSaving(false);
            setError(`Error Updating View Details. ${err.message || err}`);
        }
    };

    const createView = () => {
        const newView = View.create(viewName, viewQuery, false);
        nextStep({ view: newView });
    };

    const deleteView = () => {
        setIsDeleting(true);
        const selectedDataSource = dataSources.find((source) => source.name === dataSource);
        const request = IDeleteSystemViewRequest.load(selectedDataSource.id, view);
        viewService.deleteSystemView(request).then(() => {
            setIsDeleting(false);
            previousStep();
        }).catch((err) => {
            setIsDeleting(false);
            setError(`Error Deleting View. ${err.message || err}`);
        });
    };

    const validateQuery = () => {
        const selectedDataSource = dataSources.find((source) => source.name === dataSource);
        const request = IProjectRecordRequest.load(selectedDataSource.id, viewQuery, 'All');
        projectRecordService.getProjectRecords(1, request).then(() => {
            if (isExistingView) {
                updateView();
            } else {
                createView();
            }
        }).catch(() => {
            setError('Error Updating View. Make sure your query is valid.');
        });
    };

    const isValidUpdate = () => isNameValid && viewName !== '';

    const isValidDelete = () => viewName === view.name && viewName;

    const onNameChange = (e) => {
        const viewMatch = views.filter((item) => item.name.toLowerCase() === e.toLowerCase());
        setViewName(e);
        if (viewMatch.length < 1 || (view.name && e.toLowerCase() === view.name.toLowerCase())) {
            setIsNameValid(true);
        } else {
            setIsNameValid(false);
        }
    };

    return (
        <div className="col-md-8 offset-md-2 text-center">
            <h2 className="Admin">View Details</h2>
            <ViewBuilderSummary application={application} role={role} dataSource={dataSource} view={view.name} />
            <div className="mb-3">
                <label htmlFor="name" className="form-label">
                    Name
                </label>
                <input
                    id="name"
                    value={viewName}
                    disabled={isRootView(viewName)}
                    onChange={(e) => onNameChange(e.target.value)}
                />
            </div>
            {!isNameValid && <span className="text-danger">A view with this name already exists.</span>}
            <div className="mb-3">
                <label htmlFor="query" className="form-label">
                    Query
                </label>
                <input
                    id="query"
                    value={viewQuery || ''}
                    onChange={(e) => setViewQuery(e.target.value)}
                />
            </div>
            {(!isRootView(viewName) && isValidDelete()) && (
                <ButtonContainer>
                    <LoaderButton
                        danger
                        text="Delete View"
                        loadingText="Deleting..."
                        onClick={deleteView}
                        isLoading={isDeleting}
                        disabled={!isValidDelete()}
                    />
                </ButtonContainer>
            )}
            <ButtonContainer
                error={error}
                onErrorDismiss={() => setError(null)}
            >
                <LoaderButton
                    text="Next"
                    loadingText="Saving..."
                    onClick={validateQuery}
                    disabled={!isValidUpdate()}
                    isLoading={isSaving}
                />
                <ButtonPrimary onClick={previousStep}>
                    Back
                </ButtonPrimary>
            </ButtonContainer>
        </div>
    );
};

export default viewDetails;

viewDetails.propTypes = {
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    jobProperties: PropTypes.instanceOf(Object),
};
