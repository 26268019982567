import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import useCreateDocumentType from '../../../../_services/GeotrakService/hooks/useCreateDocumentType';
import { DocumentType } from '../../../../_services/GeotrakService/Models';
import '../documentTypes.css';

const createButton = ({ documentType = new DocumentType(), isValid = () => null }) => {
    const {
        createDocumentType, error, isLoading, isSuccessful,
    } = useCreateDocumentType();

    if (documentType.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/document-types" />;
    }

    return (
        <div className="document-type-toolbar document-type-button">
            {error && <div className="text-danger document-type-error" id="create-document-type-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="create-document-type-button"
                text="Create"
                loadingText="Creating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => createDocumentType(documentType)}
            />
        </div>
    );
};

export default createButton;

createButton.propTypes = {
    documentType: PropTypes.instanceOf(DocumentType),
    isValid: PropTypes.func,
};
