import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Menu } from 'react-data-grid-addons';
import CoordinatePair from '../../../helpers/spatialUtils/CoordinatePair';
import { DataSource } from '../../../_services/GeotrakService/Models';
import { ProjectRecordService } from '../../../_services/GeotrakService';
import IGetSpatialRecordsRequest from '../../../_services/GeotrakService/interfaces/IGetSpatialRecordsRequest';

const { MenuItem } = Menu;

const viewMap = ({ row = null, dataSource = new DataSource() }) => {
    const history = useHistory();
    const projectRecordService = new ProjectRecordService();

    const onViewMap = async () => {
        const {
            uniqueIdField, id,
        } = dataSource;
        const uniqueId = row[uniqueIdField];
        const query = `${uniqueIdField} = ${uniqueId}`;
        const request = IGetSpatialRecordsRequest.load(dataSource, query);
        const selectedPoint = await projectRecordService.getSpatialRecords(request);
        const newLocation = new CoordinatePair(selectedPoint[0].Latitude, selectedPoint[0].Longitude);
        const path = history.location.pathname.replace('data', 'map');
        history.push(`${path}?data-source=${id}&latitude=${newLocation.latitude}&longitude=${newLocation.longitude}`);
    };

    return (
        <>
            {!dataSource.serviceType && dataSource.dataType !== 'table' && (
                <MenuItem id="map-view" onClick={onViewMap}>
                    View on Map
                </MenuItem>
            )}
        </>
    );
};

viewMap.propTypes = {
    row: PropTypes.instanceOf(Object),
    dataSource: PropTypes.instanceOf(DataSource),
};

export default viewMap;
