import React from 'react';
import PropTypes from 'prop-types';
import LabelControl from './LabelControl';

const textBox = function statelessFunctionComponentClass({
    formField = {
        isRequired: false,
        regExPattern: '',
        fieldName: '',
        width: 0,
    },
    metaData = {
        isReadOnly: false,
        dataType: '',
    },
    displayName = '',
    feedback = '',
    value = '',
}) {
    const style = {
        display: 'inline-block',
        marginBottom: '0',
    };
    const {
        width, fieldName, isRequired, regExPattern,
    } = formField;
    const { isReadOnly, dataType } = metaData;
    const columnWidth = width ? `col-sm-${width}` : 'col-sm-12';
    const helpBlock = feedback ? 'has-error' : '';
    const INPUT_MAX_LENGTH = 25;

    return (
        <div className={`form-group ${helpBlock} ${columnWidth}`}>
            <LabelControl className="form-label" htmlFor={fieldName} style={style}>
                {displayName}
            </LabelControl>
            <input
                type="text"
                className="form-control"
                disabled={isReadOnly}
                id={fieldName}
                name={fieldName}
                placeholder={displayName}
                defaultValue={value}
                required={isRequired}
                pattern={regExPattern}
                aria-describedby={`helpblock${fieldName}`}
                maxLength={dataType.length == null ? INPUT_MAX_LENGTH : dataType.length}
            />
            <div id={`helpblock${fieldName}`} className="help-block">
                {feedback}
            </div>
        </div>
    );
};

export default textBox;

textBox.propTypes = {
    formField: PropTypes.shape({
        isRequired: PropTypes.bool,
        regExPattern: PropTypes.string,
        fieldName: PropTypes.string,
        width: PropTypes.number,
    }),
    metaData: PropTypes.shape({
        isReadOnly: PropTypes.bool,
        dataType: PropTypes.instanceOf(Object),
    }),
    displayName: PropTypes.string,
    value: PropTypes.string,
    feedback: PropTypes.string,
};
