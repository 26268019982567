import { useMapEvent } from 'react-leaflet';
import PropTypes from 'prop-types';
import CoordinatePair from '../../helpers/spatialUtils/CoordinatePair';

const leafletHooks = ({ widgets = {}, onMapReposition = () => null }) => {
    const onMapClick = (e) => {
        const anyMapClickUsingWigetsOpen = (widgets.Geocoder.isOpen || widgets['Street View'].isOpen);
        if (anyMapClickUsingWigetsOpen) {
            onMapReposition(new CoordinatePair(e.latlng.lat, e.latlng.lng));
        }
    };

    useMapEvent('click', onMapClick);

    return null;
};

export default leafletHooks;

leafletHooks.propTypes = {
    widgets: PropTypes.instanceOf(Object),
    onMapReposition: PropTypes.func,
};
