import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

const widgetPlaceholder = (WrappedComponent) => {
    class NoRecordSelection extends PureComponent {
        render() {
            const { selectedFeature } = this.props;
            if (Object.keys(selectedFeature).length) {
                // eslint-disable-next-line react/jsx-props-no-spreading
                return <WrappedComponent {...this.props} />;
            }
            return (
                <div className="col-md-12 text-center">
                    <h4>Select a feature to view.</h4>
                </div>
            );
        }
    }

    NoRecordSelection.propTypes = {
        selectedFeature: PropTypes.instanceOf(Object),
    };

    NoRecordSelection.defaultProps = {
        selectedFeature: {},
    };

    return NoRecordSelection;
};

const mapStateToProps = (state) => ({
    selectedFeature: state.selectedFeature,
});

const withNoRecordSelection = compose(connect(mapStateToProps, null), widgetPlaceholder);

export default withNoRecordSelection;
