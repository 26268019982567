import React, { useState } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { MdAddCircleOutline } from 'react-icons/md';
import { DatabaseService } from '../../../../../_services/GeotrakService';
import ICreateFieldRequest from '../../../../../_services/GeotrakService/interfaces/ICreateFieldRequest';

const createButton = ({
    table = '',
    fieldName = '',
    fieldType = '',
    fieldLength = 0,
    addField = () => null,
    setError = () => null,
}) => {
    const [isWorking, setIsWorking] = useState(false);
    const params = useParams();
    const { database, schema } = params;

    const databaseService = new DatabaseService();

    const createTableField = async () => {
        if (isWorking) return;

        setIsWorking(true);
        try {
            const request = ICreateFieldRequest.load(
                database,
                table,
                fieldName,
                fieldType,
                fieldLength,
                schema
            );

            await databaseService.createField(request);

            addField();
            setIsWorking(false);
        } catch (err) {
            setError(`Error creating column on table. ${err.message || err}`);
            setIsWorking(false);
        }
    };

    return (<MdAddCircleOutline onClick={createTableField} size={20} />);
};

export default createButton;

createButton.propTypes = {
    table: PropTypes.string,
    fieldName: PropTypes.string,
    fieldType: PropTypes.string,
    fieldLength: PropTypes.number,
    addField: PropTypes.func,
    setError: PropTypes.func,
};
