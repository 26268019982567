import React, { Component } from 'react';
import {
    MdContacts,
    MdDeveloperBoard,
    MdEmail,
    MdPeople,
    MdFolder,
    MdViewList,
    MdAssignment,
    MdViewCarousel,
    MdAutorenew,
    MdFeedback,
    MdChatBubble,
    MdAttachMoney,
    MdMap,
    MdAttachment,
    MdAlarm
} from 'react-icons/md';
import { UserService } from '../../_services';
import NavIconContainer from '../../utilComponents/NavIconContainer';

export default class AdminHome extends Component {
  renderLink = (route, Icon, adminTypes, label) => {
      const auth = new UserService();
      const userHasPermission = auth.hasAdminPermission(adminTypes);
      return (
          <div className="col-sm">
              <NavIconContainer
                  isAccessible={userHasPermission}
                  icon={Icon}
                  link={`/admin/${route}`}
                  title={label}
              />
          </div>
      );
  };

  render() {
      return (
          <div className="d-flex flex-wrap ps-3">

              {this.renderLink('autopopulations', MdAutorenew, ['DEV'], 'Auto Populate')}

              {this.renderLink('check-request', MdAttachMoney, ['IT', 'DEV'], 'Check Request')}

              {this.renderLink('comments', MdChatBubble, ['IT', 'DEV'], 'Comments')}

              {this.renderLink('databases', MdFolder, ['DEV'], 'Databases')}

              {this.renderLink('document-types', MdAttachment, ['IT', 'DEV'], 'Documents')}

              {this.renderLink('etls', MdAlarm, ['IT', 'DEV'], 'ETLs')}

              {this.renderLink('fields', MdViewList, ['IT', 'DEV'], 'Fields')}

              {this.renderLink('forms', MdAssignment, ['IT', 'DEV'], 'Forms')}

              {this.renderLink('logs', MdFeedback, ['DEV'], 'Logs')}

              {this.renderLink('markets', MdMap, ['DEV'], 'Markets')}

              {this.renderLink('modules', MdDeveloperBoard, ['DEV'], 'Modules')}

              {this.renderLink('notifications', MdEmail, ['IT', 'DEV'], 'Notifications')}

              {this.renderLink('projects', MdContacts, ['DEV'], 'Projects')}

              {this.renderLink('users', MdPeople, ['USER', 'IT', 'DEV'], 'Users')}

              {this.renderLink('views', MdViewCarousel, ['IT', 'DEV'], 'Views')}
          </div>
      );
  }
}
