import PropTypes from 'prop-types';
import { User } from '../models';

class ICreateUserRequest {
    constructor() {
        this.data = {};
    }

    static load(user) {
        if (!user) {
            throw Error('user is required');
        }
        if (!(user instanceof User)) {
            throw Error('Unexpected type for user');
        }

        const request = new ICreateUserRequest();
        request.data = user;
        return request;
    }
}

export default ICreateUserRequest;

ICreateUserRequest.propTypes = {
    data: PropTypes.instanceOf(User),
};
