import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonDanger } from '../../../../utilComponents/styledComponents';
import { FieldService } from '../../../../_services/GeotrakService';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import Modal from '../../../../components/Modals/Modal';
import '../fields.css';

const clearButton = ({ dataSourceId, onClearFields = () => null }) => {
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const fieldService = new FieldService();

    const clearFields = async () => {
        try {
            setIsLoading(true);

            await fieldService.deleteFields(dataSourceId);

            onClearFields();

            setIsLoading(false);
            setShowConfirmationPopup(false);
        } catch (err) {
            setError(err);
            setIsLoading(false);
        }
    };

    if (showConfirmationPopup) {
        return (
            <>
                <Modal
                    showModal
                    header="Clear Fields"
                    onCancel={() => setShowConfirmationPopup(false)}
                    component={(
                        <h5 className="text-center">
                            Are you sure you want to clear all fields?
                            This will permanently delete all fields and views (including custom views)
                            associated with this data source.
                        </h5>
                    )}
                    footer={(
                        <LoaderButton
                            danger
                            id="confirm-clear-field-button"
                            text="Clear All Fields"
                            loadingText="Deleting..."
                            isLoading={isLoading}
                            onClick={() => clearFields()}
                        />
                    )}
                    displayFullScreen={false}
                />
                {error && (
                    <div
                        className="text-center text-danger"
                        id="clear-field-error"
                    >
                        {`ERROR: ${error.message || 'An error occurred.'}`}
                    </div>
                )}
            </>
        );
    }

    return (
        <ButtonDanger
            id="clear-field-button"
            className="start-field-clear-button"
            onClick={() => setShowConfirmationPopup(true)}
        >
            Clear Fields
        </ButtonDanger>
    );
};

export default clearButton;

clearButton.propTypes = {
    field: PropTypes.instanceOf(Object),
    onClearFields: PropTypes.func,
};
