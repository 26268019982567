import React, { useEffect, useState } from 'react';
import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs';
import PropTypes from 'prop-types';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { DataSource, ViewField } from '../../_services/GeotrakService/Models';
import { ButtonContainer, ButtonPrimary } from '../../utilComponents/styledComponents';
import DragContainer from '../../utilComponents/DragContainer';

const fieldShuffler = ({
    usedFields = [],
    pickListFields = [],
    onFieldsUpdate = () => null,
    dataSource = new DataSource(),
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentFields, setCurrentFields] = useState([]);
    const [optionFields, setOptionFields] = useState([]);
    const { uniqueIdField } = dataSource;

    const isUniqueIdField = (fieldName) => fieldName.toLowerCase() === uniqueIdField.toLowerCase();

    const onFieldRemoved = ([list, field]) => {
        const currentFieldList = [...currentFields];
        const optionFieldList = [...optionFields];
        if (list === 'current' && !isUniqueIdField(field.id)) {
            const filteredCurrentList = currentFieldList.filter((item) => item.name !== field.id);
            setCurrentFields(filteredCurrentList);
            onFieldsUpdate(filteredCurrentList);
        }
        if (list === 'options' && !isUniqueIdField(field.id)) {
            const rawField = optionFieldList.find((item) => item.name === field.id);
            setCurrentFields([...currentFieldList, rawField]);
            onFieldsUpdate([...currentFieldList, rawField]);
        }
    };

    const onFieldReorder = (fieldCards) => {
        const fieldNames = fieldCards.map((field) => field.id);
        const reorderedFields = [...currentFields].sort(
            (a, b) => fieldNames.indexOf(a.name) - fieldNames.indexOf(b.name)
        );
        setCurrentFields(reorderedFields);
        onFieldsUpdate(reorderedFields);
    };

    const addAllFields = () => {
        const allFieldsList = [...optionFields];
        setCurrentFields(allFieldsList);
        onFieldsUpdate(allFieldsList);
    };

    const removeAllFields = () => {
        const emptyFieldsList = [...currentFields.filter(
            (field) => field.name.toLowerCase() === uniqueIdField.toLowerCase()
        )];
        setCurrentFields(emptyFieldsList);
        onFieldsUpdate(emptyFieldsList);
    };

    const getAvailableFields = () => optionFields.filter(
        ({ name: pickName }) => !currentFields.some(({ name: fieldName }) => fieldName === pickName)
    );

    const availableFields = getAvailableFields();
    const filteredFields = availableFields.filter(
        (field) => field.alias.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        setCurrentFields(usedFields);
        setOptionFields(pickListFields);
    }, [usedFields, pickListFields]);

    return (
        <div className="container col-md-12">
            <div className="container col-md-12 mb-3">
                <input
                    value={searchTerm}
                    placeholder="Search from available"
                    onChange={(e) => setSearchTerm((e.target.value))}
                />
            </div>
            <div>
                <ButtonContainer className="justify-content-center">
                    <ButtonPrimary
                        data-bs-toggle="tooltip"
                        title="Add all fields"
                        onClick={() => addAllFields()}
                    >
                        <BsChevronDoubleRight size={15} />
                    </ButtonPrimary>
                    <span />
                    <ButtonPrimary
                        data-bs-toggle="tooltip"
                        title="Remove all fields"
                        onClick={() => removeAllFields()}
                    >
                        <BsChevronDoubleLeft size={15} />
                    </ButtonPrimary>
                </ButtonContainer>
            </div>
            <div className="d-flex">
                <div className="col-md-6">
                    <strong>
                        Available fields (click and drag to place a field in selected fields):
                    </strong>
                    <DragContainer
                        id="options"
                        list={filteredFields.map((field) => ({ id: field.name, text: field.alias }))}
                        cardOrder={() => null}
                        uniqueIdField={uniqueIdField}
                        removedFrom={onFieldRemoved}
                    />
                </div>
                <div className="col-md-6">
                    <strong>
                        Selected fields (fields here will be included in your custom view):
                    </strong>
                    <DragContainer
                        id="current"
                        list={currentFields.map((field) => ({ id: field.name, text: field.alias }))}
                        cardOrder={onFieldReorder}
                        uniqueIdField={uniqueIdField}
                        removedFrom={onFieldRemoved}
                    />
                </div>
            </div>
        </div>
    );
};

export default DragDropContext(HTML5Backend)(fieldShuffler);

fieldShuffler.propTypes = {
    usedFields: PropTypes.arrayOf(PropTypes.instanceOf(ViewField)),
    pickListFields: PropTypes.arrayOf(PropTypes.instanceOf(ViewField)),
    onFieldsUpdate: PropTypes.func,
    dataSource: PropTypes.instanceOf(DataSource),
};
