import React, { useState } from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import useDeleteGeocoderJob from '../../../../_services/EtlService/hooks/useDeleteGeocoderJob';
import Modal from '../../../../components/Modals/Modal';
import { ButtonDanger } from '../../../../utilComponents/styledComponents';
import '../etljobs.css';

const deleteButton = ({ jobId = null }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const {
        deleteGeocoderJob, error, isLoading, isSuccessful,
    } = useDeleteGeocoderJob();

    if (!jobId) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/etls/geocoder-jobs" />;
    }

    const deleteJob = () => {
        setShowConfirmation(false);
        deleteGeocoderJob(jobId);
    };

    return (
        <div className="etl-job-toolbar geocoder-job-button">
            <Modal
                showModal={showConfirmation}
                header="Wait!"
                onCancel={() => setShowConfirmation(false)}
                component={
                    <h5 className="text-center">Are you sure you want to delete this job?</h5>
                }
                footer={
                    <ButtonDanger onClick={deleteJob}>Delete</ButtonDanger>
                }
            />
            {error && <div className="text-danger etl-geocoder-job-error" id="delete-geocoder-job-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                danger
                id="delete-geocoder-job-button"
                text="Delete"
                loadingText="Deleting..."
                isLoading={isLoading}
                onClick={() => setShowConfirmation(true)}
            />
        </div>
    );
};

export default deleteButton;

deleteButton.propTypes = {
    jobId: PropTypes.string,
};
