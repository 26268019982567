import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { activateModuleThunk, resetActiveModule } from '../actions/moduleActions';
import { resetQuery } from '../actions/queryActions';
import DataViewComponent from './DataView';
import MapView from './Maps/MapView';
import Loading from '../utilComponents/LoaderSpinner';
import { MapConfig } from '../_services/GeotrakService/Models';
import { ConfigType, HistoryType } from '../types';
import { MapService } from '../_services/GeotrakService';

const DATA_SOURCE_ROUTE = 'data-source';

class GeoTRAK extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mapConfig: new MapConfig(),
        };

        this.mapService = new MapService();
    }

    componentDidMount() {
        const { activateModuleAction, history } = this.props;
        const searchParameters = new URLSearchParams(history.location.search);
        this.getMapConfiguration();
        activateModuleAction(searchParameters.get(DATA_SOURCE_ROUTE), history);
    }

    componentWillUnmount() {
        const {
            resetModuleAction,
            resetQueryAction,
        } = this.props;
        resetModuleAction();
        resetQueryAction();
    }

    getMapConfiguration = async () => {
        const { match: { params: { moduleRoleId } } } = this.props;
        const mapConfig = await this.mapService.getModuleMap(moduleRoleId);
        this.setState({ mapConfig });
    }

    render() {
        const {
            history,
            match: { path, url },
            config,
        } = this.props;
        const { mapConfig } = this.state;
        const configLoaded = config.isLoaded;

        const searchParameters = new URLSearchParams(history.location.search);

        return (
            <>
                <nav className="navbar navbar-default navbar-expand-sm data-navbar">
                    <div className="container-fluid">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link text-white" href={`${url}/data?data-source=${searchParameters.get(DATA_SOURCE_ROUTE)}`}>
                                    Data
                                </a>
                            </li>
                            {mapConfig.id && (
                                <li className="nav-item">
                                    <a className="nav-link text-white" href={`${url}/map?data-source=${searchParameters.get(DATA_SOURCE_ROUTE)}`}>
                                        Map
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </nav>
                {configLoaded && (
                    <Switch>
                        <Route path={`${path}/data`} component={DataViewComponent} />
                        <Route path={`${path}/map`} component={MapView} />
                    </Switch>
                )}
                {!configLoaded && (
                    <div className="topMargin">
                        <Loading className="topMargin" />
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    config: state.config,
});

const mapDispatchToProps = {
    resetModuleAction: resetActiveModule,
    activateModuleAction: activateModuleThunk,
    resetQueryAction: resetQuery,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeoTRAK);

GeoTRAK.propTypes = {
    resetQueryAction: PropTypes.func,
    resetModuleAction: PropTypes.func,
    activateModuleAction: PropTypes.func,
    config: ConfigType.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string,
        url: PropTypes.string,
        params: PropTypes.instanceOf(Object),
    }).isRequired,
    history: HistoryType,
};

GeoTRAK.defaultProps = {
    resetQueryAction: () => null,
    resetModuleAction: () => null,
    activateModuleAction: () => null,
    history: {},
};
