import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import '../notifications.css';
import { NotificationField } from '../../../../_services/NotificationService/Models';
import useCreateNotificationField from '../../../../_services/NotificationService/hooks/field/useCreateNotificationField';

const createFieldButton = ({ notificationField = new NotificationField(), isValid = () => null }) => {
    const {
        createNotificationField, error, isLoading, isSuccessful,
    } = useCreateNotificationField();

    if (notificationField.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/notifications/${notificationField.notificationId}/fields`} />;
    }

    return (
        <div className="notification-toolbar notification-button">
            {error && <div className="text-danger notification-error" id="create-notification-field-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="create-notification-field-button"
                text="Create"
                loadingText="Creating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => createNotificationField(notificationField)}
            />
        </div>
    );
};

export default createFieldButton;

createFieldButton.propTypes = {
    isValid: PropTypes.func,
    notificationField: PropTypes.instanceOf(NotificationField),
};
