import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Marker, LayerGroup } from 'react-leaflet';
import MapContextMenu from './MapContextMenu';
import { addAlertThunk } from '../actions/errorActions';
import { fetchFeatureThunk, resetFeature } from '../actions/featureActions';
import { setIdCollection } from '../actions/idActions';
import { setLayerVisibilityAction } from '../actions/mapActions';
import { editData } from '../helpers/tools';
import { formatQuery } from '../helpers/utilities';
import { MapConfig } from '../_services/GeotrakService/Models';
import MapMarkers from './MapMarkers';
import useGetDataSources from '../_services/GeotrakService/hooks/useGetDataSources';
import CoordinatePair from '../helpers/spatialUtils/CoordinatePair';
import { ProjectRecordService } from '../_services/GeotrakService';
import IGetSpatialRecordsRequest from '../_services/GeotrakService/interfaces/IGetSpatialRecordsRequest';

const isValidMapLayer = (selectedDataSource) => !(
    selectedDataSource.dataType === 'table'
        || !selectedDataSource.databaseReference
        || !selectedDataSource.tableReference
);

const getTargetQuery = (dataSource, selectedDataSource, activeQuery) => {
    if (selectedDataSource.name === dataSource.name) {
        return activeQuery;
    }
    return formatQuery(selectedDataSource);
};

const mapLayers = ({ mapConfig = new MapConfig(), onMapReposition = () => null }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const searchParameters = new URLSearchParams(history.location.search);
    const projectRecordService = new ProjectRecordService();
    const activeQuery = useSelector((state) => state.activeConfiguration.query);
    const { dataSources } = useGetDataSources(params.moduleRoleId);
    const dataSource = dataSources.find((source) => source.id === searchParameters.get('data-source'));
    const visibleLayers = useSelector((state) => state.mapUI.visibleLayers);

    const [isLoading, setIsLoading] = useState(true);

    const [contextMenuData, setContextMenuData] = useState(
        {
            isContextMenuVisible: false,
            contextMenuPosition: [],
            canConfirmMarkerPlacement: false,
            markerPosition: [],
            markerSiteId: null,
            markerLayer: null,
        }
    );
    const [selectedSiteId, setSelectedSiteId] = useState(null);

    const [mapData, setMapData] = useState({});

    // Update marker to position of click, context menu to nearest container.
    const showContextMenu = useCallback((contextMenuCoordinates, latLng, layer, siteId) => {
        setContextMenuData((oldContextMenuData) => ({
            ...oldContextMenuData,
            isContextMenuVisible: true,
            contextMenuPosition: contextMenuCoordinates,
            markerPosition: latLng,
            markerSiteId: siteId,
            markerLayer: layer,
        }));
    }, [setContextMenuData]);

    // TODO (EOKORONKWO) 2021-03-03 Reset all the context data
    const closeContextMenu = useCallback(() => {
        setContextMenuData((oldContextMenuData) => ({
            ...oldContextMenuData,
            isContextMenuVisible: false,
        }));
    }, [setContextMenuData]);

    const startMovingLocation = useCallback(() => {
        setContextMenuData((oldContextMenuData) => ({
            ...oldContextMenuData,
            canConfirmMarkerPlacement: true,
        }));
    }, [setContextMenuData]);

    const cancelMovingLocation = useCallback(() => {
        setContextMenuData(
            {
                isContextMenuVisible: false,
                contextMenuPosition: [],
                canConfirmMarkerPlacement: false,
                markerPosition: [],
                markerSiteId: null,
                markerLayer: null,
            }
        );
    }, [setContextMenuData]);

    const handleMarkerSelection = useCallback((event) => {
        const { id, layer } = event.target.options;

        if (layer !== dataSource.name) {
            dispatch(addAlertThunk(
                'danger',
                'Invalid Selection',
                'You can only select points from the currently active data tab.'
            ));
            return;
        }

        if (id === selectedSiteId) {
            setSelectedSiteId(null);
            dispatch(resetFeature());
        } else {
            dispatch(fetchFeatureThunk(dataSource.id, id));
            const newLocation = new CoordinatePair(event.latlng.lat, event.latlng.lng);
            onMapReposition(newLocation);
            setSelectedSiteId(id);
            dispatch(setIdCollection([id]));
        }
    }, [dataSource, dataSources]);

    const handleConfirmLocation = useCallback(async () => {
        const newPoint = {
            lat: contextMenuData.markerPosition[0],
            lng: contextMenuData.markerPosition[1],
        };

        const { databaseReference, tableReference, uniqueIdField } = dataSources.find(
            (source) => source.name === contextMenuData.markerLayer
        );
        const promises = [
            editData(databaseReference, tableReference, uniqueIdField, contextMenuData.markerSiteId, [{ name: 'Latitude', value: newPoint.lat.toString() }]),
            editData(databaseReference, tableReference, uniqueIdField, contextMenuData.markerSiteId, [{ name: 'Longitude', value: newPoint.lng.toString() }]),
        ];
        await Promise.all(promises);
        // Update local state with new point data.
        setMapData((mapDataState) => ({
            ...mapDataState,
            layer: {
                ...mapDataState[contextMenuData.markerLayer],
                [newPoint.id]: { ...newPoint },
            },
        }));

        setContextMenuData(
            {
                isContextMenuVisible: false,
                contextMenuPosition: [],
                canConfirmMarkerPlacement: false,
                markerPosition: [],
                markerSiteId: null,
                markerLayer: null,
            }
        );
    }, [contextMenuData, dataSources, setMapData, setContextMenuData]);

    const loadSpatialData = useCallback(async (loadTargetDataSources) => {
        setIsLoading(true);
        const spatialDataPromises = [];
        const visibility = {};
        loadTargetDataSources.forEach((selectedDataSource) => {
            const query = getTargetQuery(dataSource, selectedDataSource, activeQuery);
            visibility[selectedDataSource.name] = selectedDataSource.isMapDefaultOn;

            // Only update if this data source is valid for render in this view.
            if (isValidMapLayer(selectedDataSource)) {
                spatialDataPromises.push(
                    projectRecordService.getSpatialRecords(
                        IGetSpatialRecordsRequest.load(selectedDataSource, query)
                    ).then((points) => ({ points, selectedDataSource }))
                );
            }
        });

        try {
            // Wait for all spatial data to be returned, then iterate and render it.
            const data = await Promise.all(spatialDataPromises);
            const updatedMapData = {};
            data.forEach(({ points, selectedDataSource }) => {
                const normalizedPointData = {};
                points.forEach((point) => {
                    normalizedPointData[point.SiteId] = point;
                });
                updatedMapData[selectedDataSource.name] = normalizedPointData;
            });

            // Update visibility of all of the layers to the initialized default state.
            dispatch(setLayerVisibilityAction(visibility));

            // Update map data
            setMapData(updatedMapData);
        } catch (err) {
            dispatch(addAlertThunk('danger', 'Failed to load map data.', err));
        }

        setIsLoading(false);
    }, [dataSource, activeQuery]);

    useEffect(() => {
        loadSpatialData(dataSources, dataSource, activeQuery);
    }, [dataSources, dataSource, activeQuery]);

    if (isLoading) {
        return null;
    }

    return (
        <>
            <MapContextMenu
                top={contextMenuData.contextMenuPosition[1]}
                left={contextMenuData.contextMenuPosition[0]}
                closeMenu={closeContextMenu}
                startMovingLocation={startMovingLocation}
                confirmLocation={handleConfirmLocation}
                cancelMoving={cancelMovingLocation}
                hasConfirm={contextMenuData.canConfirmMarkerPlacement}
                visible={contextMenuData.isContextMenuVisible}
                mapConfig={mapConfig}
            />
            <MapMarkers
                data={mapData}
                dataSources={dataSources}
                selectedSiteId={selectedSiteId}
                visibleLayers={visibleLayers}
                handleMarkerSelection={handleMarkerSelection}
                showContextMenu={showContextMenu}
            />
            { contextMenuData.canConfirmMarkerPlacement && (
                <LayerGroup>
                    <Marker
                        position={contextMenuData.markerPosition}
                        draggable
                        onContextMenu={(event) => showContextMenu(
                            [event.containerPoint.x, event.containerPoint.y],
                            [event.latlng.lat, event.latlng.lng],
                            contextMenuData.markerLayer,
                            contextMenuData.markerSiteId
                        )}
                    />
                </LayerGroup>
            )}
        </>
    );
};

export default mapLayers;

mapLayers.propTypes = {
    mapConfig: PropTypes.instanceOf(MapConfig),
    onMapReposition: PropTypes.func,
};
