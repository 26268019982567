import React from 'react';
import PropTypes from 'prop-types';
import { utc } from 'moment';

const dateFormatter = ({ value = null }) => {
    const date = utc(value);
    const context = (!date.isValid()) ? value : date.format('MM/DD/YYYY');
    return <span id="date-format-label">{context}</span>;
};

export default dateFormatter;

dateFormatter.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.number]),
};
