import { useState, useEffect } from 'react';
import useGetRoles from '../../../_services/GeotrakService/hooks/useGetRoles';
import useGetUser from '../../../_services/UserService/hooks/useGetUser';

const useUserForm = (id) => {
    const { roles, isLoading: isLoadingRoles } = useGetRoles();

    let user = {};
    let isLoading = false;

    if (id) {
        const result = useGetUser(id);
        user = result.user;

        isLoading = result.isLoading;
    }

    const [values, setValues] = useState({
        id: null,
        username: '',
        emailAddress: '',
        name: '',
        phoneNumber: '',
        adminPermission: '',
        roles: [],
        isDeleted: false,
    });

    const isValid = () => values.name !== '' && values.username !== '' && values.emailAddress !== '';

    const onFormUpdate = (property, value) => {
        const updatedForm = { ...values, [property]: value };
        setValues(updatedForm);
    };

    const onRoleUpdate = (value) => {
        const userHasRole = values.roles.find((userRole) => userRole.id === value);
        if (userHasRole) {
            setValues({ ...values, roles: [...values.roles.filter((userRole) => userRole.id !== value)] });
        } else {
            const newRole = roles.find((role) => role.id === value);
            setValues({ ...values, roles: [...values.roles, newRole] });
        }
    };

    useEffect(() => {
        if (id && user && !isLoadingRoles) {
            setValues({
                id: user.id || null,
                username: user.username || '',
                emailAddress: user.emailAddress || '',
                name: user.name || '',
                phoneNumber: user.phoneNumber || '',
                adminPermission: user.adminPermission || '',
                roles: user.roles,
                isDeleted: user.isDeleted || false,
            });
        }
    }, [user, isLoadingRoles]);

    return {
        values,
        onFormUpdate,
        onRoleUpdate,
        isValid,
        roles,
        isLoading,
    };
};

export default useUserForm;
