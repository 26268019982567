import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../../../utilComponents/ErrorMessage';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';
import CreateTableButton from './Actions/Table/CreateButton';
import CreateFieldButton from './Actions/Fields/CreateButton';
import '../../stylesheets/AdminTablesForm.css';
import IGetTablesRequest from '../../../_services/GeotrakService/interfaces/IGetTablesRequest';
import { DatabaseService } from '../../../_services/GeotrakService';
import TableField from '../../../_services/GeotrakService/Models/TableField';

const MAX_FIELD_LENGTH = 100;
const DEFAULT_FIELD_LENGTH = 25;

const adminTablesForm = ({ match = {} }) => {
    const { database, schema } = match.params;
    const [fieldType, setFieldType] = useState('varchar');
    const [fields, setFields] = useState([]);
    const [isSpatial, setIsSpatial] = useState(false);
    const [isTableCreated, setIsTableCreated] = useState(false);
    const [existingTables, setExistingTables] = useState([]);
    const [fieldName, setFieldName] = useState('');
    const [fieldLength, setFieldLength] = useState(DEFAULT_FIELD_LENGTH);
    const [tableName, setTableName] = useState('');
    const [error, setError] = useState(null);
    const databaseService = new DatabaseService();

    const getTables = async () => {
        try {
            const request = IGetTablesRequest.load(database, schema);
            const tables = await databaseService.getTables(request);
            setExistingTables(tables);
        } catch (err) {
            setError(`An error occurred while getting existing tables. ${err.message || err}`);
        }
    };

    const addField = () => {
        try {
            const currentSchema = [...fields, TableField.load(fieldName, fieldType, fieldLength)];
            setFields(currentSchema);
            setFieldName('');
            setFieldLength(DEFAULT_FIELD_LENGTH);
        } catch (err) {
            setError(`An error occurred while adding field. ${err.message || err}`);
        }
    };

    const isTableNameValid = () => !existingTables.some(
        (table) => table.trim().toLowerCase() === tableName.trim().toLowerCase()
    );

    const isFieldNameValid = () => !fields.some(
        (field) => field.name.toLowerCase() === fieldName.toLowerCase()
    );

    const isFieldLengthValid = () => fieldLength > 0 && fieldLength <= MAX_FIELD_LENGTH;

    const isFieldValid = () => fieldName && isFieldNameValid() && isFieldLengthValid();

    const renderTableSchema = () => fields.map((field) => (
        <tr key={field.name}>
            <td>{field.name}</td>
            <td>{field.type}</td>
            <td>{field.length}</td>
        </tr>
    ));

    const renderTableFields = () => (
        <div className="table-fields">
            <table className="table table-responsive table-bordered">
                <thead>
                    <tr>
                        <th>New Field Name</th>
                        <th>Data Type</th>
                        <th>Length</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input
                                value={fieldName || ''}
                                onChange={(e) => setFieldName(e.target.value.replace(' ', ''))}
                            />
                            {fieldName && !isFieldNameValid() && (
                                <span className="text-right text-warning">Field name already exists</span>
                            )}
                        </td>
                        <td>
                            <select
                                onChange={(e) => setFieldType(e.target.value)}
                                value={fieldType}
                            >
                                <option value="varchar">varchar</option>
                                <option value="decimal">decimal</option>
                                <option value="integer">integer</option>
                                <option value="date">date</option>
                                <option value="datetime">datetime</option>
                            </select>
                        </td>
                        <td>
                            <input
                                className="input-field"
                                onChange={(e) => setFieldLength(e.target.value)}
                                type="number"
                                min={1}
                                max={MAX_FIELD_LENGTH}
                                disabled={fieldType !== 'varchar'}
                                value={fieldLength}
                            />
                            {!isFieldLengthValid() && (
                                <span className="text-warning">
                                    {`Maximum field length is ${MAX_FIELD_LENGTH}`}
                                </span>
                            )}
                        </td>
                        <td className="text-center">
                            <button className={`field-add-icon-disabled-${!isFieldValid()} mt-2`} type="button" disabled={!isFieldValid()}>
                                <CreateFieldButton
                                    table={tableName}
                                    fieldName={fieldName}
                                    fieldLength={fieldLength}
                                    fieldType={fieldType}
                                    addField={addField}
                                    setError={setError}
                                />
                            </button>
                        </td>

                    </tr>
                    {renderTableSchema()}
                </tbody>
            </table>
        </div>
    );

    useEffect(() => {
        if (database) {
            getTables();
        }
    }, []);

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Table">
                <div className="mb-3">
                    <label htmlFor="table-name" className="form-label">
                        Table Name
                        {' '}
                        <span className="text-danger">*</span>
                    </label>
                    <input
                        name="table-name"
                        value={tableName}
                        disabled={isTableCreated}
                        onChange={(e) => setTableName(e.target.value)}
                    />
                    {tableName && !isTableNameValid() && (
                        <span className="text-warning">Table name already exists</span>
                    )}
                </div>
                <form>
                    <div className="form-check mb-3">
                        <label htmlFor="spatial-option" className="form-check-label">
                            <input
                                name="spatial-option"
                                checked={isSpatial}
                                type="checkbox"
                                className="form-check-input"
                                onChange={(e) => setIsSpatial(e.target.checked)}
                                disabled={isTableCreated}
                            />
                            This data is spatial
                        </label>
                    </div>
                </form>
                {isTableCreated && renderTableFields()}
                {!isTableCreated && (
                    <CreateTableButton
                        disabled={tableName === '' || !isTableNameValid()}
                        table={tableName}
                        isSpatial={isSpatial}
                        setIsTableCreated={setIsTableCreated}
                    />
                )}
                {error && (
                    <ErrorMessage
                        message={error}
                        onDismiss={() => setError(null)}
                    />
                )}
            </AdminContainer>
        </>
    );
};

export default adminTablesForm;

adminTablesForm.propTypes = {
    match: PropTypes.instanceOf(Object),
};
