import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ModuleWidget, WidgetType } from '../../../../_services/GeotrakService/Models';
import useDeleteModuleWidget from '../../../../_services/GeotrakService/hooks/useDeleteModuleWidget';
import useCreateModuleWidget from '../../../../_services/GeotrakService/hooks/useCreateModuleWidget';
import '../maps.css';

const toggleWidget = ({
    isIncluded = false,
    widget = new WidgetType(),
    moduleRoleId = null,
}) => {
    const [isChecked, setIsChecked] = useState(isIncluded);
    const { deleteModuleWidget, error: deleteError } = useDeleteModuleWidget();
    const { createModuleWidget, error: createError } = useCreateModuleWidget();

    const deleteWidget = () => {
        setIsChecked(false);
        deleteModuleWidget(moduleRoleId, widget.id);
    };

    const addWidget = () => {
        const newWidget = new ModuleWidget();
        newWidget.moduleRoleId = moduleRoleId;
        newWidget.widgetType = widget;
        createModuleWidget(newWidget);
        setIsChecked(true);
    };

    const toggleWidgetState = () => {
        if (isChecked) {
            deleteWidget();
        } else {
            addWidget();
        }
    };

    useEffect(() => {
        setIsChecked(isIncluded);
    }, [isIncluded]);

    return (
        <div key={widget.id} className="form-check mb-3">
            <label
                htmlFor={`toggle-widget-${widget.name}`}
                className="form-check-label"
            >
                {widget.name}
                {' '}
                <span className="widget-description">
                    <em>{`(${widget.description})`}</em>
                </span>
                <input
                    type="checkbox"
                    className="form-check-input"
                    id={`toggle-widget-${widget.name}`}
                    checked={isChecked}
                    onChange={toggleWidgetState}
                />
                {deleteError && <div className="text-danger" id="delete-widget-error">{`ERROR: ${deleteError.message || 'An error occurred.'}`}</div>}
                {createError && <div className="text-danger" id="create-widget-error">{`ERROR: ${createError.message || 'An error occurred.'}`}</div>}
            </label>
        </div>
    );
};

export default toggleWidget;

toggleWidget.propTypes = {
    widget: PropTypes.instanceOf(WidgetType),
    moduleRoleId: PropTypes.string,
    isIncluded: PropTypes.bool,
};
