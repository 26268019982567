import React from 'react';
import PropTypes from 'prop-types';
import spinner from '../Assets/blocks.gif';
import '../SpinnerControl.css';

const spinnerControl = function statelessFunctionComponentClass({
    children = [],
    message = '',
}) {
    return (
        <div id="geotrak-spinner-control" className="text-center">
            <img src={spinner} alt="spinner" />
            {children}
            <div className="text-info">{message}</div>
        </div>
    );
};

export default spinnerControl;

spinnerControl.propTypes = {
    children: PropTypes.arrayOf(PropTypes.shape({
        message: PropTypes.string,
        level: PropTypes.string,
        visible: PropTypes.bool,
    })),
    message: PropTypes.string,
};
