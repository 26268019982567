import { useReducer } from 'react';
import UserService from '../UserService';
import IUpdateUserRequest from '../interfaces/IUpdateUserRequest';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';

const useUpdateUser = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const {
        data, isLoading, error, isSuccessful,
    } = state;

    const updateUser = async (userObject) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const userService = new UserService();
            const request = IUpdateUserRequest.load(userObject);
            const result = await userService.updateUser(request);
            if (userService.getCurrentUser().userid === userObject.username) {
                await userService.getUser(userObject.username);
            }
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        updateUser, error, isLoading, isSuccessful, data,
    };
};

export default useUpdateUser;
