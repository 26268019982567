import BaseNodeService from '../BaseNodeService';

export default class FormService extends BaseNodeService {
    constructor() {
        const url = '/form-service';
        const port = 3019;
        super(url, port);
    }

    get() {
        return this.fetch('/forms');
    }

    getById(id) {
        return this.fetch(`/forms/${id}`).then((res) => {
            res.formFields = this.sortFormFields(res.formFields);
            return res;
        }).catch((err) => err);
    }

    deleteForm(id) {
        return this.delete(`/forms/${id}`);
    }

    sortFormFields = (fields) => fields.sort((a, b) => {
        const x = a.orderIndex;
        const y = b.orderIndex;
        if (x < y) {
            return -1;
        }
        if (x > y) {
            return 1;
        }
        return 0;
    })

    getFormsByModuleRole(moduleName, roleId) {
        return this.fetch(`/forms/module/${moduleName}/role/${roleId}`);
    }

    getFormsByModuleProjectRole(selectedRoles) {
        const promises = [];
        selectedRoles.forEach((role) => promises.push(this.fetch(`/forms/role/${role.name}`)));
        return Promise.all(promises).then((results) => results[0]);
    }

    insert(form) {
        return this.postJson('/forms', form);
    }

    updateForm(form) {
        return this.put(`/forms/${form.id}`, form);
    }
}
