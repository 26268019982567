import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import EtlJob from '../../../../_services/EtlService/Models/EtlJob';
import useCreateEtlJob from '../../../../_services/EtlService/hooks/useCreateEtlJob';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import ErrorMessage from '../../../../utilComponents/ErrorMessage';

const createButton = ({
    etlJob = new EtlJob(),
    isValid = () => null,
    moduleId = null,
}) => {
    const {
        createEtlJob, error, isLoading, isSuccessful,
    } = useCreateEtlJob();

    if (etlJob.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/modules/${moduleId}/roles/${etlJob.moduleRoleId}/etls`} />;
    }

    return (
        <div className="d-flex flex-column">
            <div className="d-flex justify-content-end mb-2">
                <LoaderButton
                    id="create-etl-button"
                    text="Create"
                    loadingText="Creating..."
                    isLoading={isLoading}
                    disabled={!isValid()}
                    onClick={() => createEtlJob(etlJob)}
                />
            </div>
            {error && <ErrorMessage message={error.message || error} />}
        </div>
    );
};

export default createButton;

createButton.propTypes = {
    etlJob: PropTypes.instanceOf(EtlJob),
    isValid: PropTypes.func,
    moduleId: PropTypes.string,
};
