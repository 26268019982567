import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import useUpdateGeocoderJob from '../../../../_services/EtlService/hooks/useUpdateGeocoderJob';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import GeocoderJob from '../../../../_services/EtlService/Models/GeocoderJob';
import '../etljobs.css';

const updateButton = ({ job = new GeocoderJob(), isValid = () => null }) => {
    const {
        updateJob, isLoading, isSuccessful, error,
    } = useUpdateGeocoderJob();

    if (!job.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/etls/geocoder-jobs" />;
    }

    return (
        <div className="etl-job-toolbar geocoder-job-button">
            {error && <div className="text-danger etl-geocoder-job-error" id="update-etl-geocoder-job-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="update-etl-geocoder-job-button"
                text="Update"
                loadingText="Updating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => updateJob(job)}
            />
        </div>
    );
};

export default updateButton;

updateButton.propTypes = {
    job: PropTypes.instanceOf(GeocoderJob),
    isValid: PropTypes.func,
};
