import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonContainer, ButtonPrimary, ButtonDanger } from '../../../utilComponents/styledComponents';
import LoaderButton from '../../../utilComponents/LoaderButton';
import Modal from '../../../components/Modals/Modal';
import { CheckRequestAdminService } from '../../../_services/CheckRequestService';
import { Option } from '../../../_services/CheckRequestService/Models';
import './checkRequest.css';

const checkRequestAdminService = new CheckRequestAdminService();

const fieldOptions = ({ fieldId = null }) => {
    const [options, setOptions] = useState([]);
    const [selectedOptionId, setOption] = useState(null);
    const [optionName, setOptionName] = useState('');
    const [showNewOption, setNewOption] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [error, setError] = useState(null);

    const getOptions = async () => {
        try {
            setNewOption(false);
            setOption(null);
            const fieldResult = await checkRequestAdminService.getField(fieldId);
            setOptions(fieldResult.options);
            setError(null);
        } catch (err) {
            setError(err.message || err);
        }
    };

    const selectOption = (option) => {
        setOption(option.id);
        setOptionName(option.name);
    };

    const createOption = async (newOption) => {
        try {
            await checkRequestAdminService.createOption(newOption);
            getOptions();
            setSaving(false);
            setNewOption(false);
            setOption(null);
            setOptionName('');
            setError(null);
        } catch (err) {
            setError(err.message || err);
            setSaving(false);
        }
    };

    const updateOption = async (newOption) => {
        try {
            await checkRequestAdminService.updateOption(newOption);
            getOptions();
            setSaving(false);
            setOption(null);
            setOptionName('');
            setError(null);
        } catch (err) {
            setSaving(false);
            setError(err.message || err);
        }
    };

    const deleteOption = async () => {
        try {
            setShowConfirmation(false);
            setDeleting(true);
            await checkRequestAdminService.deleteOption(selectedOptionId);
            getOptions();
            setDeleting(false);
            setOption(null);
            setError(null);
        } catch (err) {
            setDeleting(false);
            setError(err.message || err);
        }
    };

    const isValid = () => optionName !== '';

    const submitOption = () => {
        setSaving(true);
        const newOption = new Option(optionName, fieldId, selectedOptionId);
        if (selectedOptionId) {
            updateOption(newOption);
        } else {
            createOption(newOption);
        }
    };

    const renderOptionForm = () => (
        <form>
            <div className="mb-3">
                <label htmlFor="option-name" className="form-label">
                    Name
                </label>
                <input
                    type="text"
                    id="option-name"
                    value={optionName}
                    placeholder="Name"
                    onChange={(e) => setOptionName(e.target.value)}
                />
            </div>
            <ButtonContainer
                error={error}
                onErrorDismiss={() => setError(null)}
            >
                <LoaderButton
                    type="submit"
                    text="Save"
                    loadingText="Saving..."
                    isLoading={isSaving}
                    disabled={!isValid()}
                    onClick={submitOption}
                />
                <LoaderButton
                    onClick={() => setShowConfirmation(true)}
                    text="Delete"
                    loadingText="Deleting..."
                    isLoading={isDeleting}
                    disabled={!isValid()}
                    danger
                />
            </ButtonContainer>
        </form>
    );

    const renderOptions = () => (
        <div className="list-group cr-options-container">
            {options.sort((a, b) => ((a.name > b.name) ? 1 : -1)).map((option) => (
                <button className="list-group-item list-group-item-action" type="button" key={option.id} onClick={() => selectOption(option)}>
                    {option.name}
                </button>
            ))}
        </div>
    );

    useEffect(() => {
        getOptions();
    }, [fieldId]);

    return (
        <>
            <h4 className="text-center">Options</h4>
            <ButtonContainer>
                <ButtonPrimary onClick={() => setNewOption(true)}>
                    New Option
                </ButtonPrimary>
            </ButtonContainer>
            {renderOptions()}
            {(selectedOptionId || showNewOption) && renderOptionForm()}
            <Modal
                showModal={showConfirmation}
                header="Wait!"
                onCancel={() => setShowConfirmation(false)}
                component={
                    <h5 className="text-center">Are you sure you want to delete this option?</h5>
                }
                footer={
                    <ButtonDanger onClick={deleteOption}>Delete</ButtonDanger>
                }
                displayFullScreen={false}
            />
        </>
    );
};

export default fieldOptions;

fieldOptions.propTypes = {
    fieldId: PropTypes.number,
};
