import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdDelete } from 'react-icons/md';
import { ButtonDanger } from '../../../../../utilComponents/styledComponents';
import { Domain } from '../../../../../_services/GeotrakService/Models';
import { DomainService } from '../../../../../_services/GeotrakService';
import Modal from '../../../../../components/Modals/Modal';
import ErrorMessage from '../../../../../utilComponents/ErrorMessage';
import '../../../../stylesheets/AdminDomains.css';

const updateButton = ({ domain = new Domain(), onDelete = () => null }) => {
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [error, setError] = useState(null);
    const domainService = new DomainService();

    const deleteDomain = () => {
        setShowConfirmation(false);
        domainService.deleteDomainValue(selectedDomain.id).then(() => {
            onDelete();
            setSelectedDomain(null);
        }).catch((err) => {
            setError(`Error deleting domain. ${err.message || err}`);
        });
    };

    return (
        <div className="row">
            <div>
                <MdDelete
                    size={20}
                    onClick={() => {
                        setSelectedDomain(domain);
                        setShowConfirmation(true);
                    }}
                    className="text-danger admin-domain-action-icon"
                />
                <Modal
                    showModal={showConfirmation}
                    header="Wait!"
                    onCancel={() => {
                        setShowConfirmation(false);
                        setSelectedDomain(null);
                    }}
                    component={
                        <h5 className="text-center">Are you sure you want to delete this domain value?</h5>
                    }
                    footer={
                        <ButtonDanger onClick={deleteDomain}>Delete</ButtonDanger>
                    }
                    displayFullScreen={false}
                />
            </div>
            <div className="col">
                {error && (
                    <ErrorMessage
                        message={error}
                        onDismiss={() => setError(null)}
                    />
                )}
            </div>
        </div>
    );
};

export default updateButton;

updateButton.propTypes = {
    domain: PropTypes.instanceOf(Domain),
    isDisabled: PropTypes.func,
    onDelete: PropTypes.func,
};
