import React from 'react';
import PropTypes from 'prop-types';
import './logs.css';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import ErrorMessage from '../../../utilComponents/ErrorMessage';

const adminLogsError = ({ error = null }) => {
    if (error) {
        return (
            <div id="logs-error">
                <Breadcrumbs />
                <ErrorMessage message={error.message || 'An error occurred.'} />
            </div>
        );
    }

    return null;
};

export default adminLogsError;

adminLogsError.propTypes = {
    error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};
