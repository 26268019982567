import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import './stylesheets/InputPassword.css';

const inputPassword = ({
    password = '', handleChange = () => null,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordDisplay = () => {
        const PasswordIcon = showPassword ? IoMdEyeOff : IoMdEye;
        return (
            <PasswordIcon
                className="position-absolute eye-icon"
                size={20}
                onClick={() => setShowPassword(!showPassword)}
            />
        );
    };

    return (
        <>
            <div className="position-relative d-flex">
                <input
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="geotrak-password"
                    onChange={(e) => handleChange(e.target.value)}
                    value={password}
                />
                {!password ? null : togglePasswordDisplay()}
            </div>
        </>
    );
};

export default inputPassword;

inputPassword.propTypes = {
    password: PropTypes.string,
    handleChange: PropTypes.func,
};
