import React, { useState } from 'react';
import { MdAddCircle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useGetDataSource from '../../_services/GeotrakService/hooks/useGetDataSource';
import LoaderButton from '../../utilComponents/LoaderButton';
import { ButtonPrimary } from '../../utilComponents/styledComponents';
import { ICreateProjectRecordsRequest, ProjectRecordService } from '../../_services/GeotrakService';
import { returnCurrentLastPageNumber } from '../../helpers/utilities';
import refreshTableData from '../../actions/dataRefreshAction';
import useGetDataSourceWidgets from '../../_services/GeotrakService/hooks/useGetDataSourceWidgets';
import Modal from '../Modals/Modal';
import ErrorMessage from '../../utilComponents/ErrorMessage';

const recordCreator = ({ onPageChange = () => null, recordCount = 0 }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [value, setValue] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const activeSchema = useSelector((state) => state.activeConfiguration.schema);
    const history = useHistory();
    const dispatch = useDispatch();
    const projectRecordService = new ProjectRecordService();
    const searchParameters = new URLSearchParams(history.location.search);
    const { dataSource } = useGetDataSource(searchParameters.get('data-source'));
    const { dataSourceWidgets } = useGetDataSourceWidgets(searchParameters.get('data-source'));
    const STANDARD_PAGE_SIZE = 50;

    const getIdField = () => {
        if (dataSource.searchFieldName === 'SiteId') {
            return dataSource.uniqueIdField;
        }
        return dataSource.searchFieldName;
    };

    const getMaximumIdLength = () => {
        const searchField = activeSchema.find((field) => field.key === getIdField());
        if (searchField) {
            return searchField.columnLength;
        }
        setError('The unique id field for this data source could not be found.');
        return 0;
    };

    const getInvalidRecords = () => {
        const inputList = value.split('\n');
        return inputList.filter((input) => input.length > getMaximumIdLength());
    };

    const navigateToNewRecords = () => {
        onPageChange(returnCurrentLastPageNumber(recordCount, STANDARD_PAGE_SIZE));
        dispatch(refreshTableData());
        const dataGridElement = document.querySelector('.react-grid-Canvas');
        if (dataGridElement) {
            dataGridElement.scrollTop = dataGridElement.scrollHeight;
        }
    };

    const addRecords = async () => {
        try {
            setError(null);
            setIsLoading(true);
            const invalidRecords = getInvalidRecords();
            if (invalidRecords.length < 1) {
                const request = ICreateProjectRecordsRequest.load(dataSource.id, value.split('\n'));
                await projectRecordService.createProjectRecords(request);
                setIsLoading(false);
                setIsModalOpen(false);
                setValue('');
                navigateToNewRecords();
            } else {
                setError(`The ids [${invalidRecords.join(', ')}] exceed the maximum length of ${getMaximumIdLength()}.`);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            setError(err.message || 'An error occurred saving records.');
        }
    };

    const isValid = () => {
        const inputList = value.split('\n');
        return value !== '' && inputList.every((input) => input);
    };

    if (dataSourceWidgets.find((widget) => widget.widgetType.name === 'New Record') && !isModalOpen) {
        return (
            <ButtonPrimary id="record-creator-button" onClick={() => setIsModalOpen(true)}>
                <MdAddCircle size={20} />
            </ButtonPrimary>
        );
    }

    if (isModalOpen) {
        return (
            <>
                <Modal
                    showModal
                    header="Input New Records"
                    onCancel={() => setIsModalOpen(false)}
                    component={(
                        <>
                            {dataSource.id && (
                                <h5 className="text-center">
                                    {`To create new records, enter ${getIdField()}s on their own lines below.
                                        Maximum length for ids is ${getMaximumIdLength()}.`}
                                </h5>
                            )}
                            <textarea
                                rows={5}
                                id="record-creator-input"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                placeholder={'xxxxx\nxxxxx\nxxxxx\n etc'}
                            />
                        </>
                    )}
                    footer={(
                        <>
                            <LoaderButton
                                text="Submit"
                                id="create-record"
                                loadingText="Submitting..."
                                isLoading={isLoading}
                                disabled={!isValid()}
                                onClick={addRecords}
                            />
                            {error && (
                                <div className="mt-1">
                                    <ErrorMessage id="record-creator-error" message={error} onDismiss={() => setError(null)} />
                                </div>
                            )}
                        </>
                    )}
                    displayFullScreen={false}
                />
            </>
        );
    }

    return null;
};

export default recordCreator;
