export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const DRAW_CONTROLLER_RECEIVED = 'DRAW_CONTROLLER_RECEIVED';
export const DRAW_COMPLETE_CONTROLLER_RECEIVED = 'DRAW_COMPLETE_CONTROLLER_RECEIVED';
export const SET_ACTIVE_VIEW = 'SET_ACTIVE_VIEW';
export const SET_ACTIVE_SCHEMA = 'SET_ACTIVE_SCHEMA';
export const RESET_TABLE_DATA = 'RESET_TABLE_DATA';
export const SET_TABLE_DATA_COLUMNS_FILTER = 'SET_TABLE_DATA_COLUMNS_FILTER';
export const RESET_TABLE_DATA_COLUMNS_FILTER = ' RESET_TABLE_DATA_COLUMNS_FILTER';
export const REFRESH_TABLE_DATA = 'REFRESH_TABLE_DATA';
export const SET_QUERY = 'SET_QUERY';
export const RESET_QUERY = 'RESET_QUERY';
export const SET_ID_COLLECTION = 'SET_ID_COLLECTION';
export const CLEAR_ID_COLLECTION = 'CLEAR_ID_COLLECTION';
export const TOGGLE_WIDGET = 'TOGGLE_WIDGET';
export const CLOSE_WIDGETS = 'CLOSE_WIDGETS';
export const GET_LOGIN_USER_RECEIVED = 'GET_LOGIN_USER_RECEIVED';
export const GET_ACTIVE_MODULE_RECEIVED = 'GET_ACTIVE_MODULE_RECEIVED';
export const RESET_ACTIVE_MODULE = 'RESET_ACTIVE_MODULE';
export const GET_ACTIVE_SCREEN_RECEIVED = 'GET_ACTIVE_SCREEN_RECEIVED';
export const SET_FEATURE = 'SET_FEATURE';
export const RESET_FEATURE = 'RESET_FEATURE';
export const SET_LAYER_VISIBILITY = 'SET_LAYER_VISIBILITY';
