import React from 'react';
import PropTypes from 'prop-types';
import '../stylesheets/ButtonCharcoal.css';

export const buttonCharcoal = ({ onClick = () => null, children = null }) => (
    <button
        type="button"
        className="btn btn-default text-white charcoal-button"
        onClick={onClick}
    >
        {children}
    </button>
);

buttonCharcoal.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default buttonCharcoal;
