import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import useUpdateRenderer from '../../../../_services/GeotrakService/hooks/useUpdateRenderer';
import { Renderer } from '../../../../_services/GeotrakService/Models';
import '../renderers.css';

const updateButton = ({
    renderer = new Renderer(),
    isValid = () => null,
    moduleId = null,
    roleId = null,
}) => {
    const {
        updateRenderer, error, isLoading, isSuccessful,
    } = useUpdateRenderer();

    if (!renderer.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/modules/${moduleId}/roles/${roleId}/data-sources/${renderer.dataSourceId}`} />;
    }

    return (
        <div className="renderer-toolbar renderer-button">
            {error && <div className="text-danger renderer-error" id="update-renderer-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="update-renderer-button"
                text="Update"
                loadingText="Updating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => updateRenderer(renderer)}
            />
        </div>
    );
};

export default updateButton;

updateButton.propTypes = {
    renderer: PropTypes.instanceOf(Renderer),
    isValid: PropTypes.func,
    moduleId: PropTypes.string,
    roleId: PropTypes.string,
};
