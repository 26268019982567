import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { MapContainer, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { drawControllerReceived, drawCompleteControllerReceived } from '../../actions/drawActions';
import Widget from '../Widget';
import BaseMapLayers from './BasemapLayers';
import LeafletHooks from './LeafletHooks';
import MapLayers from '../../utilComponents/MapLayers';
import '../stylesheets/MapView.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { DataSource, MapConfig } from '../../_services/GeotrakService/Models';
import CoordinatePair from '../../helpers/spatialUtils/CoordinatePair';

const MIN_ZOOM = 3;
const MAX_ZOOM = 18;
const SELECTED_ZOOM = 17;

const leafletMap = ({ mapConfig = new MapConfig(), dataSource = new DataSource() }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [mapPosition, setMapPosition] = useState(mapConfig.center);
    const [mapRef, setMapRef] = useState(null);
    const widgets = useSelector((state) => state.widgets);
    const {
        center: {
            maximumLatitude, maximumLongitude, minimumLatitude, minimumLongitude,
        },
        zoom,
    } = mapConfig;
    const mapBounds = [
        [maximumLatitude, maximumLongitude],
        [minimumLatitude, minimumLongitude],
    ];

    const onMapReposition = (coordinates) => {
        const newLocation = {
            lat: coordinates.latitude,
            lng: coordinates.longitude,
        };
        mapRef.flyTo(newLocation, SELECTED_ZOOM);
        setMapPosition(coordinates);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search);
        const latitude = searchParams.get('latitude');
        const longitude = searchParams.get('longitude');
        if (latitude && longitude && mapRef) {
            onMapReposition(new CoordinatePair(latitude, longitude));
            const stripCoordinates = history.location.search.replace(`&latitude=${latitude}`, '').replace(`&longitude=${longitude}`, '');
            const path = `${history.location.pathname}${stripCoordinates}`;
            history.push(path);
        }
    }, [mapRef]);

    return (
        <div>
            <MapContainer
                attributionControl
                id="leaflet-map-geotrak"
                center={[mapPosition.latitude, mapPosition.longitude]}
                dragging
                doubleClickZoom
                inertia={false}
                maxBounds={mapBounds}
                maxZoom={MAX_ZOOM}
                minZoom={MIN_ZOOM}
                zoom={zoom}
                zoomSnap={1}
                zoomDelta={1}
                zoomControl
                ref={setMapRef}
            >
                {mapRef && (
                    <LeafletHooks
                        widgets={widgets}
                        onMapReposition={onMapReposition}
                    />
                )}
                {mapRef && (<BaseMapLayers />)}
                <MapLayers mapConfig={mapConfig} mapRef={mapRef} onMapReposition={onMapReposition} />
                <FeatureGroup>
                    <EditControl
                        position="topleft"
                        edit={{ edit: true, remove: true }}
                        onMounted={(f) => dispatch(drawControllerReceived(f))}
                        onCreated={(event) => dispatch(drawCompleteControllerReceived(event))}
                    />
                </FeatureGroup>
            </MapContainer>
            <Widget
                dataSource={dataSource}
                onMapReposition={onMapReposition}
                mapPosition={mapPosition}
            />
        </div>
    );
};

export default leafletMap;

leafletMap.propTypes = {
    mapConfig: PropTypes.instanceOf(Object),
    drawCompleteControllerReceived: PropTypes.func,
    drawControllerReceived: PropTypes.func,
    dataSource: PropTypes.instanceOf(DataSource),
};
