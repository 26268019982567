import React, { Component } from 'react';
import {
    MdEdit, MdLock, MdAdd, MdLockOpen
} from 'react-icons/md';
import uuidv4 from 'uuid/v4';
import ButtonPrimary from '../../utilComponents/styledComponents/ButtonPrimary';
import { ButtonContainer, ButtonSuccess } from '../../utilComponents/styledComponents';
import LoadingFullScreen from '../../utilComponents/LoadingFullScreen';
import SuccessMessage from '../../utilComponents/SuccessMessage';
import DataSourceSelector from '../../components/DataSourceSelector';
import { alphabetize, debounce } from '../../helpers/utilities';
import { FieldService, ViewService, DatabaseService } from '../../_services/GeotrakService';
import IGetViewRequest from '../../_services/GeotrakService/interfaces/IGetViewRequest';
import Delete from './Fields/Actions/DeleteButton';
import Clear from './Fields/Actions/ClearButton';
import Modal from '../../components/Modals/Modal';
import '../stylesheets/AdminFields.css';
import AdminContainer from './AdminContainer';
import IGetFieldsRequest from '../../_services/GeotrakService/interfaces/IGetFieldsRequest';
import Breadcrumbs from '../../utilComponents/Breadcrumbs';

const DEBOUNCE_WAIT_TIME = 1000;

const addUniqueIdToFields = (originalFields, schema) => {
    const fields = [...originalFields];
    const fieldsLabels = [...new Set(fields.map((field) => field.name))];
    const fieldsHasSiteId = fieldsLabels.some((field) => field === 'SiteId');
    const fieldsHasObjectId = fieldsLabels.some((field) => field === 'OBJECTID');

    if (Object.prototype.hasOwnProperty.call(schema, 'SiteId') && !fieldsHasSiteId) {
        fieldsLabels.push('SiteId');
        fields.unshift({
            name: 'SiteId',
            alias: 'Site ID',
            isEditable: false,
        });
    }
    if (Object.prototype.hasOwnProperty.call(schema, 'OBJECTID') && !fieldsHasObjectId) {
        fieldsLabels.push('OBJECTID');
        fields.unshift({
            name: 'OBJECTID',
            alias: 'OBJECTID',
            isEditable: false,
        });
    }
    return fields;
};

export default class AdminFields extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: [],
            originalFields: [],
            selectedField: null,
            possibleFields: [],
            schema: {},
            showAddModal: false,
            showImportModal: false,
            showEditModal: false,
            form: {},
            success: '',
            error: '',
            isLoading: false,
            selectedDataSource: null,
            selectedImportDataSource: null,
            inValidFieldName: '',
            showInvalidField: false,
            isTableSorted: true,
            searchTerm: '',
            isImportByDefault: true,
        };

        this.FieldService = new FieldService();
        this.viewService = new ViewService();
        this.databaseService = new DatabaseService();
    }

    getFieldsByDefaultOrder = (dataSource) => this.FieldService.getFields(dataSource.id)
        .then((fields) => fields).catch((err) => {
            this.setState({ error: err.message || err });
        });

    getFieldsByFieldOrder = (dataSource) => {
        const request = IGetViewRequest.load(dataSource.id);
        return this.viewService.getSystemView(request).then((response) => response.fields).catch((err) => {
            this.setState({ error: err.message || err });
        });
    };

    getFields = async (dataSource) => {
        if (dataSource) {
            try {
                const fields = await this.getFieldsByDefaultOrder(dataSource);
                const { tableReference, databaseReference } = dataSource;
                const request = IGetFieldsRequest.load(databaseReference, tableReference);
                const schema = await this.databaseService.getFields(request);
                const dataSourceFieldsWithId = addUniqueIdToFields(fields, schema);
                const fieldsLabels = dataSourceFieldsWithId.map((field) => field.name);
                const possibleFieldsToBeAdded = Object.keys(schema).filter(
                    (field) => !fieldsLabels.includes(field)
                ).map((field) => ({ name: field }));
                this.setState({
                    selectedDataSource: dataSource,
                    fields: dataSourceFieldsWithId,
                    originalFields: [...fields],
                    possibleFields: possibleFieldsToBeAdded,
                    isLoading: false,
                    schema,
                });
            } catch (err) {
                this.setState({ error: err.message || err, success: '', isLoading: false });
            }
        } else {
            this.setState({
                fields: [],
                possibleFields: [],
                schema: {},
                selectedDataSource: null,
                success: '',
                error: '',
            });
        }
    };

    updateFields = () => {
        const {
            fields, originalFields, selectedDataSource, selectedDataSource: { app, role, name },
        } = this.state;
        const existingFields = fields
            .filter((field) => field.dataSourceId === selectedDataSource.id && this.isFieldUpdated(field))
            .map((field) => {
                const newField = { ...field };
                newField.id = originalFields.find((of) => of.name === newField.name).id;
                newField.App = app;
                newField.Role = role;
                newField.LayerID = name;
                return newField;
            });
        const invalidField = existingFields.find((field) => !field.id);
        if (invalidField) {
            this.setState({
                inValidFieldName: invalidField.name,
                showInvalidField: true,
                fields: [...originalFields],
            });
            return null;
        }
        this.setState({ showInvalidField: false });
        return existingFields;
    };

    createFields = () => {
        const { fields, selectedDataSource } = this.state;
        const createdFields = [];
        fields.forEach((field) => {
            const fieldCopy = { ...field };
            if (fieldCopy.dataSourceId !== selectedDataSource.id) {
                delete fieldCopy.id;
                fieldCopy.dataSourceId = selectedDataSource.id;
                createdFields.push(fieldCopy);
            }
        });
        return createdFields;
    };

    mergeFields = (importDataSourceFields) => {
        const { fields } = this.state;
        const selectedDataSourceFields = [...fields];
        const dataSourceFieldNames = selectedDataSourceFields.map((v) => v.name);
        const dedupedFieldNames = Array.from(new Set(dataSourceFieldNames));
        const mergedFields = [];
        importDataSourceFields.forEach((importField) => {
            const duplicateFieldName = dedupedFieldNames
                .find((dataSourceFieldName) => dataSourceFieldName === importField.name);
            if (!duplicateFieldName) {
                mergedFields.push(importField);
            }
        });
        return mergedFields;
    };

    importFields = async () => {
        const {
            schema, fields, isImportByDefault, selectedImportDataSource,
        } = this.state;
        if (!selectedImportDataSource) {
            return;
        }
        const selectedDataSourceFields = [...fields];
        const importer = isImportByDefault ? this.getFieldsByDefaultOrder : this.getFieldsByFieldOrder;
        try {
            const importDataSourceFields = await importer(selectedImportDataSource);
            const schemaFields = Object.keys(schema).filter(
                (schemaField) => !fields.find((field) => field.name === schemaField)
            );
            const mergedFields = this.mergeFields(importDataSourceFields);
            const totalUserFields = mergedFields.concat(selectedDataSourceFields);
            const fieldsLabels = totalUserFields.map((field) => field.name);
            const possibleFields = schemaFields.filter((field) => !fieldsLabels.includes(field))
                .map((field) => ({ name: field }));
            this.setState({
                possibleFields,
                fields: totalUserFields,
                showImportModal: false,
                success: '',
                error: '',
                isLoading: false,
                selectedImportDataSource: null,
                isImportByDefault: true,
            });
        } catch (err) {
            this.setState({ error: err.message || err, success: '', isLoading: false });
        }
    };

  handleFormChange = (e) => {
      const { form } = this.state;
      const updatedForm = { ...form };
      const property = e.target.id;
      updatedForm[property] = e.target.value;
      this.setState({ form: updatedForm });
  };

  onFieldDelete = (fieldName) => {
      const { fields, possibleFields } = this.state;
      const oldField = fields.find((field) => field.name === fieldName);
      const newFields = fields.filter((field) => field.name !== fieldName);

      this.setState({
          fields: newFields,
          possibleFields: [oldField].concat(possibleFields),
      });
  };

    onClearFields = () => {
        const { fields, possibleFields, schema } = this.state;
        const resetFields = addUniqueIdToFields([], schema);
        const newPossibleFields = fields.concat(possibleFields);

        this.setState({
            fields: resetFields,
            possibleFields: newPossibleFields,
            originalFields: [],
        });
    };

  addFieldToList = (fieldName) => {
      const { fields, possibleFields } = this.state;
      const newField = {
          name: fieldName,
          alias: fieldName,
          isEditable: true,
      };

      this.setState({
          fields: fields.concat(newField),
          possibleFields: possibleFields.filter((field) => field.name !== fieldName),
          success: '',
          error: '',
      });
  };

  saveEditedField = () => {
      const { selectedField, form, fields } = this.state;
      const newField = { ...selectedField, ...form };
      const newFields = fields.map((field) => {
          if (field.name === newField.name) {
              return newField;
          }
          return field;
      });

      this.setState({
          form: {}, showEditModal: false, fields: newFields, success: '', error: '',
      });
  };

  isFieldUpdated = (updatedField) => {
      const { originalFields } = this.state;
      const originalField = originalFields.filter((field) => field.name === updatedField.name)[0];
      return Object.keys(originalField).find((key) => originalField[key] !== updatedField[key]);
  };

  saveToDatabase = async () => {
      const { selectedDataSource } = this.state;
      const fieldsToUpdate = this.updateFields();
      const fieldsToCreate = this.createFields();
      const request = IGetViewRequest.load(selectedDataSource.id);

      try {
          this.setState({ isLoading: true });
          if (fieldsToCreate.length) {
              const view = await this.viewService.getSystemView(request);

              const payload = {
                  fields: fieldsToCreate,
                  query: view.query,
              };

              await this.FieldService.addFields(selectedDataSource.id, payload);
          }
          if (fieldsToUpdate.length) {
              const shortenedFields = fieldsToUpdate.map((editField) => ({
                  id: editField.id,
                  name: editField.name,
                  alias: editField.alias,
                  isEditable: editField.isEditable,
                  dataSourceId: editField.dataSourceId,
              }));
              await this.FieldService.updateFields(selectedDataSource.id, shortenedFields);
          }
          this.setState({
              success: 'Operation Successful', error: '', isLoading: false, fields: [],
          });
          const SUCCESS_MESSAGE_DELAY = 10000;
          setTimeout(() => {
              this.setState({ success: '' });
          }, SUCCESS_MESSAGE_DELAY);
          await this.getFields(selectedDataSource);
      } catch (err) {
          this.setState({ error: err.message || err, success: '', isLoading: false });
      }
  };

  renderAddFields = (fields = []) => {
      const { isTableSorted, searchTerm } = this.state;
      let sortedFieldList;
      if (!isTableSorted) {
          sortedFieldList = alphabetize([...fields], 'name');
      } else {
          sortedFieldList = fields;
      }
      return sortedFieldList.filter(
          (field) => field.name.toUpperCase().includes(searchTerm.toUpperCase())
      ).map((field) => (
          <div className="list-group-item list-group-item-action" key={uuidv4()}>
              <MdAdd
                  size={20}
                  className="admin-fields-left-button admin-fields-add-field"
                  onClick={() => {
                      this.addFieldToList(field.name);
                  }}
              />
              <span>{field.name}</span>
          </div>
      ));
  };

renderFields = () => {
    const { fields, selectedDataSource } = this.state;
    return fields.map((field, index) => (
        <div
            id={`${field.name}-${index}`}
            key={uuidv4()}
            className={`list-group-item list-group-item-action d-flex align-items-center
            ${field.dataSourceId !== selectedDataSource.id && 'bg-light-subtle'}`}
        >
            <Delete field={field} selectedDataSourceId={selectedDataSource.id} onFieldDelete={this.onFieldDelete} />
            <MdEdit
                size={20}
                className="admin-fields-left-button"
                onClick={() => {
                    this.setState({
                        selectedField: field,
                        showEditModal: true,
                    });
                }}
            />
            <div className="nav-justified">
                <strong>{field.alias}</strong>
                {field.dataSourceId !== selectedDataSource.id && (<span className="text-white badge bg-info mx-2"><i>New</i></span>)}
                {field.isEditable ? (
                    <MdLockOpen
                        onClick={() => this.changeLockOnField(field.name)}
                        size={20}
                        className="admin-fields-right-button"
                    />
                ) : (
                    <MdLock
                        onClick={() => this.changeLockOnField(field.name)}
                        size={20}
                        className="admin-fields-right-button"
                    />
                )}
            </div>
        </div>
    ));
};

  changeLockOnField = (fieldName) => {
      const { fields } = this.state;
      const fieldsCopy = fields.map((field) => {
          const newField = { ...field };
          if (field.name === fieldName) {
              newField.isEditable = !field.isEditable;
              return newField;
          }
          return field;
      });
      this.setState({ fields: fieldsCopy });
  };

  renderInvalidFieldWarning = () => {
      const { showInvalidField, inValidFieldName } = this.state;
      if (showInvalidField) {
          return (
              <div>
                  <Modal
                      showModal={showInvalidField}
                      header="Field is not available to upload"
                      onCancel={() => {
                          this.setState({
                              showInvalidField: false,
                          });
                      }}
                      component={(
                          <div className="text-center">
                              <span> Field </span>
                              <span>{inValidFieldName}</span>
                              <span>
                                  is not in the database, you need to add without editing
                                  and save to the database before you can edit it
                              </span>
                              <br />
                          </div>
                      )}
                  />
              </div>
          );
      }
      return null;
  };

  getSortMethodClass = (isTableSorted) => {
      if (isTableSorted) {
          return 'active';
      }
      return 'opacity-50';
  };

  renderAddModal = () => {
      const {
          possibleFields, searchTerm, showAddModal, isTableSorted,
      } = this.state;
      return (
          <Modal
              showModal={showAddModal}
              header="Add Fields"
              displayFullScreen={false}
              onCancel={() => this.setState({ showAddModal: false })}
              component={(
                  <div id="add-fields-modal">
                      <ButtonContainer>
                          <div data-toggle="buttons" className="btn-group" role="group" aria-label="radio toggle button group">
                              <label className={`btn btn-outline-primary ${this.getSortMethodClass(isTableSorted)}`} htmlFor="table-order">
                                  Sort by Table Order
                                  <input
                                      id="table-order"
                                      type="radio"
                                      className="btn-check"
                                      autoComplete="off"
                                      checked={isTableSorted}
                                      onChange={() => this.setState({ isTableSorted: true })}
                                  />
                              </label>
                              <label className={`btn btn-outline-primary ${this.getSortMethodClass(!isTableSorted)}`} htmlFor="alphabet-order">
                                  Sort Alphabetically
                                  <input
                                      id="alphabet-order"
                                      type="radio"
                                      className="btn-check"
                                      autoComplete="off"
                                      checked={!isTableSorted}
                                      onChange={() => this.setState({ isTableSorted: false })}
                                  />
                              </label>
                          </div>
                      </ButtonContainer>
                      <input
                          type="text"
                          placeholder="Search Fields"
                          className="admin-fields-search"
                          value={searchTerm}
                          onChange={(e) => this.setState({ searchTerm: e.target.value })}
                      />
                      <div className="list-group">
                          {this.renderAddFields(possibleFields)}
                      </div>
                  </div>
              )}
          />
      );
  };

  renderEditModal = () => {
      const { selectedField, showEditModal, form } = this.state;
      return (
          <Modal
              showModal={showEditModal}
              header={`Edit Field (${selectedField.name})`}
              onCancel={() => {
                  this.setState({
                      showEditModal: false,
                      form: {},
                      selectedField: null,
                  });
              }}
              component={(
                  <div className="mb-3">
                      <label htmlFor="alias" className="form-label">
                          Field Alias
                      </label>
                      <input
                          type="text"
                          id="alias"
                          onChange={this.handleFormChange}
                          value={
                              Object.prototype.hasOwnProperty.call(form, 'alias')
                                  ? form.alias
                                  : selectedField.alias
                          }
                      />
                  </div>
              )}
              footer={<ButtonPrimary onClick={this.saveEditedField}>Save</ButtonPrimary>}
          />
      );
  };

  renderImportModal = () => {
      const { selectedImportDataSource, showImportModal } = this.state;
      return (
          <Modal
              showModal={showImportModal}
              header="Import Fields"
              displayFullScreen={false}
              onCancel={() => {
                  this.setState({
                      selectedImportDataSource: null,
                      showImportModal: false,
                      isImportByDefault: true,
                  });
              }}
              component={(
                  <div id="import-fields-modal">
                      <DataSourceSelector onDataSourceSelected={(dataSource) => {
                          this.setState({ selectedImportDataSource: dataSource });
                      }}
                      />
                      {selectedImportDataSource && (
                          <div className="import-fields">
                              <div className="form-check mb-3">
                                  <label htmlFor="import-by-default-order" className="form-check-label">
                                      <input
                                          id="import-by-default-order"
                                          name="importMode"
                                          type="radio"
                                          className="form-check-input"
                                          defaultChecked
                                          onChange={
                                              () => {
                                                  this.setState({
                                                      selectedImportDataSource, isImportByDefault: true,
                                                  });
                                              }
                                          }
                                      />
                                      Import with default order
                                  </label>
                              </div>
                              <div className="form-check mb-3">
                                  <label htmlFor="import-by-field-order" className="form-check-label">
                                      <input
                                          id="import-by-field-order"
                                          name="importMode"
                                          className="form-check-input"
                                          type="radio"
                                          onChange={
                                              () => {
                                                  this.setState({
                                                      selectedImportDataSource, isImportByDefault: false,
                                                  });
                                              }
                                          }
                                      />
                                      Import with field order
                                  </label>
                              </div>
                          </div>
                      )}
                  </div>
              )}
              footer={(
                  <ButtonPrimary
                      className="btn-import"
                      disabled={!selectedImportDataSource}
                      onClick={() => {
                          this.setState({ isLoading: true });
                          this.importFields();
                      }}
                  >
                      Import

                  </ButtonPrimary>
              )}
          />
      );
  };

  render() {
      const {
          isLoading, selectedDataSource, error, success, selectedField, showInvalidField,
      } = this.state;
      return (
          <div id="admin-fields">
              {isLoading && <LoadingFullScreen />}
              <Breadcrumbs />
              <AdminContainer title="Edit Fields">
                  <div className="row">
                      <div className="col-md-4">
                          <DataSourceSelector onDataSourceSelected={this.getFields} />
                      </div>
                      <div className="col-md-8">
                          {selectedDataSource && (
                              <div id="edit-fields" className="list-group">
                                  <ButtonContainer
                                      error={error}
                                      onErrorDismiss={() => this.setState({ error: null })}
                                  >
                                      <Clear
                                          className="btn-clear-fields"
                                          dataSourceId={selectedDataSource.id}
                                          onClearFields={this.onClearFields}
                                      />
                                      <ButtonSuccess
                                          className="btn-save-to-database-fields"
                                          onClick={debounce(this.saveToDatabase, DEBOUNCE_WAIT_TIME)}
                                      >
                                          Save to Database
                                          {showInvalidField && this.renderInvalidFieldWarning()}
                                      </ButtonSuccess>
                                      <ButtonPrimary
                                          className="btn-open-import-fields"
                                          onClick={() => this.setState({ showImportModal: true })}
                                      >
                                          Import Fields
                                      </ButtonPrimary>
                                      <ButtonPrimary
                                          className="btn-open-add-new-fields"
                                          onClick={() => this.setState({ showAddModal: true })}
                                      >
                                          Add New Field
                                      </ButtonPrimary>
                                  </ButtonContainer>
                                  {success && <SuccessMessage message={success} onDismiss={() => this.setState({ success: '' })} />}
                                  <div id="selected-datasource-fields" className="overflow-y-auto fields mb-3">
                                      {this.renderFields()}
                                  </div>
                              </div>
                          )}
                      </div>
                  </div>
              </AdminContainer>
              {this.renderAddModal()}
              {selectedField && this.renderEditModal()}
              {selectedDataSource && this.renderImportModal()}
          </div>
      );
  }
}
