import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LoaderButton from '../../../utilComponents/LoaderButton';
import { CheckRequestAdminService } from '../../../_services/CheckRequestService';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import { RequestForm, RequestType } from '../../../_services/CheckRequestService/Models';
import Modal from '../../../components/Modals/Modal';
import SuccessMessage from '../../../utilComponents/SuccessMessage';
import AdminContainer from '../AdminContainer';

const checkRequestAdminService = new CheckRequestAdminService();

const requestTypePicker = ({
    jobProperties = {
        requestFormId: null,
    },
    nextStep = () => null,
}) => {
    const { project, requestFormId } = jobProperties;
    const [requestTypes, setRequestTypes] = useState([]);
    const [selectedRequestType, setRequestType] = useState('');
    const [requestTypeName, setRequestTypeName] = useState('');
    const [requestTypeAlias, setRequestTypeAlias] = useState('');
    const [minimumRequiredDocuments, setMinimumRequiredDocuments] = useState(0);
    const [isWorking, setWorking] = useState(false);
    const [showNewRequestType, setNewRequestType] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const getRequestTypes = () => {
        checkRequestAdminService.getRequestTypes().then((requestTypesResult) => {
            setRequestTypes(requestTypesResult);
        }).catch((err) => {
            setError(`Error Getting Request Types. ${err.message || err}`);
        });
    };

    const getRequestForm = () => {
        checkRequestAdminService.getRequestForm(requestFormId).then((requestForm) => {
            setMinimumRequiredDocuments(requestForm.requiredDocuments);
            setRequestType(requestForm.requestType.name);
        }).catch((err) => {
            setError(`Error Getting Request Form. ${err.message || err}`);
        });
    };

    const createRequestForm = (newRequestForm) => {
        checkRequestAdminService.createRequestForm(newRequestForm).then((requestForm) => {
            setWorking(false);
            nextStep({ requestFormId: requestForm.id });
        }).catch((err) => {
            setWorking(false);
            setError(`Error Creating Request Form. ${err.message || err}`);
        });
    };

    const updateRequestForm = (newRequestForm) => {
        checkRequestAdminService.updateRequestForm(newRequestForm).then(() => {
            setWorking(false);
            nextStep();
        }).catch((err) => {
            setWorking(false);
            setError(`Error Updating Request Form. ${err.message || err}`);
        });
    };

    const createRequestType = () => {
        const newRequestType = new RequestType(requestTypeName, requestTypeAlias);
        checkRequestAdminService.createRequestType(newRequestType).then(() => {
            setNewRequestType(false);
            setRequestTypeName('');
            setRequestTypeAlias('');
            getRequestTypes();
            setSuccess('Request Type Created Successfully');
        }).catch((err) => {
            setError(`Error Creating Request Type. ${err.message || err}`);
        });
    };

    const handleRequestForm = () => {
        const requestType = requestTypes.find((type) => type.name === selectedRequestType);
        const newRequestForm = new RequestForm(project, requestType, minimumRequiredDocuments, requestFormId);
        setWorking(true);
        if (requestFormId) {
            updateRequestForm(newRequestForm);
        } else {
            createRequestForm(newRequestForm);
        }
    };

    const isRequestTypeValid = () => requestTypes.filter(
        (requestType) => requestType.name === selectedRequestType
    ).length > 0;

    const isRequestTypeExists = () => requestTypes.some(
        (type) => requestTypeName.trim().toLowerCase() === type.name.toLowerCase()
        && requestTypeAlias.trim().toLowerCase() === type.alias.toLowerCase()
    );

    const isNewRequestTypeValid = () => requestTypeName !== '' && requestTypeAlias !== '';

    useEffect(() => {
        getRequestTypes();
        if (requestFormId) {
            getRequestForm();
        }
    }, []);

    return (
        <AdminContainer title="Request Type">
            <form>
                <div className="mb-3">
                    <label htmlFor="request-type" className="form-label">
                        Request Type
                        {' '}
                        <span className="text-danger">*</span>
                    </label>
                    <select
                        value={selectedRequestType}
                        id="request-type"
                        onChange={(e) => setRequestType(e.target.value)}
                    >
                        <option disabled value="">--Select a request type--</option>
                        {requestTypes.map((type) => (
                            <option key={type.name} value={type.name}>{type.alias}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="required-documents" className="form-label">
                        Minimum Required Documents
                    </label>
                    <input
                        id="required-documents"
                        type="number"
                        min={0}
                        value={minimumRequiredDocuments}
                        onChange={(e) => setMinimumRequiredDocuments(e.target.value)}
                    />
                </div>
                <ButtonContainer
                    error={error}
                    onErrorDismiss={() => setError(null)}
                >
                    <LoaderButton
                        text="Save"
                        isLoading={isWorking}
                        disabled={!isRequestTypeValid()}
                        onClick={handleRequestForm}
                    />
                    {!requestFormId
                    && (
                        <ButtonPrimary onClick={() => setNewRequestType(true)}>
                            New Request Type
                        </ButtonPrimary>
                    )}
                </ButtonContainer>
                {success && (
                    <SuccessMessage
                        message={success}
                        onDismiss={() => setSuccess(null)}
                    />
                )}
            </form>
            <Modal
                showModal={showNewRequestType}
                header="New Request Type"
                onCancel={() => setNewRequestType(false)}
                component={(
                    <form>
                        <div className="mb-3">
                            <label htmlFor="request-type-name" className="form-label">
                                Name
                                {' '}
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                value={requestTypeName}
                                id="request-type-name"
                                onChange={(e) => setRequestTypeName(e.target.value)}
                                placeholder="Name"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="request-type-alias" className="form-label">
                                Alias
                                {' '}
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="request-type-alias"
                                value={requestTypeAlias}
                                onChange={(e) => setRequestTypeAlias(e.target.value)}
                                placeholder="Alias"
                            />
                        </div>
                        {requestTypeName && requestTypeAlias && isRequestTypeExists() && (
                            <span className="text-warning">Request type already exists</span>
                        )}
                    </form>
                )}
                footer={(
                    <LoaderButton
                        text="Save"
                        disabled={!isNewRequestTypeValid() || isRequestTypeExists()}
                        onClick={createRequestType}
                    />
                )}
            />
        </AdminContainer>
    );
};

export default requestTypePicker;

requestTypePicker.propTypes = {
    jobProperties: PropTypes.instanceOf(Object),
    nextStep: PropTypes.func,
};
