import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Role } from '../../../../_services/GeotrakService/Models';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import useUpdateRole from '../../../../_services/GeotrakService/hooks/useUpdateRole';
import '../roles.css';

const updateButton = ({
    role = new Role(),
    isValid = () => null,
    moduleId = null,
}) => {
    const {
        updateRole, error, isLoading, isSuccessful,
    } = useUpdateRole();

    if (!role.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/modules/${moduleId}/roles`} />;
    }

    return (
        <div className="role-toolbar role-button">
            {error && <div className="text-danger role-error" id="update-role-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="update-role-button"
                text="Update"
                loadingText="Updating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => updateRole(role)}
            />
        </div>
    );
};

export default updateButton;

updateButton.propTypes = {
    role: PropTypes.instanceOf(Role),
    isValid: PropTypes.func,
    moduleId: PropTypes.string,
};
