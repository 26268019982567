import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import { User } from '../../../../_services/UserService/models';
import useUpdateUser from '../../../../_services/UserService/hooks/useUpdateUser';
import '../users.css';

const reactivateButton = ({ user = new User(), isValid = () => null }) => {
    const {
        updateUser, error, isLoading, isSuccessful,
    } = useUpdateUser();

    const reactivateUser = async () => {
        user.toggleActivation();
        updateUser(user);
    };

    if (!user.isDeleted && !isSuccessful) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/users" />;
    }

    return (
        <div className="user-toolbar user-button">
            {error && <div className="text-danger user-error" id="reactivate-user-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="reactivate-user-button"
                text="Reactivate User"
                loadingText="Reactivating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={reactivateUser}
            />
        </div>
    );
};

export default reactivateButton;

reactivateButton.propTypes = {
    user: PropTypes.instanceOf(User),
    isValid: PropTypes.func,
};
