import React from 'react';
import PropTypes from 'prop-types';
import useGetUnits from '../_services/GeotrakService/hooks/useGetUnits';
import Loading from './LoaderSpinner';

const selectUnit = ({ onSelected = () => null }) => {
    const [units, error, isLoading] = useGetUnits();

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <div className="text-danger" id="select-unit-error">An error occurred while getting units</div>;
    }

    return (
        <select id="select-unit" defaultValue="--Select a unit--" onChange={(e) => onSelected(e.target.value)}>
            <option disabled value="--Select a unit--">--Select a unit--</option>
            {units.map((unit) => <option id="select-unit-option" key={unit.id} value={unit.name}>{unit.name}</option>)}
        </select>
    );
};

export default selectUnit;

selectUnit.propTypes = {
    onSelected: PropTypes.func,
};
