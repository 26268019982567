/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserType } from '../../types';
import { UserService } from '../../_services';
import withCurrentUser from '../hocs/withCurrentUser';
import { LOGIN_URI } from '../../helpers/routing';
import Loading from '../LoaderSpinner';

const auth = new UserService();

const authorizedComponent = ({
    component: Component, user = {}, isAuthorized, componentProps,
}) => {
    if (!isAuthorized) {
        return <Loading />;
    }

    if (user || isAuthorized) {
        return <Component {...componentProps} />;
    }

    return <Redirect to="/unauthorized" />;
};

const authorizedPrivateRoute = ({
    component: Component,
    user = {},
    checkAuthorized = () => true,
    ...rest
}) => {
    let renderFunction;
    const isAuthenticated = auth.isAuthenticated();
    const AuthorizedComponent = authorizedComponent;
    if (isAuthenticated) {
        const isAuthorized = checkAuthorized(rest);
        renderFunction = (otherProps) => (
            <AuthorizedComponent
                isAuthorized={isAuthorized}
                user={user}
                component={Component}
                componentProps={otherProps}
            />
        );
    } else {
        renderFunction = () => (<Redirect to={LOGIN_URI} />);
    }
    return (
        <Route
            {...rest}
            render={renderFunction}
        />
    );
};

export default withCurrentUser(authorizedPrivateRoute);

authorizedComponent.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.func,
        PropTypes.element,
        PropTypes.node,
    ]).isRequired,
    user: UserType,
    isAuthorized: PropTypes.bool.isRequired,
    // component props are different per rendered subcomponent.
    // Cannot capture this polymorphism with prop types.
    // eslint-disable-next-line react/forbid-prop-types
    componentProps: PropTypes.object.isRequired,
};

authorizedPrivateRoute.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.func,
        PropTypes.element,
        PropTypes.node,
    ]),
    checkAuthorized: PropTypes.func,
    user: UserType,
};
