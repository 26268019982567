import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import useDeleteProject from '../../../../_services/GeotrakService/hooks/useDeleteProject';
import { Project } from '../../../../_services/GeotrakService/Models';
import '../projects.css';

const deleteButton = ({ project = new Project() }) => {
    const {
        deleteProject, error, isLoading, isSuccessful,
    } = useDeleteProject();

    if (!project.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/projects" />;
    }

    return (
        <div className="project-toolbar project-button">
            {error && <div className="text-danger project-error" id="delete-project-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                danger
                id="delete-project-button"
                text="Delete"
                loadingText="Deleting..."
                isLoading={isLoading}
                onClick={() => deleteProject(project)}
            />
        </div>
    );
};

export default deleteButton;

deleteButton.propTypes = {
    project: PropTypes.instanceOf(Project),
};
