import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdDelete } from 'react-icons/md';
import '../fields.css';
import { FieldService } from '../../../../_services/GeotrakService';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import Modal from '../../../../components/Modals/Modal';

const deleteButton = ({ field = {}, selectedDataSourceId, onFieldDelete = () => null }) => {
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const fieldService = new FieldService();

    const deleteField = async () => {
        try {
            setIsLoading(true);
            if (field.id && selectedDataSourceId === field.dataSourceId) {
                await fieldService.deleteField(field.id);
            }
            onFieldDelete(field.name);
            setIsLoading(false);
            setShowConfirmationPopup(false);
        } catch (err) {
            setError(err);
            setIsLoading(false);
        }
    };

    if (showConfirmationPopup) {
        return (
            <>
                <Modal
                    showModal
                    header="Delete Field"
                    onCancel={() => setShowConfirmationPopup(false)}
                    component={
                        <h5 className="text-center">{`Are you sure you want to delete '${field.alias}'?`}</h5>
                    }
                    footer={(
                        <LoaderButton
                            danger
                            id="confirm-delete-field-button"
                            text="Delete"
                            loadingText="Deleting..."
                            isLoading={isLoading}
                            onClick={() => deleteField()}
                        />
                    )}
                    displayFullScreen={false}
                />
                {error && (
                    <div
                        className="text-center text-danger"
                        id="delete-field-error"
                    >
                        {`ERROR: ${error.message || 'An error occurred.'}`}
                    </div>
                )}
            </>
        );
    }

    return (
        <MdDelete id="delete-field-button" className="start-field-delete-button" size={20} onClick={() => setShowConfirmationPopup(true)} />
    );
};

export default deleteButton;

deleteButton.propTypes = {
    field: PropTypes.instanceOf(Object),
    onFieldDelete: PropTypes.func,
};
