import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import useUpdateProject from '../../../../_services/GeotrakService/hooks/useUpdateProject';
import { Project } from '../../../../_services/GeotrakService/Models';
import '../projects.css';

const updateButton = ({ project = new Project(), isValid = () => null }) => {
    const {
        updateProject, error, isLoading, isSuccessful,
    } = useUpdateProject();

    if (!project.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/projects" />;
    }

    return (
        <div className="project-toolbar project-button">
            {error && <div className="text-danger project-error" id="update-project-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="update-project-button"
                text="Update"
                loadingText="Updating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => updateProject(project)}
            />
        </div>
    );
};

export default updateButton;

updateButton.propTypes = {
    project: PropTypes.instanceOf(Project),
    isValid: PropTypes.func,
};
