import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdAddCircleOutline } from 'react-icons/md';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import './views.css';
import { alphabetize } from '../../../helpers/utilities';
import Modal from '../../../components/Modals/Modal';

const fieldAdder = ({ fields = [], addField = () => null }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isTableSorted, setIsTableSorted] = useState(true);
    const [fieldList, setFieldList] = useState(fields);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (!isTableSorted) {
            setFieldList(alphabetize([...fields], 'name'));
        } else {
            setFieldList(fields);
        }
    }, [fields, isTableSorted]);

    return (
        <>
            <ButtonPrimary onClick={() => setIsOpen(true)}>
                Add Fields
            </ButtonPrimary>
            <Modal
                showModal={isOpen}
                header="Add Fields"
                onCancel={() => setIsOpen(false)}
                component={(
                    <div className="col-md-12">
                        <ButtonContainer>
                            <div data-toggle="buttons" className="btn-group" role="group">
                                <label
                                    className={`field-adder btn btn-primary ${isTableSorted ? 'active' : ''}`}
                                    htmlFor="table-order"
                                >
                                    <input
                                        type="radio"
                                        autoComplete="off"
                                        value="tableOrder"
                                        id="table-order"
                                        className="btn-check"
                                        checked={isTableSorted}
                                        onChange={() => setIsTableSorted(true)}
                                    />
                                    Sort by Table Order
                                </label>
                                <label
                                    className={`field-adder btn btn-primary ${!isTableSorted ? 'active' : ''}`}
                                    htmlFor="alphabet-order"
                                >
                                    <input
                                        type="radio"
                                        autoComplete="off"
                                        className="btn-check"
                                        value="alphabetOrder"
                                        id="alphabet-order"
                                        checked={!isTableSorted}
                                        onChange={() => setIsTableSorted(false)}
                                    />
                                    Sort Alphabetically
                                </label>
                            </div>
                        </ButtonContainer>
                        <input
                            type="text"
                            placeholder="Search Fields"
                            className="view-field-search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="list-group">
                            {fieldList.filter(
                                (field) => field.name.toUpperCase().includes(searchTerm.toUpperCase())
                            ).map((field) => (
                                <div className="d-flex list-group-item list-group-item-action ">
                                    <button type="button" key={field.name} className="view-field-add-icon" onClick={() => addField(field.name)}>
                                        <MdAddCircleOutline size={20} />
                                    </button>
                                    <span className="ps-2">{field.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            />
        </>
    );
};

export default fieldAdder;

fieldAdder.propTypes = {
    fields: PropTypes.instanceOf(Array),
    addField: PropTypes.func,
};
