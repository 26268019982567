import React from 'react';
import PropTypes from 'prop-types';
import { FaStar } from 'react-icons/fa';
import { FavoriteView, View } from '../../../_services/GeotrakService/Models';
import { ViewService } from '../../../_services/GeotrakService';
import '../viewsList.css';

const unfavorite = ({
    view = new View(),
    refreshFavoriteList = () => null,
    favoriteViews = [],
    onError = () => null,
}) => {
    const viewService = new ViewService();

    const deleteFavoriteView = async () => {
        try {
            const deletedView = favoriteViews.find((favoriteView) => favoriteView.viewName === view.name);
            await viewService.deleteFavoriteView(deletedView.id);
            refreshFavoriteList();
        } catch (err) {
            onError({ message: 'Error deleting favorite view' });
        }
    };

    return (
        <FaStar
            size={15}
            color="gold"
            className="views-list-favorite-icon"
            onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                deleteFavoriteView();
            }}
        />
    );
};

export default unfavorite;

unfavorite.propTypes = {
    view: PropTypes.instanceOf(View),
    refreshFavoriteList: PropTypes.func,
    onError: PropTypes.func,
    favoriteViews: PropTypes.arrayOf(PropTypes.instanceOf(FavoriteView)),
};
