import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdEdit } from 'react-icons/md';
import { ButtonContainer, ButtonDanger } from '../../../../../utilComponents/styledComponents';
import LoaderButton from '../../../../../utilComponents/LoaderButton';
import { Domain } from '../../../../../_services/GeotrakService/Models';
import { DomainService } from '../../../../../_services/GeotrakService';
import IUpdateDomainRequest from '../../../../../_services/GeotrakService/interfaces/IUpdateDomainRequest';
import '../../../../stylesheets/AdminDomains.css';

const updateButton = ({
    domain = new Domain(),
    isDisabled = () => null,
    onUpdate = () => null,
}) => {
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [editDomainValue, setEditDomainValue] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState(null);
    const domainService = new DomainService();

    const editDomain = () => {
        setIsEditing(true);
        const editedDomain = new Domain(domain.field, editDomainValue, domain.database, domain.table, domain.id);
        const request = IUpdateDomainRequest.load(editedDomain);
        domainService.updateDomainValue(request).then(() => {
            setIsEditing(false);
            setEditDomainValue(null);
            setSelectedDomain(null);
            onUpdate();
        }).catch((err) => {
            setIsEditing(false);
            setError(`Error editing domain. ${err.message || err}`);
        });
    };

    return (
        <div className="row">
            <div>
                {!selectedDomain && (
                    <MdEdit
                        size={20}
                        onClick={() => {
                            setSelectedDomain(domain);
                            setEditDomainValue(domain.value);
                        }}
                        className="admin-domain-action-icon"
                    />
                )}
                {!isEditing && (
                    <span className="ps-3">{domain.value}</span>
                )}
                {selectedDomain === domain && (
                    <div className="row">
                        <input
                            type="text"
                            value={editDomainValue}
                            onChange={(e) => setEditDomainValue(e.target.value)}
                        />
                        <ButtonContainer
                            error={error}
                            onErrorDismiss={() => setError(null)}
                        >
                            <LoaderButton
                                type="submit"
                                disabled={!isDisabled(editDomainValue)}
                                isLoading={isEditing}
                                text="Save"
                                loadingText="Saving..."
                                bsSize="large"
                                onClick={editDomain}
                            />
                            <ButtonDanger onClick={() => setSelectedDomain(null)}>Cancel</ButtonDanger>
                        </ButtonContainer>
                    </div>
                )}
            </div>
        </div>
    );
};

export default updateButton;

updateButton.propTypes = {
    domain: PropTypes.instanceOf(Domain),
    isDisabled: PropTypes.func,
    onUpdate: PropTypes.func,
};
