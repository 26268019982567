import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from '../helpers/utilities';

const breadcrumbs = ({ crumbName = '' }) => {
    const location = useLocation().pathname.split('/');
    location.shift();
    let route = location[0];

    const renderCrumbs = () => location.slice(1, location.length - 1).map((crumb) => {
        route = `${route}/${crumb}`;
        return (
            <li key={crumb} className="breadcrumb-item">
                <a href={`/${route}`}>
                    {capitalizeFirstLetter(crumb)}
                </a>
            </li>
        );
    });

    return (
        <nav aria-label="breadcrumb" className="navbar navbar-light bg-light">
            <ol className="breadcrumb ms-3 mt-1">
                <li className="breadcrumb-item">
                    <a href={`/${route}/home`}>
                        {capitalizeFirstLetter(route)}
                    </a>
                </li>
                {renderCrumbs()}
                <li className="breadcrumb-item active">
                    {capitalizeFirstLetter(crumbName || location[location.length - 1])}
                </li>
            </ol>
        </nav>
    );
};

breadcrumbs.propTypes = {
    crumbName: PropTypes.string,
};

export default breadcrumbs;
