import React, { memo } from 'react';
import { LayerGroup } from 'react-leaflet';
import PropTypes from 'prop-types';
import MarkerCluster from './MarkerCluster';
import { DataSource } from '../_services/GeotrakService/Models';

const mapMarkers = ({
    data = {},
    visibleLayers = {},
    selectedSiteId = 0,
    handleMarkerSelection = () => null,
    showContextMenu = () => null,
    dataSources = [],
}) => {
    const renderClusters = () => Object.keys(data).map((layer) => {
        if (!(visibleLayers[layer])) {
            return null;
        }
        const dataSource = dataSources.find((source) => source.name === layer);
        return (
            <MarkerCluster
                key={dataSource.name}
                dataSource={dataSource}
                data={data}
                selectedSiteId={selectedSiteId}
                handleMarkerSelection={handleMarkerSelection}
                showContextMenu={showContextMenu}
            />
        );
    });

    return (
        <LayerGroup>
            {renderClusters()}
        </LayerGroup>
    );
};

export default memo(mapMarkers);

mapMarkers.propTypes = {
    data: PropTypes.instanceOf(Object),
    dataSources: PropTypes.arrayOf(PropTypes.instanceOf(DataSource)),
    visibleLayers: PropTypes.instanceOf(Object),
    selectedSiteId: PropTypes.number,
    handleMarkerSelection: PropTypes.func,
    showContextMenu: PropTypes.func,
};
