import { lazy } from 'react';
import {
    MdComment,
    MdFileDownload,
    MdAttachFile,
    MdAssignment,
    MdLayers,
    MdMyLocation,
    MdFileUpload,
    MdSearch,
    MdStraighten,
    MdStreetview,
    MdInfo
} from 'react-icons/md';
import { CLOSE_WIDGETS, TOGGLE_WIDGET } from '../CONSTANTS/types';
import withNoRecordSelection from '../utilComponents/hocs/withNoRecordSelection';

const STANDARD_WIDGET_WIDTH = 450;
const STANDARD_WIDGET_HEIGHT = 500;

const defaultWidgetState = {
    Comments: {
        tool: withNoRecordSelection(lazy(() => import('../components/Comment/CommentWidget'))),
        isOpen: false,
        title: 'Comments',
        icon: MdComment,
        width: 800,
        height: 710,
    },
    Contents: {
        tool: lazy(() => import('../components/TableOfContents/TableOfContentsWidget')),
        isOpen: false,
        title: 'Table of Contents',
        icon: MdLayers,
        width: 450,
        height: 600,
    },
    Documents: {
        tool: withNoRecordSelection(lazy(() => import('../components/Documents'))),
        isOpen: false,
        title: 'Documents',
        icon: MdAttachFile,
        width: 700,
        height: 600,
    },
    Download: {
        tool: lazy(() => import('../components/Download/Download')),
        isOpen: false,
        title: 'Download',
        icon: MdFileDownload,
        width: 450,
        height: 300,
    },
    Forms: {
        tool: withNoRecordSelection(lazy(() => import('../components/Forms/Forms'))),
        isOpen: false,
        title: 'Forms',
        icon: MdAssignment,
        width: '60%',
        height: '80%',
        x: 50,
        y: 10,
    },
    Geocoder: {
        tool: lazy(() => import('../components/GeocoderWidget')),
        isOpen: false,
        title: 'Geocoder',
        icon: MdMyLocation,
        width: 600,
        height: 500,
    },
    Measurement: {
        tool: lazy(() => import('../components/MeasurementWidget')),
        isOpen: false,
        title: 'Measurement',
        icon: MdStraighten,
        width: 450,
        height: 450,
    },
    Search: {
        tool: lazy(() => import('../components/Search')),
        isOpen: false,
        title: 'Search',
        icon: MdSearch,
        width: 800,
        height: 650,
    },
    'Street View': {
        tool: lazy(() => import('../components/StreetViewWidget')),
        isOpen: false,
        title: 'Street View',
        icon: MdStreetview,
        screen: 'Map',
        width: 600,
        height: 450,
    },
    Summary: {
        tool: lazy(() => import('../components/SummaryWidget')),
        isOpen: false,
        title: 'Summary',
        icon: MdInfo,
        width: 500,
        height: 600,
    },
    Upload: {
        tool: lazy(() => (import('../components/Upload/Upload'))),
        isOpen: false,
        title: 'Upload',
        icon: MdFileUpload,
        width: STANDARD_WIDGET_WIDTH,
        height: STANDARD_WIDGET_HEIGHT,
        x: 10,
        y: 10,
    },
    ViewsDrawer: {
        tool: lazy(() => import('../components/ViewsDrawer/ViewsDrawer')),
        screen: 'Data',
    },
};

export default (state = defaultWidgetState, action = { type: '', widget: '' }) => {
    switch (action.type) {
    case TOGGLE_WIDGET: {
        const newState = { ...state };
        newState[action.widget].isOpen = !newState[action.widget].isOpen;
        return newState;
    }
    case CLOSE_WIDGETS: {
        const newState = { ...state };
        Object.keys(newState).forEach((key) => {
            newState[key].isOpen = false;
        });
        return newState;
    }
    default:
        return state;
    }
};
