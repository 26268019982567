import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import './stylesheets/RecordCount.css';

const recordCount = ({ totalRecords = 0 }) => {
    const selectedFeatureCollection = useSelector((state) => state.selectedFeatureCollection);

    return (
        <div className="float-end">
            <span id="record-count-label" className="record-count">
                {selectedFeatureCollection.length}
                {' '}
                of
                {' '}
                {parseFloat(totalRecords).toLocaleString('en')}
                {' '}
                Selected
            </span>
        </div>
    );
};

export default recordCount;

recordCount.propTypes = {
    totalRecords: PropTypes.number,
};
