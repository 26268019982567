import { useReducer } from 'react';
import UserService from '../UserService';
import ICreateUserRequest from '../interfaces/ICreateUserRequest';
import { ACTIONS, useApiReducer } from '../../../helpers/hooks/useApiReducer';

const useCreateUser = () => {
    const [state, dispatch] = useReducer(useApiReducer, { data: null });
    const {
        data, isLoading, error, isSuccessful,
    } = state;

    const createUser = async (userObject) => {
        try {
            dispatch({ type: ACTIONS.CALL });
            const request = ICreateUserRequest.load(userObject);
            const result = await new UserService().createUser(request);
            dispatch({ type: ACTIONS.SUCCESS, data: result });
        } catch (err) {
            dispatch({ type: ACTIONS.ERROR, error: err });
        }
    };

    return {
        createUser, error, isLoading, isSuccessful, data,
    };
};

export default useCreateUser;
