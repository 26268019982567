import React from 'react';
import PropTypes from 'prop-types';
import { UserService } from '../../_services';
import ApplicationLink from './NavBarItems/ApplicationLink';
import LoginLink from './NavBarItems/LoginLink';
import UserMenu from './NavBarItems/UserMenu';

const geotrakNavBar = ({ onLogout = () => null, history = {} }) => {
    const auth = new UserService();

    return (
        <nav className="navbar navbar-expand-md navbar-default">
            <div className="container-fluid">
                <a className="navbar-brand text-primary text-white" href="/">Home</a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsed-navbar"
                    aria-controls="collapsed-navbar"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="collapsed-navbar">
                    <ul className="navbar-nav me-auto navbar-brand">
                        <li className="nav-item">
                            <a role="button" className="nav-link text-white" data-route="/service-center/home" href="/service-center/home">
                                Service Center
                            </a>
                        </li>
                        {(auth.isAuthenticated()) && <ApplicationLink label="Admin" route="/admin/home" userRole="Admin" />}
                    </ul>
                    {(auth.isAuthenticated())
                        ? <UserMenu onLogout={onLogout} history={history} /> : <LoginLink />}
                </div>
            </div>
        </nav>
    );
};

export default geotrakNavBar;

geotrakNavBar.propTypes = {
    onLogout: PropTypes.func,
    history: PropTypes.instanceOf(Object),
};
