import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../../utilComponents/LoaderButton';
import useDeleteOffice from '../../../../../_services/CheckRequestService/hooks/useDeleteOffice';
import { Office } from '../../../../../_services/CheckRequestService/Models';
import '../offices.css';

const deleteButton = ({ office = new Office() }) => {
    const {
        deleteOffice, error, isLoading, isSuccessful,
    } = useDeleteOffice();

    if (!office.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/check-request/check-request-offices" />;
    }

    return (
        <div className="office-toolbar office-button">
            {error && <div className="text-danger office-error" id="delete-office-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                danger
                id="delete-office-button"
                text="Delete"
                loadingText="Deleting..."
                isLoading={isLoading}
                onClick={() => deleteOffice(office)}
            />
        </div>
    );
};

export default deleteButton;

deleteButton.propTypes = {
    office: PropTypes.instanceOf(Office),
};
