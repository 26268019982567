import React from 'react';
import PropTypes from 'prop-types';
import withWizard from '../../../utilComponents/hocs/withWizard';
import ProjectPicker from './ProjectPicker';
import RequestTypePicker from './RequestTypePicker';
import RolePicker from './RolePicker';
import FieldPicker from './FieldPicker';
import NotificationPicker from './NotificationPicker';
import Success from './Success';

const steps = {
    SET_PROJECT: ProjectPicker,
    SET_REQUEST_TYPE: RequestTypePicker,
    SET_ROLES: RolePicker,
    SET_FIELDS: FieldPicker,
    SET_NOTIFICATIONS: NotificationPicker,
};

const requestBuilder = ({ requestFormId = null }) => {
    const RequestWizard = withWizard(steps, Success, { requestFormId });
    return (
        <div className="container">
            <div className="col-md-12">
                <RequestWizard />
            </div>
        </div>
    );
};

export default requestBuilder;

requestBuilder.propTypes = {
    requestFormId: PropTypes.number,
};
