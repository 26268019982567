/*
 * These routes are thin wrappers around page level components.
 * The page level components themselves do not have similar APIs, thus prop-spreading is more ergonomic here
 * than attempting to define a union of all page props.
 */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Home from '../containers/Home';
import Login from '../containers/Login';
import NotFound from '../containers/NotFound';
import Unauthorized from '../containers/Unauthorized';
import ExpiredToken from '../containers/ExpiredToken';
import ServiceCenterRouter from '../containers/ServiceCenter/ServiceCenterRouter';
import AdminRouter from '../containers/AdminRouter';
import GeoTRAK from '../containers/GeoTRAK';
import authorizedPrivateRoute from './privateRoutes/AuthorizedPrivateRoute';
import { UserService } from '../_services';
import withCurrentUser from './hocs/withCurrentUser';

const auth = new UserService();
const applicationAuthorizationCheck = (props) => auth.hasAppReferenceRole(props.computedMatch.params.moduleRoleId);

const routes = ({ user = {} }) => {
    const PrivateRoute = authorizedPrivateRoute;
    return (
        <Switch>
            <PrivateRoute exact path="/" component={Home} user={user} />
            <Route
                path="/service-center"
                user={user}
                render={(otherProps) => <ServiceCenterRouter {...otherProps} user={user} />}
            />
            <Route path="/admin" component={AdminRouter} />
            <Route path="/login" component={Login} />
            <Route path="/unauthorized" component={Unauthorized} />
            <Route path="/expiredToken" component={ExpiredToken} />
            <Redirect exact from="/.auth/login/aad/callback" to="/" />
            <PrivateRoute
                path="/modules/:moduleId/roles/:moduleRoleId"
                component={GeoTRAK}
                user={user}
                checkAuthorized={applicationAuthorizationCheck}
            />
            <Route component={NotFound} />
        </Switch>
    );
};

routes.propTypes = {
    user: PropTypes.instanceOf(Object),
    component: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.func,
        PropTypes.element,
        PropTypes.node,
    ]),
};

export default withCurrentUser(routes);
