import React from 'react';
import { useHistory } from 'react-router';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import useGetNotificationUsers from '../../../_services/NotificationService/hooks/user/useGetNotificationUsers';
import useGetUsers from '../../../_services/UserService/hooks/useGetUsers';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';

const adminNotificationsUsers = ({ match }) => {
    const { params: { id } } = match;
    const history = useHistory();
    const { notificationUsers, isLoadingNotificationUsers } = useGetNotificationUsers(id);
    const { users, isLoadingUsers } = useGetUsers();

    if (isLoadingUsers || isLoadingNotificationUsers) {
        return <LoadingFullScreen id="notification-user-form" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Notification Users">
                <ButtonContainer>
                    <ButtonPrimary id="new-notification-user-button" onClick={() => history.push(`/admin/notifications/${id}/users/new`)}>
                        New User
                    </ButtonPrimary>
                </ButtonContainer>
                <div className="list-group">
                    {notificationUsers.map((user) => {
                        const finalUser = users.find((prettyUser) => prettyUser.username === user.username);
                        return (
                            <button
                                type="button"
                                className="list-group-item list-group-item-action text-start"
                                key={user.id}
                                onClick={() => history.push(`/admin/notifications/${id}/users/${user.id}`)}
                            >
                                {finalUser ? finalUser.name : user.username}
                            </button>
                        );
                    })}
                </div>
            </AdminContainer>
        </>
    );
};

export default adminNotificationsUsers;
