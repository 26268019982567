import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import TwoWayQueryBuilderWrapper from './TwoWayQueryBuilderWrapper';
import { updateQueryBuilderFieldOptions, validateQuery } from './query-helpers';
import { alphabetize, formatQuery } from '../../helpers/utilities';
import { ButtonPrimary } from '../../utilComponents/styledComponents';
import { addAlertThunk } from '../../actions/errorActions';
import TableBuilder from '../../helpers/TableBuilder';
import './QueryBuilder.css';
import { DataSource, View } from '../../_services/GeotrakService/Models';

// Initialize default options for select fields.
const defaultFieldOption = {
    name: '--Select a field--',
    label: '--Select a field--',
    input: { type: 'text' },
};
const defaultFieldSelectionOption = {
    name: 'Choose Option',
    value: '',
};

// Common query replacements
const combinatorRegex = /\b(and|or)\b/gi;
const operatorRegex = /\b(is|not|null|in)\b/gi;

const convertSchemaToOptions = (schema, domainMap) => {
    if (!schema || !schema.length) {
        return [];
    }
    const fieldOptions = [defaultFieldOption];

    // alphabetize is in-place. Don't update argument.
    const schemaCopy = schema.map((column) => {
        const newObject = {};
        Object.keys(column).forEach((property) => {
            newObject[property] = column[property];
        });
        return newObject;
    });

    alphabetize(schemaCopy, 'name').forEach((field) => {
        const fieldOption = {
            name: field.key,
            label: field.name,
            operators: 'all',
        };
        const domainReference = domainMap[field.key || field.name];
        if (domainReference) {
            const options = domainReference.map((option) => ({ name: option, value: option }));
            fieldOption.input = {
                type: 'select',
                options: [defaultFieldSelectionOption].concat(options),
            };
        } else {
            fieldOption.input = {
                type: 'text',
            };
        }
        fieldOptions.push(fieldOption);
    });
    return fieldOptions;
};

const convertDataSourceQuery = (activeQuery, dataSourceQuery) => {
    // Remove default query content from the active query.
    // This means the response will only have view specific content modifiable.
    if (!activeQuery) {
        return null;
    }
    let query = null;
    if (dataSourceQuery !== null) {
        query = activeQuery.replace(`${dataSourceQuery} AND `, '').replace(dataSourceQuery, '');
    } else {
        query = activeQuery;
    }

    if (combinatorRegex.test(query)) {
        query = query.replace(combinatorRegex, (value) => value.toUpperCase());
    }

    if (operatorRegex.test(query)) {
        query = query.replace(operatorRegex, (value) => value.toLowerCase());
    }

    // Query builder requires query to be wrapped with parenthesis.
    if (!query.startsWith('(')) {
        query = `(${query})`;
    }
    return query;
};

const queryBuilder = ({
    onQueryChange, domains = {}, masterView = new View(), dataSource = new DataSource(),
}) => {
    const [isQueryBuilderVisible, setIsQueryBuilderVisible] = useState(null);
    const [query, setQuery] = useState(null);
    const [fieldOptions, setFieldOptions] = useState([]);
    const [isUserQueryValid, setIsUserQueryValid] = useState(false);
    const activeQuery = useSelector((state) => state.activeConfiguration.query);
    const [hideQuery, setHideQuery] = useState(false);
    const dispatch = useDispatch();

    const setQueryBuilderVisibility = useCallback((queryToValidate, isVisible) => {
        if (queryToValidate && validateQuery(queryToValidate)) {
            setIsQueryBuilderVisible(isVisible);
        }
    }, [setIsQueryBuilderVisible]);

    const getQueryBuilderFieldOptions = (userInput) => {
        try {
            const formattedQuery = convertDataSourceQuery(userInput || activeQuery, formatQuery(dataSource));
            const tableBuilder = new TableBuilder(masterView.fields);
            const schema = tableBuilder.getColumns();
            const schemaUpdatedOptions = convertSchemaToOptions(schema, domains);
            return updateQueryBuilderFieldOptions(schemaUpdatedOptions, formattedQuery);
        } catch (err) {
            dispatch(addAlertThunk('danger', 'Query Builder Construction Failed', err || err.message));
            return [];
        }
    };

    const isUserInputValid = (userInput) => {
        const currentQuery = convertDataSourceQuery(userInput, formatQuery(dataSource));
        const queryValidForSearch = validateQuery(currentQuery);
        const queryValidForUI = Object.keys(getQueryBuilderFieldOptions(userInput)).length;
        return (queryValidForSearch && queryValidForUI);
    };

    const handleTextChange = useCallback((event) => {
        const userInput = event.target.value;
        setIsUserQueryValid(isUserInputValid(userInput));
        setQuery(userInput);
        setQueryBuilderVisibility(userInput, false);
        onQueryChange({ query: userInput });
    }, [setQuery, setQueryBuilderVisibility, setIsUserQueryValid, onQueryChange]);

    const returnEditorStatus = () => {
        const userInstruction = (
            <>
                <h5>
                    The current view&apos;s query has operations not supported in the Query Builder
                </h5>
                <p>
                    Please reach out to an IT representative if you need assistance building your search.
                </p>
            </>
        );
        const queryOk = (
            <h5>
                This query is valid!
            </h5>
        );
        return (
            <div className={`alert ${isUserQueryValid ? 'alert-success' : 'alert-danger'} rounded-0`}>
                {isUserQueryValid ? queryOk : userInstruction}
            </div>
        );
    };

    const handleBuilderChange = useCallback((event) => {
        try {
            const dataSourceQuery = formatQuery(dataSource);
            let currentQuery = null;
            const userOperators = event.data.rules.filter((v) => v.combinator).map((v) => v.combinator);
            userOperators.push(event.data.combinator);
            if (!userOperators.includes('Choose Operator')) {
                setQuery(event.query);
                currentQuery = event.query === '()' ? dataSourceQuery : event.query;
            }
            onQueryChange({ query: currentQuery });
        } catch (err) {
            dispatch(addAlertThunk(
                'danger',
                'Query Builder UI problem occurred.',
                err.message || err
            ));
        }
    }, [setQuery, onQueryChange]);

    useEffect(() => {
        const formattedQuery = convertDataSourceQuery(activeQuery, formatQuery(dataSource));
        const queryBuilderOptions = getQueryBuilderFieldOptions();
        setQuery(formattedQuery);

        if (Object.keys(queryBuilderOptions).length && validateQuery(formattedQuery)) {
            setQueryBuilderVisibility(formattedQuery, true);
            setFieldOptions(queryBuilderOptions);
        }
    }, [dataSource, activeQuery]);

    return (
        <div className="querybuilder-container">
            <div className="querybuilder-form pb-2">
                {query && !isQueryBuilderVisible && returnEditorStatus()}
                {activeQuery && query !== '()' && (
                    <>
                        <ButtonPrimary
                            id="toggle-query-button"
                            onClick={() => { setHideQuery(!hideQuery); }}
                        >
                            {hideQuery ? 'Show Current Query' : 'Hide Current Query'}
                        </ButtonPrimary>
                        {!hideQuery && (
                            <div className="card rounded-0 bg-light m-2">
                                <div className="card-body">
                                    <code>
                                        {query}
                                    </code>
                                </div>
                            </div>
                        )}
                    </>
                )}
                <h6><b>Query Builder</b></h6>
                {(!activeQuery || isQueryBuilderVisible) && (
                    <TwoWayQueryBuilderWrapper
                        fields={fieldOptions}
                        onChange={handleBuilderChange}
                        query={query}
                    />
                )}
                {activeQuery && !isQueryBuilderVisible && (
                    <textarea
                        id="query-text-box"
                        cols={60}
                        rows={10}
                        required
                        value={query == null ? '' : query}
                        onChange={handleTextChange}
                        name="whereClause"
                    />
                )}
            </div>
        </div>
    );
};

queryBuilder.propTypes = {
    onQueryChange: PropTypes.func.isRequired,
    domains: PropTypes.instanceOf(Object),
    masterView: PropTypes.instanceOf(View),
    dataSource: PropTypes.instanceOf(DataSource),
};

export default queryBuilder;
