import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import useUpdateMarket from '../../../../_services/GeotrakService/hooks/useUpdateMarket';
import { Market } from '../../../../_services/GeotrakService/Models';
import '../markets.css';

const updateButton = ({ market = new Market(), isValid = () => null }) => {
    const {
        updateMarket, error, isLoading, isSuccessful,
    } = useUpdateMarket();

    if (!market.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to="/admin/markets" />;
    }

    return (
        <div className="market-toolbar market-button">
            {error && <div className="text-danger market-error" id="update-market-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                id="update-market-button"
                text="Update"
                loadingText="Updating..."
                isLoading={isLoading}
                disabled={!isValid()}
                onClick={() => updateMarket(market)}
            />
        </div>
    );
};

export default updateButton;

updateButton.propTypes = {
    market: PropTypes.instanceOf(Market),
    isValid: PropTypes.func,
};
