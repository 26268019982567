import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonPrimary } from '../../../utilComponents/styledComponents';
import Modal from '../../../components/Modals/Modal';
import { DatabaseService, DomainService } from '../../../_services/GeotrakService';
import TwoWayQueryBuilderWrapper from '../../../components/QueryBuilder/TwoWayQueryBuilderWrapper';
import './notifications.css';
import IGetFieldsRequest from '../../../_services/GeotrakService/interfaces/IGetFieldsRequest';

const notificationQueryBuilder = ({
    database = '',
    table = '',
    schema = '',
    whereClause = '',
    onQueryUpdate = () => null,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(null);
    const [options, setOptions] = useState([]);
    const [queryValue, setQueryValue] = useState('');
    const databaseService = new DatabaseService();

    const getFields = async () => {
        try {
            const request = IGetFieldsRequest(database, table, schema);
            const result = await databaseService.getFields(request);
            return result;
        } catch (err) {
            setError(err.message || err);
            return {};
        }
    };

    const getDomains = () => new DomainService().getRawDomains(database, table)
        .then((result) => result)
        .catch((err) => setError(err.message || err));

    const populateQueryBuilder = (fields) => {
        const result = Object.keys(fields).map((field) => {
            const newField = {
                name: field,
                label: field,
                operators: 'all',
            };
            if (fields[field].options) {
                newField.input = {
                    type: 'select',
                    options: fields[field].options,
                };
            } else {
                newField.input = {
                    type: 'text',
                };
            }
            return newField;
        });
        setOptions(result);
    };

    const getQueryBuilderData = async () => {
        const fields = await getFields();
        const domains = await getDomains();
        Object.keys(fields).forEach((field) => {
            const domainList = domains.filter((domain) => domain.field === field);
            if (domainList.length > 0) {
                const domainValues = domainList.map((domain) => domain.value);
                const domainValueList = domainValues.map((value) => ({ value, name: value }));
                fields[field].options = [{ value: '', name: 'Choose Option' }, ...domainValueList];
            }
        });
        populateQueryBuilder(fields);
    };

    const removeParenthesis = (match) => {
        const regexPar = /\(|\)/g;
        return match.replace(regexPar, '');
    };

    const formatQueryContent = () => {
        const regexAndOr = /( and| or)/gi;
        const regex = /( is| not| null| in)/gi;
        const regexIn = /( in | in)/gi;
        const containsIn = /\win|in\S/g;
        let formattedQuery = whereClause;
        if (regexAndOr.test(formattedQuery)) {
            formattedQuery = formattedQuery.replace(regexAndOr, (x) => x.toUpperCase());
        }
        if (regex.test(formattedQuery)) {
            formattedQuery = formattedQuery.replace(regex, (x) => x.toLowerCase());
        }
        if (regexIn.test(formattedQuery)) {
            formattedQuery = formattedQuery.replace(/(\('.*?'\))/g, removeParenthesis).trim();
        }
        if (containsIn.test(formattedQuery)) {
            formattedQuery = formattedQuery.replace(/in(\('.*?'\))/g, removeParenthesis).trim();
        }

        setQueryValue(formattedQuery.startsWith('(') ? formattedQuery : `(${formattedQuery})`);
    };

    const onQueryChange = ({ query }) => {
        const regex = /( is| not| null| in)/gi;

        let newQuery = query;
        if (query === '()') {
            newQuery = '';
        }
        if (regex.test(query)) {
            newQuery = query.replace(regex, (x) => x.toLowerCase());
        }
        // handles issues with IS NULL and IS NOT NULL queries
        newQuery = query.includes(' null ') ? newQuery.replace(/null.*?''/g, 'null').trim() : newQuery;
        // handles issues with IN and NOT IN queries
        newQuery = query.includes(' in ') ? query.replace(/in ('.*?')/g, this.replacer).trim() : newQuery;
        setQueryValue(newQuery);
        onQueryUpdate(newQuery);
    };

    useEffect(() => {
        if (database && table && schema) {
            getQueryBuilderData();
        }
    }, [database, table, schema]);

    useEffect(() => {
        formatQueryContent();
    }, [whereClause]);

    if (isOpen) {
        return (
            <>
                <Modal
                    showModal
                    header="Query Builder"
                    onCancel={() => setIsOpen(false)}
                    component={(
                        <div className="text-center p-2 notification-query-builder">
                            <TwoWayQueryBuilderWrapper
                                fields={options}
                                query={queryValue}
                                onChange={onQueryChange}
                            />
                        </div>
                    )}
                    footer={(
                        <ButtonPrimary onClick={() => setIsOpen(false)}>Done</ButtonPrimary>
                    )}
                />
                {error && <span className="text-danger">{`ERROR: ${error.message || 'An error occurred.'}`}</span>}
            </>
        );
    }

    return (
        <ButtonPrimary
            onClick={() => setIsOpen(true)}
            className="notification-query-builder-toggle"
            disabled={!database || !table || !schema}
        >
            Query Builder
        </ButtonPrimary>
    );
};

export default notificationQueryBuilder;

notificationQueryBuilder.propTypes = {
    database: PropTypes.string,
    table: PropTypes.string,
    schema: PropTypes.string,
    whereClause: PropTypes.string,
    onQueryUpdate: PropTypes.func,
};
