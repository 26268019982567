import React, { Component } from 'react';
import {
    MdPayment, MdAttachMoney, MdMultilineChart, MdBuild
} from 'react-icons/md';
import PropTypes from 'prop-types';
import { GiDart } from 'react-icons/gi';
import NavIconContainer from '../../utilComponents/NavIconContainer';
import withCurrentUser from '../../utilComponents/hocs/withCurrentUser';
import IGetFavoriteDataSourceRequest from '../../_services/GeotrakService/interfaces/IGetFavoriteDataSourceRequest';
import { DataSourceService } from '../../_services/GeotrakService';
import ErrorMessage from '../../utilComponents/ErrorMessage';

class ServiceHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            structuralRole: {
                module: {},
            },
            dataSourceId: '',
            isLoading: true,
            error: null,
        };

        this.dataSourceService = new DataSourceService();
    }

    componentDidMount = async () => {
        const { user } = this.props;
        const structuralRole = user.roles.find((role) => role.module.unit.Name === 'Structural');
        if (user && this.hasStructuralAccess(user)) {
            const dataSourceId = await this.getDataSource(structuralRole.id);
            this.setState({
                structuralRole,
                dataSourceId,
            });
        }
        this.setState({
            isLoading: false,
        });
    }

  hasStructuralAccess = (user) => user.roles.filter((role) => role.module.unit.Name === 'Structural').length > 0;

  getDefaultDataSource = async (moduleRoleId) => {
      try {
          const dataSources = await this.dataSourceService.getDataSources(moduleRoleId);
          return dataSources.find((dataSource) => dataSource.isInitialDataSource);
      } catch (err) {
          this.setState({ error: { message: `Error Getting Data Source. ${err.message}` } });
          return null;
      }
  }

getFavoriteDataSource = (moduleRoleId) => {
    try {
        const request = IGetFavoriteDataSourceRequest.load(moduleRoleId);
        return this.dataSourceService.getFavoriteDataSource(request);
    } catch (err) {
        this.setState({ error: { message: `Error Getting Data Source. ${err.message}` } });
        return null;
    }
}

  getDataSource = async (roleId) => {
      const favoriteDataSource = await this.getFavoriteDataSource(roleId);
      let dataSourceId;
      if (favoriteDataSource.id) {
          dataSourceId = favoriteDataSource.dataSourceId;
      } else {
          const defaultDataSource = await this.getDefaultDataSource(roleId);
          dataSourceId = defaultDataSource.id;
      }
      return dataSourceId;
  }

  render() {
      const {
          structuralRole, dataSourceId, isLoading, error,
      } = this.state;
      const { user } = this.props;
      if (!user.Id || isLoading) {
          return null;
      }

      return (
          <div className="container">
              {error && (
                  <div className="row">
                      <div className="col-md-12">
                          <ErrorMessage
                              message={error}
                              onDismiss={() => this.setState({ error: null })}
                          />
                      </div>
                  </div>
              )}
              <div className="col-md-12 d-flex flex-wrap">
                  <div className="col-sm">
                      <NavIconContainer
                          link="https://dart.bv.com/dart/"
                          icon={GiDart}
                          title="Dart"
                          isNewTab
                      />
                  </div>
                  <div className="col-sm">
                      <NavIconContainer
                          link="/service-center/finance"
                          icon={MdAttachMoney}
                          title="Finance"
                      />
                  </div>
                  <div className="col-sm">
                      <NavIconContainer
                          link="/service-center/procurement"
                          icon={MdPayment}
                          title="Procurement"
                      />
                  </div>
                  <div className="col-sm">
                      <NavIconContainer
                          link="/"
                          icon={MdMultilineChart}
                          title="Project Controls"
                      />
                  </div>
                  <div className="col-sm">
                      <NavIconContainer
                          link={`/modules/${structuralRole.module.id}/roles/${structuralRole.id}/data?data-source=${dataSourceId}`}
                          icon={MdBuild}
                          isAccessible={this.hasStructuralAccess(user)}
                          title="Structural Engineering"
                      />
                  </div>
              </div>
          </div>
      );
  }
}

export default withCurrentUser(ServiceHome);

ServiceHome.propTypes = {
    user: PropTypes.instanceOf(Object),
};

ServiceHome.defaultProps = {
    user: {},
};
