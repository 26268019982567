import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import useGetUsers from '../../../_services/UserService/hooks/useGetUsers';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import LoadingFullScreen from '../../../utilComponents/LoadingFullScreen';
import Breadcrumbs from '../../../utilComponents/Breadcrumbs';
import AdminContainer from '../AdminContainer';
import './users.css';

const adminUsers = () => {
    const history = useHistory();
    const { users, isLoading } = useGetUsers();
    const [filteredUsers, setFilteredUsers] = useState([]);

    const searchUsers = (userName) => {
        const result = users.filter((user) => user && user.name.toUpperCase().includes(userName.toUpperCase()));
        setFilteredUsers(result);
    };

    useEffect(() => {
        setFilteredUsers(users);
    }, [users]);

    if (isLoading) {
        return <LoadingFullScreen id="user-list-loader" />;
    }

    return (
        <>
            <Breadcrumbs />
            <AdminContainer title="Users">
                <ButtonContainer>
                    <ButtonPrimary onClick={() => history.push('/admin/users/new')}>
                        New User
                    </ButtonPrimary>
                </ButtonContainer>
                <input
                    className="user-search"
                    placeholder="Search..."
                    onChange={(e) => searchUsers(e.target.value)}
                />
                <div className="list-group border-bottom border-secondary market-container mb-4">
                    {filteredUsers.map((user) => (
                        <button
                            type="button"
                            className={`list-group-item text-start ${user.isDeleted && 'user-deleted'}`}
                            key={user.id}
                            onClick={() => history.push(`/admin/users/${user.id}`)}
                        >
                            {user.name}
                            {' '}
                            {user.isDeleted ? <span className="text-secondary badge bg-light mx-2">{user.adminPermission}</span>
                                : <span className="text-white badge bg-info mx-2">{user.adminPermission}</span> }
                        </button>
                    ))}
                </div>
            </AdminContainer>
        </>
    );
};

export default adminUsers;
