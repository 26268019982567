import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { ButtonDanger } from '../../../utilComponents/styledComponents';
import LoaderButton from '../../../utilComponents/LoaderButton';
import { CheckRequestActionService } from '../../../_services';
import Modal from '../../Modals/Modal';

const deleteButton = ({ checkRequestId = null, refreshQueues = () => null }) => {
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const checkRequestActionService = new CheckRequestActionService();

    const deleteRequest = async () => {
        try {
            setIsLoading(true);
            await checkRequestActionService.deleteRequest(checkRequestId);
            setError(null);
            setIsLoading(false);
            setShowConfirmationPopup(false);
            refreshQueues();
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    };

    if (showConfirmationPopup) {
        return (
            <>
                <Modal
                    showModal
                    header="Delete Check"
                    onCancel={() => setShowConfirmationPopup(false)}
                    component={
                        <h5 className="text-center">Are you sure you want to delete this check?</h5>
                    }
                    footer={(
                        <LoaderButton
                            danger
                            id="confirm-delete-button"
                            onClick={() => deleteRequest()}
                            isLoading={isLoading}
                            text="Delete"
                            loadingText="Deleting..."
                        />
                    )}
                    displayFullScreen={false}
                />
                {error && (
                    <div
                        className="text-center text-danger"
                        id="delete-request-error"
                    >
                        {`ERROR: ${error.message || 'An error occurred.'}`}
                    </div>
                )}
            </>
        );
    }

    return (
        <ButtonDanger id="delete-request-button" onClick={() => setShowConfirmationPopup(true)}>
            Delete
        </ButtonDanger>
    );
};

export default deleteButton;

deleteButton.propTypes = {
    checkRequestId: PropTypes.number,
    refreshQueues: PropTypes.func,
};
