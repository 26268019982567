import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdDelete } from 'react-icons/md';
import { ButtonPrimary, ButtonContainer, ButtonDanger } from '../../../utilComponents/styledComponents';
import { CheckRequestAdminService } from '../../../_services/CheckRequestService';
import LoaderButton from '../../../utilComponents/LoaderButton';
import { RequestFormRoleField } from '../../../_services/CheckRequestService/Models';
import Modal from '../../../components/Modals/Modal';
import AdminContainer from '../AdminContainer';
import '../../stylesheets/AdminCheckRequest.css';

const checkRequestAdminService = new CheckRequestAdminService();

const fieldPicker = ({
    jobProperties = {
        requestFormId: null,
    },
    nextStep = () => null,
}) => {
    const { requestFormId } = jobProperties;
    const [requestFormRoles, setRequestFormRoles] = useState([]);
    const [requestFormRoleFields, setRequestFormRoleFields] = useState([]);
    const [requestFormRole, setRequestFormRole] = useState(null);
    const [fields, setFields] = useState([]);
    const [isAdding, setIsAdding] = useState(false);
    const [newField, setNewField] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteField, setDeleteField] = useState(null);
    const [error, setError] = useState(null);

    const getRequestFormRoleFields = (roleId) => {
        checkRequestAdminService.getRequestFormRoleFields(roleId).then((result) => {
            setRequestFormRoleFields(result);
            setRequestFormRole(roleId);
        }).catch((err) => {
            setError(`Error Getting Fields. ${err.message || err}`);
        });
    };

    const getAllFields = () => {
        checkRequestAdminService.getFields().then((result) => {
            setFields(result);
        }).catch((err) => {
            setError(`Error Getting Fields. ${err.message || err}`);
        });
    };

    const getRequestFormRoles = () => {
        checkRequestAdminService.getRequestFormRoles(requestFormId).then((result) => {
            setRequestFormRoles(result);
        }).catch((err) => {
            setError(`Error Getting Roles. ${err.message || err}`);
        });
    };

    const addField = () => {
        setIsAdding(true);
        const selectedRole = requestFormRoles.filter((role) => role.id === parseInt(requestFormRole, 10))[0];
        const selectedField = fields.filter((field) => field.id === parseInt(newField, 10))[0];
        const addedField = new RequestFormRoleField(selectedRole, selectedField, true);
        checkRequestAdminService.createRequestFormRoleField(addedField).then(() => {
            setIsAdding(false);
            getRequestFormRoleFields(requestFormRole);
        }).catch((err) => {
            setIsAdding(false);
            setError(`Error Adding Field. ${err.message || err}`);
        });
    };

    const confirmDelete = (e) => {
        setShowConfirmation(true);
        setDeleteField(e);
    };

    const cancelDelete = () => {
        setShowConfirmation(false);
    };

    const deleteSelectedField = () => {
        checkRequestAdminService.deleteRequestFormRoleField(deleteField.id).then(() => {
            setDeleteField(null);
            setShowConfirmation(false);
            getRequestFormRoleFields(requestFormRole);
        }).catch((err) => {
            setError(`Error Deleting Field. ${err.message || err}`);
        });
    };

    const toggleRequired = async (field) => {
        field.toggleIsRequired();
        try {
            await checkRequestAdminService.updateRequestFormRoleField(field);
            getAllFields();
        } catch (err) {
            setError(`Error Updating Field. ${err.message || err}`);
        }
    };

    const renderFieldOptions = () => {
        const roleFields = requestFormRoleFields.map((roleField) => roleField.field.id);
        const fieldOptions = fields.filter((field) => !roleFields.includes(field.id));
        return fieldOptions.map((field) => (
            <option key={field.id} value={field.id}>{field.alias}</option>
        ));
    };

    const renderFields = () => requestFormRoleFields.map((field) => (
        <div className="list-group-item list-group-item-action" key={`field-${field.id}`}>
            <div className="d-flex flex-row justify-content-between">
                <div className="d-flex justify-content-start field-list-delete-button">
                    <MdDelete
                        size={20}
                        onClick={() => confirmDelete(field)}
                    />
                    {field.field.alias}
                </div>
                <div className="form-check">
                    <label htmlFor={field.field.name} className="form-check-label">
                        Required
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={field.field.name}
                            name={field.field.name}
                            checked={field.isRequired}
                            onChange={() => toggleRequired(field)}
                        />
                    </label>
                </div>
            </div>
        </div>
    ));

    useEffect(() => {
        getAllFields();
        getRequestFormRoles();
    }, []);

    return (
        <AdminContainer title="Form Fields">
            <form>
                <div className="mb-3">
                    <label htmlFor="select-request-role" className="form-label">
                        Role
                        {' '}
                        <span className="text-danger">*</span>
                    </label>
                    <select
                        id="select-request-role"
                        onChange={(e) => getRequestFormRoleFields(e.target.value)}
                        defaultValue=""
                    >
                        <option disabled value="">--Select a role--</option>
                        {requestFormRoles.map((role) => (
                            <option key={role.id} value={role.id}>{`${role.requestRole.name} (${role.requestRole.alias})`}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="select-field" className="form-label">
                        Field
                        {' '}
                        <span className="text-danger">*</span>
                    </label>
                    <select
                        id="select-field"
                        onChange={(e) => setNewField(e.target.value)}
                        defaultValue=""
                    >
                        <option disabled value="">--Select a field--</option>
                        {renderFieldOptions()}
                    </select>
                </div>
                <ButtonContainer>
                    <LoaderButton
                        isLoading={isAdding}
                        text="Add"
                        onClick={addField}
                        loadingText="Adding..."
                    />
                </ButtonContainer>
            </form>
            <div className="list-group">
                {requestFormRoleFields.length
                    ? <div className="form-fields-list">{renderFields()}</div> : <h3><em>No fields for this role yet.</em></h3>}
            </div>
            <Modal
                showModal={showConfirmation}
                header="Wait!"
                onCancel={cancelDelete}
                component={
                    <h5 className="text-center">Are you sure you want to delete this field?</h5>
                }
                footer={
                    <ButtonDanger onClick={deleteSelectedField}>Delete</ButtonDanger>
                }
                displayFullScreen={false}
            />
            <ButtonContainer
                error={error}
                onErrorDismiss={() => setError(null)}
            >
                <ButtonPrimary onClick={() => nextStep()}>Next</ButtonPrimary>
            </ButtonContainer>
        </AdminContainer>
    );
};

export default fieldPicker;

fieldPicker.propTypes = {
    jobProperties: PropTypes.instanceOf(Object),
    nextStep: PropTypes.func,
};
