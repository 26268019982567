import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import LoaderButton from '../../../../utilComponents/LoaderButton';
import useDeleteMap from '../../../../_services/GeotrakService/hooks/useDeleteMap';
import { MapConfig } from '../../../../_services/GeotrakService/Models';
import '../maps.css';

const deleteButton = ({ map = new MapConfig(), moduleId = null }) => {
    const {
        deleteMap, error, isLoading, isSuccessful,
    } = useDeleteMap();

    if (!map.id) {
        return null;
    }

    if (isSuccessful) {
        return <Redirect to={`/admin/modules/${moduleId}/roles/${map.moduleRoleId}`} />;
    }

    return (
        <div className="map-toolbar map-button">
            {error && <div className="text-danger map-error" id="delete-map-error">{`ERROR: ${error.message || 'An error occurred.'}`}</div>}
            <LoaderButton
                danger
                id="delete-map-button"
                text="Delete"
                loadingText="Deleting..."
                isLoading={isLoading}
                onClick={() => deleteMap(map)}
            />
        </div>
    );
};

export default deleteButton;

deleteButton.propTypes = {
    map: PropTypes.instanceOf(MapConfig),
    moduleId: PropTypes.string,
};
