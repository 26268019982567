import React from 'react';
import PropTypes from 'prop-types';
import './stylesheets/SuccessMessage.css';

const successMessage = ({ onDismiss = () => null, message = '' }) => (
    <div id="success-message" className="success-message alert alert-success alert-dismissable d-flex align-items-center justify-content-between" role="alert">
        <span>
            <strong>Success:</strong>
            {' '}
            <span>
                {message || 'Successful.'}
            </span>
        </span>
        <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={onDismiss}
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
);

export default successMessage;

successMessage.propTypes = {
    message: PropTypes.string,
    onDismiss: PropTypes.func,
};
