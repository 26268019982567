import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonContainer, ButtonPrimary } from '../../../utilComponents/styledComponents';
import ViewBuilderSummary from './ViewBuilderSummary';

const dataSourcePicker = ({
    nextStep = () => null,
    previousStep = () => null,
    jobProperties = {
        application: '',
        role: '',
        dataSources: [],
    },
}) => {
    const { application, role, dataSources } = jobProperties;
    const [selectedDataSource, setSelectedDataSource] = useState('');
    const [uniqueIdField, setUniqueIdField] = useState(null);

    const chooseDataSource = (dataSource) => {
        setSelectedDataSource(dataSource);
        const pickedDataSource = dataSources.find((source) => source.name === dataSource);
        setUniqueIdField(pickedDataSource.uniqueIdField);
    };

    const renderDataSourcePicker = () => (
        <select defaultValue="--Select a data source--" onChange={(e) => chooseDataSource(e.target.value)} className="mb-3">
            <option disabled value="--Select a data source--">--Select a data source--</option>
            {dataSources.map((dataSource) => (
                <option key={dataSource.name} value={dataSource.name}>{dataSource.alias}</option>
            ))}
        </select>
    );

    const isValid = () => selectedDataSource !== '';

    return (
        <div className="col-md-8 offset-md-2 text-center">
            <h2 className="Admin">Data Source</h2>
            <ViewBuilderSummary application={application} role={role} />
            {dataSources.length === 0 && <h5>No data sources found for this application.</h5>}
            {dataSources.length > 0 && renderDataSourcePicker()}
            <ButtonContainer className="mt-2">
                <ButtonPrimary
                    disabled={!isValid()}
                    onClick={() => nextStep({ dataSource: selectedDataSource, uniqueIdField })}
                >
                    Next
                </ButtonPrimary>
                <ButtonPrimary onClick={previousStep}>
                    Back
                </ButtonPrimary>
            </ButtonContainer>
        </div>
    );
};

export default dataSourcePicker;

dataSourcePicker.propTypes = {
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    jobProperties: PropTypes.instanceOf(Object),
};
