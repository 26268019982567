import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'react-data-grid-addons';

const { MenuItem } = Menu;
const FREEZE_LABEL = 'Freeze Column';
const UNFREEZE_LABEL = 'Unfreeze Column';

const freezeColumn = ({ column = null, onFreezeColumn = () => null }) => (
    <MenuItem
        id="freeze-column"
        data={column}
        onClick={(event, data, element) => onFreezeColumn(event, data, element)}
    >
        {column && column.frozen ? UNFREEZE_LABEL : FREEZE_LABEL }
    </MenuItem>
);

freezeColumn.propTypes = {
    column: PropTypes.instanceOf(Object),
    onFreezeColumn: PropTypes.func,
};

export default freezeColumn;
