import React from 'react';
import PropTypes from 'prop-types';
import '../stylesheets/ButtonSuccess.css';

const buttonSuccess = ({
    onClick = () => null,
    children = null,
}) => (
    <button
        type="button"
        className="btn btn-success success-button"
        onClick={onClick}
    >
        {children}
    </button>
);

buttonSuccess.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default buttonSuccess;
