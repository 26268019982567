import PropTypes from 'prop-types';

export default class UserHistory {
    constructor() {
        this.id = null;
        this.userId = null;
        this.url = null;
        this.createdBy = null;
        this.createdOn = null;
    }

    static load(response) {
        const userHistory = new UserHistory();

        if (response) {
            userHistory.id = response.Id;
            userHistory.userId = response.UserId;
            userHistory.url = response.URL;
            userHistory.createdBy = response.CreatedBy;
            userHistory.createdOn = response.CreatedOn;
        }

        return userHistory;
    }

    validate() {
        if (!this.url) {
            throw Error('url is required');
        }
    }

    loadSqlEntity() {
        this.validate();

        return {
            URL: this.url,
        };
    }
}

UserHistory.propTypes = {
    id: PropTypes.string,
    userId: PropTypes.string,
    url: PropTypes.string,
    createdBy: PropTypes.string,
    createdOn: PropTypes.string,
};
