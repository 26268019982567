import React from 'react';
import PropTypes from 'prop-types';
import withCurrentUser from '../../../utilComponents/hocs/withCurrentUser';
import LogoutLink from '../UserMenuItems/LogoutLink';
import ModuleDescription from '../UserMenuItems/ModuleDescription';

const ROLE_ID_INDEX = 4;

const userMenu = ({ onLogout = () => null, history = {}, user = {} }) => {
    const activeRole = user.roles.find((role) => role.id === history.location.pathname.split('/')[ROLE_ID_INDEX]);

    return (
        <ul className="navbar-nav">
            <li className="nav-item">
                <a
                    className="nav-link dropdown-toggle text-white"
                    aria-expanded="false"
                    id="user-nav-dropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    href="/#"
                >
                    <span className="position-relative">
                        {activeRole ? `${user.FullName} (${activeRole.module.alias})` : `${user.FullName}`}
                    </span>
                </a>
                <ul className="dropdown-menu dropdown-menu-end shadow" aria-labelledby="user-nav-dropdown">
                    <li>
                        <ModuleDescription activeRole={activeRole} />
                    </li>
                    <LogoutLink onLogout={onLogout} />
                </ul>
            </li>
        </ul>
    );
};

export default withCurrentUser(userMenu);

userMenu.propTypes = {
    onLogout: PropTypes.func,
    history: PropTypes.instanceOf(Object),
    user: PropTypes.instanceOf(Object).isRequired,
};
