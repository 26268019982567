import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GeoTRAKLogo from '../icons/logo.svg';
import DashboardIcon from '../icons/icons_dashboards.svg';
import ManagerIcon from '../icons/icons_manager.svg';
import LandbaseIcon from '../icons/icons_landbase.svg';
import ConstructionIcon from '../icons/icons_construction.svg';
import withCurrentUser from '../utilComponents/hocs/withCurrentUser';
import { resetActiveModule } from '../actions/moduleActions';
import { addAlertThunk } from '../actions/errorActions';
import { DataSourceService } from '../_services/GeotrakService';
import IGetFavoriteDataSourceRequest from '../_services/GeotrakService/interfaces/IGetFavoriteDataSourceRequest';
import { HistoryType } from '../types';
import './stylesheets/Home.css';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previousCard: null,
        };

        this.dataSourceService = new DataSourceService();
    }

    componentDidMount = () => {
        const { resetModuleAction } = this.props;
        resetModuleAction();
    }

    flipCard = (event) => {
        const { previousCard } = this.state;
        const recentCard = event.currentTarget.classList;
        recentCard.toggle('flipped');
        if (previousCard !== null && previousCard !== recentCard) {
            previousCard.toggle('flipped');
            this.setState({
                previousCard: event.currentTarget.classList,
            });
        } else if (previousCard === recentCard) {
            this.setState({
                previousCard: null,
            });
        } else {
            this.setState({
                previousCard: event.currentTarget.classList,
            });
        }
    };

    getDefaultDataSource = async (moduleRoleId) => {
        const { addAlertAction } = this.props;
        try {
            const dataSources = await this.dataSourceService.getDataSources(moduleRoleId);
            return dataSources.find((dataSource) => dataSource.isInitialDataSource);
        } catch (err) {
            addAlertAction('danger', 'Error Getting Data Source', err);
            return null;
        }
    }

    getFavoriteDataSource = (moduleRoleId) => {
        const { addAlertAction } = this.props;
        try {
            const request = IGetFavoriteDataSourceRequest.load(moduleRoleId);
            return this.dataSourceService.getFavoriteDataSource(request);
        } catch (err) {
            addAlertAction('danger', 'Error Getting Data Source', err);
            return null;
        }
    }

    getUpstreamConfiguration = async (role) => {
        const defaultDataSource = await this.getDefaultDataSource(role.id);
        if (defaultDataSource) {
            window.open(defaultDataSource.url);
        }
    }

    getModuleConfiguration = async (role) => {
        const { history } = this.props;
        const favoriteDataSource = await this.getFavoriteDataSource(role.id);
        let dataSourceId;
        if (favoriteDataSource.id) {
            dataSourceId = favoriteDataSource.dataSourceId;
        } else {
            const defaultDataSource = await this.getDefaultDataSource(role.id);
            dataSourceId = defaultDataSource.id;
        }

        history.push(`/modules/${role.module.id}/roles/${role.id}/data?data-source=${dataSourceId}`);
    }

    renderModuleList = (selectedModule, moduleRoles = []) => {
        // this dashboards exception and all chained code are to satisfy a
        // requirement that dashboard Power BI links open
        // in a new window without entering the main GeoTRAK screen.
        if (selectedModule !== 'Dashboards') {
            return moduleRoles.map((role) => (
                <button
                    type="button"
                    key={role.id}
                    className="list-group-item moduleButtons"
                    onClick={() => this.getModuleConfiguration(role)}
                >
                    {role.name}
                </button>
            ));
        }

        return moduleRoles.map((role) => (
            <button
                type="button"
                key={role.id}
                className="list-group-item moduleButtons"
                onClick={() => this.getUpstreamConfiguration(role)}
            >
                {role.name}
            </button>
        ));
    }

    renderModules = (selectedModule) => {
        const { user } = this.props;
        if (!user) {
            return null;
        }

        const moduleRoles = user.roles.filter((role) => role.module.unit.Name === selectedModule);

        return (
            <div className="list-group">
                {this.renderModuleList(selectedModule, moduleRoles)}
            </div>
        );
    }

    renderCards = () => (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-0 col-lg-3">
                    <div className="home-card card-body" tabIndex={0} role="button" onClick={this.flipCard} onKeyDown={this.flipCard}>
                        <div className="card-front">
                            <img src={DashboardIcon} alt="Chart" className="flipcard-image" />
                            <h2>Dashboards</h2>
                            <span>
                                Interact with real-time data and visualizations with tables, charts, and more more
                                more.
                            </span>
                        </div>
                        <div className="container card-back">
                            {this.renderModules('Dashboards')}
                        </div>
                    </div>
                </div>
                <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-0 col-lg-3">
                    <div className="home-card card-body" tabIndex={-1} role="button" onClick={this.flipCard} onKeyDown={this.flipCard}>
                        <div className="card-front">
                            <img src={ManagerIcon} alt="Calendar" className="flipcard-image" />
                            <h2>Manager</h2>
                            <span>
                                Collaborate, interact, and manage your project data by region and function
                                <br />
                                <br />
                            </span>
                        </div>
                        <div className="container card-back">
                            {this.renderModules('Manager')}
                        </div>
                    </div>
                </div>
                <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-0 col-lg-3">
                    <div className="home-card card-body" tabIndex={-2} role="button" onClick={this.flipCard} onKeyDown={this.flipCard}>
                        <div className="card-front">
                            <img src={LandbaseIcon} alt="Satellites spinning around earth" className="flipcard-image" />
                            <h2>LandBase</h2>
                            <span>
                                Spatially enabled layers provide unique functionality for wireless and wireline
                                Land Acquisition needs
                            </span>
                        </div>
                        <div className="container card-back">
                            {this.renderModules('Landbase')}
                        </div>
                    </div>
                </div>
                <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-0 col-lg-3">
                    <div className="home-card card-body" tabIndex={-3} role="button" onClick={this.flipCard} onKeyDown={this.flipCard}>
                        <div className="card-front">
                            <img src={ConstructionIcon} alt="Construction worker" className="flipcard-image" />
                            <h2>Construction</h2>
                            <span>
                                Manage scoping, construction and integration activities
                                {' '}
                                <br />
                                <br />
                            </span>
                        </div>

                        <div className="container card-back">
                            {this.renderModules('Construction')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    render() {
        return (
            <div className="Home">
                <div className="text-center">
                    <img src={GeoTRAKLogo} alt="GeoTRAK Home" id="GeoTRAKLogoHome" />
                </div>
                {this.renderCards()}
            </div>
        );
    }
}

export default withCurrentUser(connect(null, {
    resetModuleAction: resetActiveModule,
    addAlertAction: addAlertThunk,
})(Home));

Home.propTypes = {
    user: PropTypes.instanceOf(Object),
    resetModuleAction: PropTypes.func,
    addAlertAction: PropTypes.func,
    history: HistoryType,
};

Home.defaultProps = {
    user: {},
    resetModuleAction: () => null,
    addAlertAction: () => null,
    history: {},
};
