import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import Routes from './utilComponents/Routes';
import { AlertSystem, DismissAlertsEffect } from './utilComponents/AlertSystem';
import Log from './utilComponents/Log';
import { UserService } from './_services';
import withCurrentUser from './utilComponents/hocs/withCurrentUser';
import GeotrakNavBar from './components/NavBar/GeotrakNavBar';
import { LOGIN_URI } from './helpers/routing';
import './App.css';

const DEBUG_TIMEOUTS = false;

const app = ({ user = {} }) => {
    const history = useHistory();
    const location = useLocation();
    const loggedInUser = user;
    let idleTimer = null;
    const auth = new UserService();

    const handleOnActive = (event) => {
        if (DEBUG_TIMEOUTS) {
            Log.debug(`${loggedInUser?.FullName} is active`, event);
            Log.debug(`time remaining for ${loggedInUser?.FullName}`, idleTimer.getRemainingTime());
        }
    };

    const handleOnIdle = (event) => {
        if (DEBUG_TIMEOUTS) {
            Log.debug(`${loggedInUser?.FullName} is idle`, event);
            Log.debug(`${loggedInUser?.FullName} was last active`, idleTimer.getLastActiveTime());
        }
    };

    const handleLogout = () => {
        auth.logout();
        if (location.pathname !== LOGIN_URI) {
            history.push(LOGIN_URI);
        }
    };

    useEffect(() => history.listen((newLocation) => {
        if (auth.isAuthenticated()) {
            auth.insertHistory(newLocation.pathname);
        }
    }), [history]);

    useEffect(() => {
        auth
            .isLoggedIn()
            .then((loggedIn) => {
                if (!loggedIn) {
                    handleLogout();
                }
            });
    }, [loggedInUser, location, history]);

    useEffect(DismissAlertsEffect, []);

    return (
        <div className={`App env${process.env.REACT_APP_ENV_PREFIX}`}>
            <IdleTimer
                ref={(ref) => { idleTimer = ref; }}
                timeout={parseInt(process.env.REACT_APP_IDLE_TIMEOUT_MS, 10)}
                onActive={handleOnActive}
                onIdle={handleOnIdle}
                debounce={parseInt(process.env.REACT_APP_IDLE_DEBOUNCE, 10)}
            />
            <GeotrakNavBar onLogout={handleLogout} history={history} />
            <Routes />
            <AlertSystem />
        </div>
    );
};

export default withCurrentUser(app);

app.propTypes = {
    user: PropTypes.instanceOf(Object),
};
