import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { FaRegStar } from 'react-icons/fa';
import { FavoriteView, View } from '../../../_services/GeotrakService/Models';
import ICreateFavoriteViewRequest from '../../../_services/GeotrakService/interfaces/ICreateFavoriteViewRequest';
import { ViewService } from '../../../_services/GeotrakService';
import '../viewsList.css';

const favorite = ({
    view = new View(),
    isSelected = false,
    refreshFavoriteList = () => null,
    onError = () => null,
}) => {
    const history = useHistory();
    const searchParameters = new URLSearchParams(history.location.search);
    const viewService = new ViewService();

    const createFavoriteView = async () => {
        try {
            const favoriteView = new FavoriteView();
            favoriteView.dataSourceId = searchParameters.get('data-source');
            favoriteView.viewName = view.name;
            const request = ICreateFavoriteViewRequest.load(favoriteView);
            await viewService.createFavoriteView(request);
            refreshFavoriteList();
        } catch (err) {
            onError({ message: 'Error saving favorite view' });
        }
    };

    return (
        <FaRegStar
            size={15}
            color={`${isSelected ? 'white' : '#0079c1'}`}
            className="views-list-favorite-icon views-list-favorite-inactive"
            onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                createFavoriteView();
            }}
        />
    );
};

export default favorite;

favorite.propTypes = {
    view: PropTypes.instanceOf(View),
    refreshFavoriteList: PropTypes.func,
    isSelected: PropTypes.bool,
    onError: PropTypes.func,
};
