import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatabaseService } from '../_services/GeotrakService';

const selectDatabase = ({ onDatabaseSelected = () => null, selectedDatabase = null }) => {
    const [databases, setDatabases] = useState([]);
    const [value, setValue] = useState(selectedDatabase || '');
    const [error, setError] = useState(null);
    const databaseService = new DatabaseService();

    const getDatabases = async () => {
        try {
            const results = await databaseService.getDatabases();
            return results;
        } catch (err) {
            return err;
        }
    };

    const onDatabaseChanged = (databaseSelected) => {
        setValue(databaseSelected);
        onDatabaseSelected(databaseSelected);
    };

    useEffect(() => {
        setValue(selectedDatabase);
    }, [value, selectedDatabase]);

    useEffect(() => {
        let isMounted = true;
        getDatabases().then((data) => {
            if (isMounted) {
                setDatabases(data);
            }
        }).catch((err) => {
            setError(err);
        });
        return () => {
            isMounted = false;
        };
    }, []);

    if (error) {
        return (
            <div id="select-database-error" className="text-danger">{`ERROR: ${error.message || 'An error occurred.'}`}</div>
        );
    }

    return (
        <div className="mb-3">
            <label htmlFor="select-database" className="form-label">
                Database
                {' '}
                <span className="text-danger">*</span>
            </label>
            <select
                id="select-database"
                value={value || ''}
                onChange={(e) => onDatabaseChanged(e.target.value)}
            >
                <option disabled value="">--Select a database--</option>
                {databases.map((database) => (
                    <option id="select-database-option" value={database} key={database}>{database}</option>
                ))}
            </select>
        </div>
    );
};

export default selectDatabase;

selectDatabase.propTypes = {
    onDatabaseSelected: PropTypes.func,
    selectedDatabase: PropTypes.string,
};
