import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import ViewsList from './ViewsList';
import './viewsList.css';

const CLOSED_WIDTH = 54;
const OPEN_WIDTH = 300;

const viewsListPanel = ({ onResize = () => null, onPageChange = () => {} }) => {
    const [isOpen, setIsOpen] = useState(true);

    const onPanelToggle = () => {
        const width = isOpen ? CLOSED_WIDTH : OPEN_WIDTH;
        setIsOpen(!isOpen);
        onResize(width);
    };

    return (
        <div className="me-3">
            <div
                className={`views-list-header ${isOpen ? 'open' : 'closed'}`}
            >
                <span>Views</span>
                <button
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#views-list-collapse"
                    aria-expanded="true"
                    aria-controls="views-list-collapse"
                    className="btn bg-transparent border-0 pb-2"
                    onClick={onPanelToggle}
                >
                    {isOpen
                        ? <FaAngleDoubleLeft size={32} className="views-list-toggle-button" />
                        : <FaAngleDoubleRight size={32} className="views-list-toggle-button" />}
                </button>
            </div>
            <div className="collapse show collapse-horizontal" id="views-list-collapse">
                <div className="views-list-container">
                    <ViewsList onPageChange={onPageChange} />
                </div>
            </div>
        </div>
    );
};

export default viewsListPanel;

viewsListPanel.propTypes = {
    onResize: PropTypes.func,
    onPageChange: PropTypes.func,
};
